/**
 * This component is used to let the user select modifiers for their individual product
 *
 * @format
 */

import React, { useEffect } from "react";
import { Switch, Table, TableBody } from "@mui/material";
import {
	addModifierToFoodProduct,
	removeModifierToFoodProduct,
	useModifiers,
} from "scripts/firestoreQueries";

import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { PRODUCER_ID_PREFIX } from "scripts/globalVariables";
// import ProductModifierInterface from "interfaces/ProductModifierInterface";
import Typography from "@mui/material/Typography";
import firebase from "firebase";
import { useProduct } from "../../scripts/firestoreQueries";

type Props = {
	productId: string;
};

const ModifierSelection: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const userId = firebase.auth().currentUser?.uid;
	const producerId = PRODUCER_ID_PREFIX + userId;
	const [loading, noModifiers, getModifiers, modifiers] = useModifiers();
	const [product, getProduct, _productLoading] = useProduct(
		props.productId,
		producerId,
	);

	useEffect(() => {
		return getModifiers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleChecked = (
		modifierId: string,
		modifierName: string,
		status: boolean,
	) => {
		if (status) {
			removeModifierToFoodProduct(producerId, props.productId, modifierId);
		} else {
			addModifierToFoodProduct(
				producerId,
				props.productId,
				modifierId,
				modifierName,
			);
		}
	};

	const modifierItem = (item: any) => {
		let status = false;

		if (product?.modifiers?.hasOwnProperty(item.modifierId)) {
			status = true;
		}
		return (
			<Grid item xs={12} sm={6}>
				<Box py={0}>
					<FormControlLabel
						control={
							<Switch
								checked={status}
								onChange={() =>
									toggleChecked(item.modifierId, item.modifierName, status)
								}
							/>
						}
						label={item.modifierName}
						labelPlacement="end"
					/>
				</Box>
			</Grid>
		);
	};

	return (
		<>
			{loading ? (
				<LinearProgress />
			) : noModifiers ? (
				<Typography>No modifiers added.</Typography>
			) : (
				<Table size="small">
					<TableBody>
						<Grid container>
							{(modifiers as Array<Object>).map((modifier) =>
								modifierItem(modifier),
							)}
						</Grid>
					</TableBody>
				</Table>
			)}
		</>
	);
};

export default ModifierSelection;
