/**
 *
 * Index page once a user logged in
 *
 * @format */

import { Box, Grid, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useFeedInformation, useNearByProducer } from "scripts/feedQueries";

import FeedCard from "components/feed/FeedCard";
import HeaderImage from "components/index/HeaderImage";
import Layout from "../components/layout/Layout";
import PromotionBanner from "components/index/PromotionBanner";
import SnaqerOfTheWeek from "components/index/SnaqerOfTheWeek";
import Typography from "@mui/material/Typography";
import firebase from "firebase";
import { trackEvent } from "scripts/intercom";

const IndexPage: React.FunctionComponent = (): JSX.Element => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [feedLoading, isEmpty, getFeedInformation, feedInformation] =
		useFeedInformation();

	const [nearByProducerLoading, getNearByProducers, nearByProducerInformation] =
		useNearByProducer();

	useEffect(() => {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});
	});

	useEffect(() => {
		return getNearByProducers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		return trackEvent("IndexPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		//@ts-ignore
		if (nearByProducerLoading === false) {
			if (Object.entries(nearByProducerInformation).length > 0) {
				//@ts-ignore
				getFeedInformation(nearByProducerInformation);
			} else {
				return;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nearByProducerLoading, nearByProducerInformation]);

	const generateFeed = (): JSX.Element => {
		const feedData: any = Object.entries(feedInformation).sort(
			(a: any, b: any) => {
				return a[1].producer.distanceInM - b[1].producer.distanceInM;
			},
		);
		return feedData.map((producer: any, index: number) => {
			return <FeedCard data={(producer as any)[1]} key={index} />;
		});
	};

	return (
		<Layout title={"Welcome to Snaqing"} showToolbar={isLoggedIn}>
			{feedLoading ? (
				<LinearProgress />
			) : isEmpty ? (
				<Typography>No items</Typography>
			) : (
				<>
					{/* <PromotionBanner /> */}
					<HeaderImage />
					{/* <Box pt={3}>
						<Typography variant="h2" gutterBottom align="center">
							Snaqer of the week
						</Typography>
					</Box>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={12}>
							<SnaqerOfTheWeek
								title="Chef Sekoura"
								subTitle="Hi, I am originally from Algiers, Algeria and food is my passion. I have been cooking since I was young, learning many ethnic berber (native North African) dishes and pastries from mother and grandmother."
								city="Manhattan, New York"
								link="http://portal.snaqing.com/snaqer/producer4MfI74tAUrfRUzTtDkkARpyke7E3"
								avatar="https://firebasestorage.googleapis.com/v0/b/snaqing-prod.appspot.com/o/producers%2Fproducer4MfI74tAUrfRUzTtDkkARpyke7E3%2Fprofile-picture-4MfI74tAUrfRUzTtDkkARpyke7E3?alt=media&token=9918527b-9429-4f89-b47d-4f969f6b8a66"
							/>
						</Grid>
					</Grid> */}
					<Box pt={3}>
						<Typography variant="h2" gutterBottom align="center">
							Explore our snaqs
						</Typography>
					</Box>
					<Grid container direction="row" rowSpacing={4} columnSpacing={2}>
						{generateFeed()}
					</Grid>
				</>
			)}
		</Layout>
	);
};

export default IndexPage;
