/**
 *
 * Message row to represent a single room.
 *
 * @format
 */

import { IconButton, TableCell } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import React from "react";
import TableRow from "@mui/material/TableRow";

type Props = {
	data: any;
	isUser: boolean;
};
const MessageRow: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const roomData = props.data[1];
	const receiverData =
		typeof roomData.receiver != "undefined" && roomData.receiver;

	const getReceiverName = () => {
		let name = "";
		if (typeof receiverData.firstName === "undefined") {
			if (typeof receiverData.fullName === "undefined") {
				name = receiverData.userId;
			} else {
				name = receiverData.fullName;
			}
		} else {
			name = receiverData.firstName;
		}
		return name;
	};
	const receiverName = getReceiverName();

	return (
		<>
			<TableRow>
				{props.isUser ? (
					<TableCell>
						{typeof receiverData.displayName === "undefined"
							? receiverData.producerId
							: receiverData.displayName}
					</TableCell>
				) : (
					<TableCell>{receiverName}</TableCell>
				)}
				<TableCell>{roomData.lastMessage.slice(0, 40)}</TableCell>
				<TableCell align="right">
					<Link
						to={{
							pathname: "/message/conversation",
							state: {
								data: roomData,
							},
						}}
						style={{ textDecoration: "none" }}
					>
						<ChevronRightIcon />
					</Link>
				</TableCell>
			</TableRow>
		</>
	);
};

export default MessageRow;
