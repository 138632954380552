/**
 *
 * Functions for the modifier product
 *
 * @format */

export const getModifierIds = (modifierObject: any) => {
	let modifierArray: Array<string> = [];

	Object.keys(modifierObject).map((modifier) => {
		modifierArray.push(modifier);
	});
	return modifierArray;
};

export const calculateAdditions = (modifierValues: Object, func: Function) => {
	const sumArray: Array<number> = [];
	Object.values(modifierValues).map((value) => {
		sumArray.push(parseFloat(value.price));
	});
	func(sumArray.reduce((a, b) => a + b, 0));
};

export const modifierValidation = (
	atLeast: number,
	atMost: number,
	modifierName: string,
	validationObject: any,
) => {
	let errorMessage: Object = {};
	if (atLeast !== 0) {
		if (
			validationObject[modifierName] < atLeast ||
			typeof validationObject[modifierName] === "undefined"
		) {
			errorMessage = {
				[modifierName]: `${modifierName} needs to have at least ${atLeast} ingredient(s) selected. `,
			};
		}
	}
	if (validationObject[modifierName] > atMost) {
		errorMessage = {
			[modifierName]: `You can't select more than ${atMost} for the ${modifierName} customization. `,
		};
	}
	return errorMessage;
};
