/**
 *
 * The form to put a product in the basket. Used in product detail page.
 *
 * @format
 */

import {
	ButtonGroup,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Switch, useHistory } from "react-router";

import { Box } from "@mui/system";
import Button from "components/Button";
import FormInput from "components/formik/FormInput";
import Title from "components/typography/Title";
import { accountInformationSchema } from "scripts/formValidation";

type Props = {
	data: any;
	funcToSetPortion: Function;
};

const AddToBasketForm: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const [counter, setCounter] = useState(1);
	const productInformation = props.data;

	useEffect(() => {
		props.funcToSetPortion(counter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counter]);
	return (
		<>
			<Grid container m={3} display="flex" flexDirection="row">
				<Grid item xs={12} sm={12} lg={12} md={12} mr={6}>
					<Typography variant="h3">Special Request</Typography>
					<Field
						component={FormInput}
						name="specialRequest"
						placeholder="Special Request"
						autoComplete="special Request"
						label="Special Request"
					/>
				</Grid>
				<Grid item xs={12} sm={12} lg={12} md={12} mt={3}>
					<Typography variant="h3">Portions</Typography>
					<ButtonGroup
						size="small"
						aria-label="small outlined button group"
						sx={{ marginTop: "10px", padding: "0" }}
					>
						<Button
							size="large"
							disabled={counter <= 0}
							onClick={() => {
								setCounter(counter > 1 ? counter - 1 : 1);
							}}
						>
							-
						</Button>
						<Button size="large">{counter}</Button>
						<Button
							size="large"
							disabled={false}
							onClick={() => {
								setCounter(counter + 1);
							}}
						>
							+
						</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
		</>
	);
};

export default AddToBasketForm;
