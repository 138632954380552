/**
 *
 * Dashboard page for a producer which shows status cards
 *
 * @format */

import { Alert, AlertTitle, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useProducer, useUser } from "../scripts/firestoreQueries";

import BoxWithTitleSubtitleAndLink from "../components/paperBox/BoxWithTitleSubtitleAndLink";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Grid from "@mui/material/Grid";
import Layout from "../components/layout/Layout";
import { PRODUCER_ID_PREFIX } from "../scripts/globalVariables";
import StatusBox from "../components/paperBox/StatusBox";
import firebase from "firebase";
import { trackEvent } from "scripts/intercom";

type UserObject = {
	additionalInformation: {
		appliedAsProducer: boolean;
		applicationFlowStarted: boolean;
	};
	firstName: string;
	lastName: string;
};

const DashboardPage: React.FunctionComponent = (): JSX.Element => {
	const userId = firebase.auth().currentUser?.uid;
	const producerId = PRODUCER_ID_PREFIX + userId;
	const [userInformation, userLoading, getUser] = useUser(userId);
	const [producerInformation, _address, producerLoading, getProducer] =
		useProducer();
	const [applicationStatus, setApplicationStatus] = useState("");
	const [applicationStatusDescription, setApplicationStatusDescription] =
		useState("");
	const [linkTitle, setLinkTitle] = useState("Apply now");

	useEffect(() => {
		setTimeout(() => {
			return getUser();
		}, 3000);
		// set the timeout to make sure cloud functions write correctly. TODO: fix this
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return getProducer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			typeof (userInformation as any)?.additionalInformation !== "undefined"
		) {
			if ((userInformation as any).additionalInformation.startedProducerFlow) {
				setApplicationStatus(
					"You've started but didn't submit the application",
				);
				setApplicationStatusDescription(
					"Please continue the application and submit as soon as possible",
				);
				setLinkTitle("Check now");
			} else if (
				(userInformation as any).additionalInformation.appliedAsProducer
			) {
				setApplicationStatus("You have applied as a Snaqer");
				setApplicationStatusDescription(
					"We will review your application shortly and reach out to you if we have any additional questions.",
				);
			} else {
				setApplicationStatus("You have not applied as a Snaqer");
				setApplicationStatusDescription(
					"Please continue the application as submit as soon as possible",
				);
				setLinkTitle("Apply now");
			}
		}
	}, [userInformation]);

	useEffect(() => {
		firebase
			.auth()
			.currentUser?.getIdTokenResult()
			.then((tokenResult) => {
				if (tokenResult.claims.role === "user") {
				}
			});
	}, []);

	useEffect(() => {
		return trackEvent("DashboardPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return userLoading ? (
		<Layout title={"Loading..."} showToolbar={false}>
			<LinearProgress color="primary" />
		</Layout>
	) : userInformation ? (
		<Layout
			title={`Welcome ${
				(userInformation as UserObject).firstName
					? (userInformation as UserObject).firstName
					: ""
			}`}
			showToolbar={
				(userInformation as any).additionalInformation?.appliedAsProducer
			}
		>
			{(userInformation as any).additionalInformation?.appliedAsProducer ? (
				!producerLoading && (
					<Grid container spacing={2}>
						<Grid item xs={12} md={12} lg={12}>
							<StatusBox
								status={(producerInformation as any).isApproved}
								titleTrue="Application status"
								subTitleTrue="Your account is approved."
								descriptionTrue=""
								linkTitleTrue=""
								linkTrue=""
								iconTrue={<CheckCircleIcon fontSize="inherit" />}
								titleFalse="Application status"
								subTitleFalse="You've successfully applied as a Snaqer."
								descriptionFalse="We will review your application shortly and reach out to you if we have any additional questions."
								linkTitleFalse="Check your account information"
								linkFalse="/producer/account"
								iconFalse={<ErrorIcon fontSize="inherit" color="error" />}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<StatusBox
								status={(producerInformation as any).isActive}
								titleTrue="Account status"
								subTitleTrue="Account is active."
								descriptionTrue=""
								linkTitleTrue=""
								linkTrue=""
								iconTrue={<CheckCircleIcon fontSize="inherit" />}
								titleFalse="Account Status"
								subTitleFalse="Account is inactive."
								descriptionFalse="Your account is inactive. Please turn it on to be listed."
								linkTitleFalse="Activate now"
								linkFalse="/producer/account"
								iconFalse={<ErrorIcon fontSize="inherit" color="error" />}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<StatusBox
								status={(producerInformation as any).certificateSubmitted}
								titleTrue="Certificate status"
								subTitleTrue="Successfully submitted."
								descriptionTrue=""
								linkTitleTrue=""
								linkTrue=""
								iconTrue={<CheckCircleIcon fontSize="inherit" />}
								titleFalse="Certificate status"
								subTitleFalse="Missing proof."
								descriptionFalse="Please upload any proof that you are allowed to handle food in your area."
								linkTitleFalse="Upload now"
								linkFalse="/producer/certificate"
								iconFalse={<ErrorIcon fontSize="inherit" color="error" />}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<StatusBox
								status={(producerInformation as any).stripeIsActive}
								titleTrue="Payment status"
								subTitleTrue="Payment is enabled."
								descriptionTrue="You've successfully set up your payment account"
								linkTitleTrue=""
								linkTrue=""
								iconTrue={<CheckCircleIcon fontSize="inherit" />}
								titleFalse="Payment Status"
								subTitleFalse="Your payment is not enabled."
								descriptionFalse="Your payout is inactive. Please set it up to get payed out."
								linkTitleFalse="Setup now"
								linkFalse="/producer/payment"
								iconFalse={<ErrorIcon fontSize="inherit" color="error" />}
							/>
						</Grid>
					</Grid>
				)
			) : (
				<>
					<Alert severity="warning">
						<AlertTitle>Warning</AlertTitle>
						Hi{" "}
						{(userInformation as any).firstName
							? (userInformation as any).firstName
							: "there"}
						, we've identified, that you haven't applied as a Snaqer yet.{"\n"}
						Please use the link below to apply now.
					</Alert>
					<Grid container spacing={3}>
						<Grid item xs={12} md={8} lg={8}>
							<BoxWithTitleSubtitleAndLink
								title="Application status"
								subTitle={applicationStatus}
								description={applicationStatusDescription}
								linkTitle={linkTitle}
								link="/apply"
							/>
						</Grid>
					</Grid>
				</>
			)}
		</Layout>
	) : (
		<LinearProgress />
	);
};

export default DashboardPage;
