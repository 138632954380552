/**
 *
 * Index page once a user logged in
 *
 * @format */

import {
	FormControlLabel,
	Grid,
	IconButton,
	Snackbar,
	Switch,
} from "@mui/material";
import {
	changeProductStatus,
	updateProduct,
	useProduct,
} from "scripts/firestoreQueries";
import { useEffect, useState } from "react";

import { Box } from "@mui/system";
import Button from "components/Button";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { Formik } from "formik";
import ImageIcon from "@mui/icons-material/Image";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Layout from "../../components/layout/Layout";
import LinearProgress from "@mui/material/LinearProgress";
import ModifierSelection from "components/product/ModifierSelection";
import { PRODUCER_ID_PREFIX } from "scripts/globalVariables";
import ProductForms from "components/product/ProductForms";
import ProductImageUpload from "../../components/imageUpload/ProductImageUpload";
import Title from "components/typography/Title";
import Typography from "@mui/material/Typography";
import firebase from "firebase";
import { foodProductSchema } from "scripts/formValidation";
import { trackEvent } from "scripts/intercom";
import { useParams } from "react-router-dom";

const UpdateProductPage: React.FunctionComponent = (): JSX.Element => {
	let { productId }: any = useParams();
	const producerId = PRODUCER_ID_PREFIX + firebase.auth().currentUser?.uid;
	const [productStatus, serProductStatus] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		openSnackbar: false,
		vertical: "top",
		horizontal: "center",
	});
	const { vertical, horizontal, openSnackbar } = snackbarState;
	const [product, getProduct, loading] = useProduct(productId, producerId);
	let initialValues = product ? product : {};
	const [imageOpen, setImageOpen] = useState(true);

	useEffect(() => {
		getProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return trackEvent("UpdateProductPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleChecked = () => {
		const newStatus = !productStatus;
		serProductStatus(newStatus);
		changeProductStatus(producerId, productId, newStatus);
	};

	const handleClose = () => {
		setSnackbarState({ ...snackbarState, openSnackbar: false });
	};

	const onSubmit = (data: any) => {
		updateProduct(productId, producerId, data as any).then(() => {
			setSnackbarState({
				openSnackbar: true,
				vertical: "top",
				horizontal: "center",
			});
		});
	};

	return (
		<Layout title="Add a Snaq">
			<>
				{loading ? (
					<LinearProgress />
				) : (
					<>
						<Box py={2}>
							<Button
								variant="outlined"
								sx={{ mt: 3, ml: 1 }}
								onClick={() => history.back()}
							>
								Back
							</Button>
						</Box>
						<Box py={2}>
							<FormControlLabel
								control={
									<Switch
										checked={product?.isActive}
										onChange={toggleChecked}
									/>
								}
								label="Product status"
								labelPlacement="end"
							/>
						</Box>
						<Box py={2}>
							<Title>Product Image</Title>
							<Button
								variant="outlined"
								fullWidth
								startIcon={imageOpen ? <KeyboardArrowUpIcon /> : <ImageIcon />}
								onClick={() => setImageOpen(!imageOpen)}
							>
								{imageOpen ? (
									<Typography>Close Image Section</Typography>
								) : (
									<Typography>Open Image Section</Typography>
								)}
							</Button>
						</Box>
						<Collapse in={imageOpen} timeout="auto" unmountOnExit>
							<ProductImageUpload productId={productId} />
						</Collapse>
						<Box py={2}>
							<Title>Product Details</Title>
							<Formik
								initialValues={initialValues}
								validationSchema={foodProductSchema}
								onSubmit={onSubmit}
							>
								{({ handleSubmit, isValid, dirty }) => (
									<>
										<ProductForms />
										<Box py={2}>
											<Title>Modifiers</Title>
										</Box>
										<ModifierSelection productId={productId} />
										<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
											<Button
												variant="outlined"
												sx={{ mt: 3, ml: 0 }}
												onClick={() => history.back()}
											>
												Back
											</Button>
											<Button
												variant="contained"
												sx={{ mt: 3, ml: 1 }}
												onClick={() => handleSubmit()}
												disabled={!(isValid && dirty)}
											>
												Save
											</Button>
										</Box>
									</>
								)}
							</Formik>
						</Box>
						<Snackbar
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
							open={openSnackbar}
							message="Successfully saved"
							key={vertical + horizontal}
							action={
								<Box mt={0}>
									<Grid container>
										<Grid item>
											<Box mt={-3}>
												<Button
													variant="text"
													sx={{ mt: 3, ml: 1 }}
													onClick={() => history.back()}
												>
													Back
												</Button>
											</Box>
										</Grid>
										<Grid item>
											<IconButton
												aria-label="close"
												color="inherit"
												sx={{ p: 0.5 }}
												onClick={handleClose}
												size="large"
											>
												<CloseIcon />
											</IconButton>
										</Grid>
									</Grid>
								</Box>
							}
						/>
					</>
				)}
			</>
		</Layout>
	);
};

export default UpdateProductPage;
