/**
 * AuthController when a user is not logged in.
 *
 * @format
 */

import { IconButton, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import HomeIcon from "@mui/icons-material/Home";
import Paper from "@mui/material/Paper";
import RoomIcon from "@mui/icons-material/Room";
import SignInForm from "../components/SignInForm";
import SignUpForm from "../components/SignUpForm";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import backgroundImage from "../img/cooking-hand.jpg";
import { useHistory } from "react-router-dom";
import useTitle from "hooks/useTitle";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const AuthController: React.FunctionComponent = (): JSX.Element => {
	const [value, setValue] = useState(0);
	const history = useHistory();
	useTitle("Snaqing - Authentic Homemade Food nearby");

	const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	return (
		<>
			<AppBar position="static">
				<Toolbar
					sx={{
						pr: "24px",
					}}
				>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						sx={{ flexGrow: 1 }}
					>
						Snaqing - Order homemade food from your neighbors
					</Typography>
					<IconButton onClick={() => history.push("/")}>
						<HomeIcon />
					</IconButton>
					<IconButton onClick={() => history.push("/map")}>
						<RoomIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Grid container component="main" sx={{ height: "100vh" }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: `url(${backgroundImage})`,
						backgroundRepeat: "no-repeat",
						backgroundColor: (theme) =>
							theme.palette.mode === "light"
								? theme.palette.grey[50]
								: theme.palette.grey[900],
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 4,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box pb={5}>
							<Typography variant="h6" color="inherit">
								Snaqing is a marketplace with certified home chefs offering
								their homemade food to their neighbors through our app
							</Typography>
						</Box>
						<AppBar position="sticky" color="inherit">
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Authentificaton"
								indicatorColor="primary"
								centered={true}
							>
								<Tab label="Sign Up" {...a11yProps(0)} />
								<Tab label="Sign In" {...a11yProps(1)} />
							</Tabs>
						</AppBar>
						<TabPanel value={value} index={0}>
							<SignUpForm />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<SignInForm />
						</TabPanel>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default AuthController;
