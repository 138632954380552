/**
 *
 * Row to show individual orders for users.
 *
 * @format */

import {
	Box,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormHelperText,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	convertStringFormatToDate,
	getFutureDate,
} from "scripts/intervalFunctions";
import { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Chip from "@mui/material/Chip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { LoadingButton } from "@mui/lab";
import { orderCancelation } from "scripts/order/orderFunctions";

export const OrdersRow = (data: any) => {
	const orderData = data.data;
	const orderDoc = orderData.orderId;
	const paymentIntend = orderData.id;
	const [open, setOpen] = useState(false);
	const [cancelDialogVisibility, setCancelDialogVisibility] = useState(false);
	const [eligibleToCancel, setEligibleToCancel] = useState(false);
	const [cancelLoading, setCancelLoading] = useState(false);
	const pickUpDate = orderData.pickUpTime.split(" ••• ")[0];
	let displayName = orderData.displayName;
	if (displayName.length <= 1 || typeof displayName === "undefined") {
		displayName = "No name, please refer to orderId.";
	}

	useEffect(() => {
		const days = new Date(getFutureDate(1));
		const deliveryDate = convertStringFormatToDate(pickUpDate);
		if (deliveryDate < days || data.status === "refunded") {
			setEligibleToCancel(false);
		} else {
			setEligibleToCancel(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getProductDetails = (orderDetails: any) => {
		return orderDetails.map((product: any) => {
			let productName = product.data.details.productName;
			let additions: Array<string> = [];

			Object.keys(product.data.additions).map((additionName) => {
				additions.push(additionName);
			});
			return (
				<TableRow sx={{ backgroundColor: "#f1f1f1" }}>
					<TableCell>
						<Typography>
							<Typography sx={{ paddingBottom: 2 }}>
								<strong>
									{product.data.portions + "x "}
									{productName}
								</strong>
								{additions.length > 0 && (
									<>
										<Typography gutterBottom>
											Additions:{" "}
											{additions.map((name: string) => (
												<Chip
													label={name}
													size="small"
													variant="outlined"
													sx={{ marginRight: 1 }}
												/>
											))}
										</Typography>
										<Typography gutterBottom>
											Special Request: {product.data.specialRequest}
										</Typography>
									</>
								)}
							</Typography>
						</Typography>
					</TableCell>
					<TableCell>
						<Typography>
							{product.data.details.portionSize +
								" " +
								product.data.details.portionType}
						</Typography>
					</TableCell>
					<TableCell></TableCell>
				</TableRow>
			);
		});
	};

	return (
		<>
			<TableRow
				sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: "white" }}
			>
				<TableCell component="th" scope="row" align="left">
					<Typography>
						{orderData.status === "succeeded" ? (
							<CheckCircleIcon color="success" />
						) : orderData.status === "refunded" ? (
							<Tooltip title="This order was refunded">
								<KeyboardReturnIcon color="secondary" />
							</Tooltip>
						) : (
							<CancelIcon color="warning" />
						)}
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography>
						<strong>{orderData.pickUpTime.split(" ••• ")[0]}</strong>
					</Typography>
					<Chip size="small" label={orderData.pickUpTime.split(" ••• ")[1]} />
				</TableCell>
				<TableCell align="left">{displayName}</TableCell>
				<TableCell align="left">
					<Chip size="medium" label={orderData.orderId} />
				</TableCell>
				<TableCell align="left">
					<Typography>$ {(orderData.amount / 100).toFixed(2)}</Typography>
				</TableCell>
				<TableCell>
					{eligibleToCancel && orderData.status === "succeeded" && (
						<LoadingButton
							startIcon={<CancelIcon />}
							onClick={() => {
								setCancelDialogVisibility(true);
							}}
						>
							Cancel Order
						</LoadingButton>
					)}
				</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow key={orderData.orderId}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Table size="small" aria-label="details">
								<TableHead>
									<TableRow sx={{ backgroundColor: "#e3e3e3" }}>
										<TableCell>
											<Typography color="primary">Product</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">Portion size</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary"></Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{getProductDetails(orderData.orderDetails)}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			<Dialog
				open={cancelDialogVisibility}
				onClose={() => setCancelDialogVisibility(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Do you want to cancel this order?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to cancel this order? This can't be undone.
					</DialogContentText>
					{cancelLoading && (
						<FormHelperText>
							Processing cancellation. Please do not close this window.
						</FormHelperText>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setCancelDialogVisibility(false)}>
						Dismiss
					</Button>
					<Button
						disabled={cancelLoading}
						onClick={async () => {
							setCancelLoading(true);
							await orderCancelation(paymentIntend, orderDoc);
							setCancelDialogVisibility(false);
							setCancelLoading(false);
						}}
						autoFocus
					>
						Cancel Order
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
