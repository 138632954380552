/**
 *
 * Functionality to show error message from DB when a user forgot password and wants to reset
 *
 * @format
 */

import firebase from "firebase";
import { useState } from "react";

const useResetPassword = (): [null | string, boolean, (data: any) => void] => {
	const [firebaseError, setFirebaseError] = useState<null | string>(null);
	const [finished, setFinished] = useState(false);
	const resetPasswordFn = (data: any) => {
		firebase
			.auth()
			.sendPasswordResetEmail(data.email)
			.then(() => {
				setFinished(true);
			})
			.catch((error) => {
				if (error.code === "auth/invalid-email") {
					setFirebaseError("Invalid email!");
					console.error("Invalid email");
				}
				if (error.code === "auth/user-not-found") {
					setFirebaseError("User not found, please sign up first.");
					console.error("User not found");
				}
				console.error(
					"Something went wrong with adding user to firestore: ",
					error,
				);
			});
	};
	return [firebaseError, finished, resetPasswordFn];
};

export default useResetPassword;
