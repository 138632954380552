/**
 *
 * Message Page which shows user and snaqer message table
 *
 * @format
 */

import Message from "pages/message/MessagePage";
import React from "react";
import useTitle from "../hooks/useTitle";

const MessagePage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Messages - Snaqing | Homemade food");

	return <Message />;
};

export default MessagePage;
