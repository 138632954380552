/**
 *
 * ProfileHeader for Snaqers profileDetail page.
 *
 * @format
 */

import {
	Avatar,
	Badge,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faAward } from "@fortawesome/free-solid-svg-icons";

type Props = {
	producerInformation: Object;
};

const ProfileHeader: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	return (
		<>
			<Grid
				item
				xs={12}
				sm={12}
				justifyContent="center"
				alignItems="center"
				sx={{ display: "flex" }}
			>
				<Tooltip
					title="This Snaqer is a verified partner of Snaqing and has
                    successfully provided proof of a certified food safety exam."
				>
					<Badge
						color="primary"
						overlap="circular"
						badgeContent={<FontAwesomeIcon icon={faAward} />}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
					>
						<Avatar
							key={"profileImage"}
							src={(props.producerInformation as any).profileImage}
							sx={{ width: 136, height: 136 }}
						/>
					</Badge>
				</Tooltip>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				justifyContent="center"
				alignItems="center"
				sx={{ display: "flex" }}
			>
				{/* <Typography variant="h2">
					{(props.producerInformation as any).displayName}
				</Typography> */}
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				justifyContent="center"
				alignItems="center"
				sx={{ display: "flex" }}
			>
				{/* <Typography>
					{typeof (props.producerInformation as any).address !== "undefined"
						? (props.producerInformation as any).address.street
						: ""}
				</Typography> */}
			</Grid>
		</>
	);
};

export default ProfileHeader;
