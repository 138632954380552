/**
 *
 * Root of the application
 *
 * @format
 */

import Index from "../pages/IndexPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const IndexPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Snaqing - Authentic Homemade Food nearby");

	return <Index />;
};

export default IndexPage;
