/**
 * SubNaviationBar which will be listed on some pages right below the navigation bar on the top of the screen.
 *
 * @format
 */

import { Box } from "@mui/system";
import { Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React from "react";
import { useHistory } from "react-router-dom";

const SubNaviationBar: React.FunctionComponent = (): JSX.Element => {
	const history = useHistory();
	return (
		<Box py={2}>
			<Button
				variant="outlined"
				startIcon={<ChevronLeftIcon />}
				onClick={() => history.goBack()}
			>
				Back
			</Button>
		</Box>
	);
};

export default SubNaviationBar;
