/**
 *
 * MUI custom style components
 *
 * @format */

import { borders, compose, spacing } from "@mui/system";

import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export default styled(MuiButton)(compose(spacing, borders));
