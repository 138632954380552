/**
 *
 * Checkout Page to select pick up time and payment method from.
 *
 * @format */

import {
	Box,
	Button,
	Card,
	CardHeader,
	FormControlLabel,
	Grid,
	LinearProgress,
	Radio,
	RadioGroup,
} from "@mui/material";
import { Field, Formik } from "formik";
import {
	stripeChargeIntend,
	usePaymentMethod,
} from "scripts/payment/stripeQueries";

import CheckboxInput from "components/formik/CheckboxInput";
import FormRadioButton from "components/formik/FormRadioButton";
import Layout from "components/layout/Layout";
import Link from "@mui/material/Link";
import PaymentMethodSelector from "components/forms/PaymentMethodSelector";
import PickUpTimeSelector from "components/forms/PickUpTimeSelector";
import SubNavigationBar from "components/navigation/SubNavigationBar";
import Typography from "@mui/material/Typography";
import { basketCheckoutSchema } from "scripts/formValidation";
import { clearBasket } from "scripts/order/orderQueries";
import firebase from "firebase";
import { getUserId } from "scripts/globalFunctions";
import history from "routes/history";
import theme from "themes/materialUi";
import { trackEvent } from "scripts/intercom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CheckoutPage: React.FunctionComponent = (): JSX.Element => {
	const location: any = useLocation();
	const producerData = location.state.producerData;
	const basketData = location.state.basketData;
	const totalBasketPrice = location.state.totalBasketPrice;

	const checkout = (data: any) => {
		const paymentMethod = data.paymentMethod;
		const requirements = data.terms;
		const pickUpTime = data.pickUpTime;
		stripeChargeIntend(
			totalBasketPrice as number,
			"usd",
			paymentMethod,
			requirements,
			basketData as any,
			pickUpTime,
		)
			.then(async () => {
				firebase.analytics().logEvent("navigate_user_thank_you", {
					id: getUserId(),
					description:
						"Navigate to Thank you screen in BasketCheckoutScreen pressed",
				});
				history.push({
					pathname: "/thank-you",
					state: {},
				});
			})
			.catch((error) => {
				console.error(
					"Error while starting payment process /charge intend",
					error,
				);
			});
		clearBasket();
	};

	useEffect(() => {
		return trackEvent("CheckoutPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Checkout and order homemade snaqs">
			<SubNavigationBar />
			<Typography variant="h3" py={3}>
				{`Order from our certified Snaqer ${producerData.displayName}`}
			</Typography>
			<Formik
				onSubmit={checkout}
				initialValues={{}}
				validationSchema={basketCheckoutSchema}
			>
				{({ handleSubmit, isValid, dirty }) => (
					<Grid container spacing={3}>
						<Grid item xs={12} lg={6}>
							<Card sx={{ padding: 3 }}>
								<Typography variant="h3" mb={1}>
									Select a pickup time
								</Typography>
								<PickUpTimeSelector producerData={producerData} />
							</Card>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Card sx={{ padding: 3 }}>
								<>
									<Typography variant="h3" mb={1}>
										Payment Methods
									</Typography>
									<PaymentMethodSelector />
								</>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Field
								component={CheckboxInput}
								name="terms"
								placeholder="Terms "
								label="Terms"
								value={false}
							/>
							<Link
								href="https://www.snaqing.com/terms-of-service"
								target="_blank"
							>
								I agree to the Terms of Service
							</Link>
						</Grid>
						<Grid item xs={12} display="flex" justifyContent="flex-end">
							<Box>
								<Box justifyContent="flex-end" display="flex" m={1}>
									<Typography>
										Original price{" "}
										{`$${((totalBasketPrice as any) * 1.15).toFixed(2)}`}
									</Typography>
								</Box>
								<Box justifyContent="flex-end" display="flex" m={1}>
									<Typography>
										<strong>
											Discount (-15%)
											{` -$${((totalBasketPrice as any) * 0.15).toFixed(2)}`}
										</strong>
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} display="flex" justifyContent="flex-end">
							<Box py={2}>
								<Button
									variant="contained"
									onClick={() => handleSubmit()}
									disabled={!(isValid && dirty)}
								>
									PAY NOW ${totalBasketPrice.toFixed(2)}
								</Button>
							</Box>
						</Grid>
					</Grid>
				)}
			</Formik>
		</Layout>
	);
};

export default CheckoutPage;
