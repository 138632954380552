/**
 * No payment account exists components
 *
 * @format
 */

import { Alert, AlertTitle, Box } from "@mui/material";
import {
	PRODUCER_COLLECTION,
	PRODUCER_ID_PREFIX,
	STRIPE_ACCOUNT_COLLECTION,
} from "../../scripts/globalVariables";
import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Title from "../typography/Title";
import firebase from "firebase";
import { useStripeAccountInformation } from "../../scripts/stripeFunctions";

const AccountExists: React.FunctionComponent = (): JSX.Element => {
	const userId = firebase.auth().currentUser?.uid;
	const producerId = PRODUCER_ID_PREFIX + userId;
	const [accountInformation, getAccountInformation, loading] =
		useStripeAccountInformation();
	const [buttonLoading, setButtonLoading] = useState(false);
	const [errorOcurred, setErrorOcurred] = useState(false);

	const startNewProcess = async () => {
		await clearStripe(
			`${PRODUCER_COLLECTION}/${producerId}/${STRIPE_ACCOUNT_COLLECTION}`,
		);
		setTimeout(function () {
			window.location.reload();
		}, 3000);
	};
	const onSubmit = async () => {
		setButtonLoading(true);
		let accountUpdateLink = firebase
			.functions()
			.httpsCallable("getAccountUpdateLink");
		await accountUpdateLink((accountInformation as any)?.id)
			.then((response) => {
				const url = response.data.url;
				// window.location.replace(url);
				window.open(url, "_blank");
				setButtonLoading(false);
			})
			.catch((error) => {
				setErrorOcurred(true);
				console.error("Error while getting Stripe Account Update Link", error);
			});
	};

	useEffect(() => {
		getAccountInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearStripe = (path: string) => {
		const ref = firebase.firestore().collection(path);
		ref.get().then((snapshot) => {
			snapshot.docs.forEach((doc) => {
				ref.doc(doc.id).delete();
			});
		});
	};

	return loading ? (
		<LinearProgress />
	) : (
		<>
			{buttonLoading ? (
				<>
					<LinearProgress />
				</>
			) : (
				<>
					<Title>Account Status</Title>
					{!(accountInformation as any)?.data().charges_enabled && (
						<Box py={2}>
							<Alert severity="warning">
								<AlertTitle>Warning</AlertTitle>
								Your account needs attention, please use the button below to get
								to your Stripe account.
							</Alert>
						</Box>
					)}
					{(accountInformation as any)?.data().charges_enabled && (
						<Box py={2}>
							<Paper>
								<Alert severity="success">
									<AlertTitle>Success</AlertTitle>
									Your account is active. Please visit your Stripe Account to
									check your balance.
								</Alert>
							</Paper>
						</Box>
					)}
					<Button
						variant="contained"
						onClick={() => {
							onSubmit();
						}}
					>
						Check Stripe Account
					</Button>
				</>
			)}
			{errorOcurred && (
				<>
					<Box py={2}>
						<Alert severity="error">
							<AlertTitle>Error</AlertTitle>
							There was an error with your Stripe Account. Please reach out to
							support@snaqing.com.
						</Alert>
					</Box>
					<Button variant="contained" onClick={() => startNewProcess()}>
						Start new Process
					</Button>
				</>
			)}
		</>
	);
};

export default AccountExists;
