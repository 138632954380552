/**
 *
 * The form to put a product in the basket. Used in product detail page.
 *
 * @format
 */

import { Box, FormHelperText, Grid } from "@mui/material";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	calculateAdditions,
	getModifierIds,
	modifierValidation,
} from "scripts/modifiers/ModifierFunctions";

import FormSwitch from "components/formik/FormSwitch";
import Title from "components/typography/Title";
import Typography from "@mui/material/Typography";
import { accountInformationSchema } from "scripts/formValidation";
import { useModifiersOnProduct } from "scripts/modifiers/ModifierQueries";

const initialValues = {};

type Props = {
	data: any;
	calculationFunc: Function;
	modifierValidationErrors: Function;
	getModifierSelection: Function;
};

const ModifierForm: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const productInformation = props.data;
	const modifierArray = getModifierIds(productInformation.modifiers);
	const [getModifiers, modifiers, loading] = useModifiersOnProduct(
		props.data.producer.producerId,
		modifierArray,
	);
	const [modifierValues, setModifierValues] = useState({});
	const [modalValidationCheck, setModalValidationCheck] = useState<any>({});
	const [toggle, setToggle] = useState(false);
	let errorMessage: any = {};

	useEffect(() => {
		getModifiers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Object.entries(errorMessage).length > 0) {
			props.modifierValidationErrors(false);
		} else {
			props.modifierValidationErrors(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorMessage]);

	useEffect(() => {
		calculateAdditions(modifierValues, props.calculationFunc);
		props.getModifierSelection(modifierValues);
	});

	const handleSwiftStatus = async (
		name: any,
		value: any,
		price: any,
		modifierName: string,
		modifierId: string,
	) => {
		let oldValue = modalValidationCheck[modifierName];
		if (value === true) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const newModifierValue = {
				[name]: { name, price },
				modifierId: modifierId,
			};
			setModifierValues({
				...modifierValues,
				[name]: { name, price, modifierId: modifierId },
			});
			if (typeof modalValidationCheck[modifierName] === "undefined") {
				setModalValidationCheck({
					...modalValidationCheck,
					[modifierName]: 1,
				});
			} else {
				setModalValidationCheck({
					...modalValidationCheck,
					[modifierName]: oldValue + 1,
				});
			}
		} else {
			const newModifierValues = modifierValues;
			delete (newModifierValues as any)[name];
			setModifierValues(newModifierValues);
			if (modalValidationCheck[modifierName] > 0) {
				setModalValidationCheck({
					...modalValidationCheck,
					[modifierName]: oldValue - 1,
				});
			}
		}
	};

	const ModifierItem = (item: any): JSX.Element => {
		errorMessage = modifierValidation(
			item.atLeast,
			item.atMost,
			item.modifierName,
			modalValidationCheck,
		);

		return (
			<Formik
				initialValues={initialValues}
				key={item.modifierId}
				// @ts-ignore
				onSubmit={() => {}}
			>
				{() => {
					return (
						<Grid container m={3}>
							<Grid item xs={12}>
								<Typography variant="h3">Customization</Typography>
							</Grid>
							<Grid item xs={12} mt={0.5}>
								<Typography>
									Select at least {item.atLeast} and up to {item.atMost}
								</Typography>{" "}
							</Grid>
							<Grid container display="flex" flexDirection="row" height="100%">
								{Object.entries(item.modifierOptions).map(
									(option: any, index: number) => {
										const optionName = option[0].toString();
										const optionAmount = option[1];
										const modifierId = item.modifierId;
										return (
											<>
												<Grid
													item
													xs={12}
													lg={3}
													md={3}
													key={index}
													display="flex"
												>
													<Field
														component={FormSwitch}
														name={optionName}
														price={option[1]}
														modifierName={item.modifierName}
														func={handleSwiftStatus}
														modifierId={modifierId}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													lg={9}
													md={9}
													display="flex"
													alignItems="center"
												>
													<Typography>$ {option[1]}</Typography>
												</Grid>
											</>
										);
									},
								)}
							</Grid>
							<Grid item xs={12}>
								{errorMessage[item.modifierName] && (
									<FormHelperText>
										{errorMessage[item.modifierName]}
									</FormHelperText>
								)}
							</Grid>
						</Grid>
					);
				}}
			</Formik>
		);
	};

	return (
		<>
			{modifiers &&
				Object.values(modifiers as any).map((modifier) => {
					return ModifierItem(modifier);
				})}
		</>
	);
};

export default ModifierForm;
