/**
 *
 * Page to show all past orders
 *
 * @format */

import {
	Box,
	Button,
	Grid,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	getProducerId,
	getUserId,
	minimumIntegerDigits,
} from "scripts/globalFunctions";

import LinearProgress from "@mui/material/LinearProgress";
import { OrdersRow } from "./OrdersRow";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import { useOrders } from "../../scripts/firestoreQueries";

const UpcomingOrders: React.FunctionComponent = (): JSX.Element => {
	// const [open, setOpen] = useState(false);
	const userId: string = getUserId()!;
	const [orders, getOrders, loading, noOrders] = useOrders(
		"userId",
		userId,
		"upcoming",
	);
	const [totalOrders, setTotalOrders] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleChangePage = (
		_event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, totalOrders - page * rowsPerPage);

	useEffect(() => {
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const refreshing = () => {
		return getOrders();
	};

	useEffect(() => {
		if (typeof orders !== "undefined") {
			const total = Object.values(orders).length;
			setTotalOrders(total);
		}
	}, [orders]);

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table" key="id">
					<TableHead id="header">
						<TableRow key="rowHeader">
							<TableCell key="rowHeaderStatus">Status</TableCell>
							<TableCell key="rowHeaderHandover">Date/Time</TableCell>
							<TableCell key="rowHeaderSnaqer">Snaqer</TableCell>
							<TableCell key="rowHeaderOrderId">Order ID</TableCell>
							<TableCell key="rowHeaderPrice">Price</TableCell>
							<TableCell key="rowHeaderCancellation"></TableCell>
							<TableCell key="rowHeaderCollapse"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody key="body">
						{loading ? (
							<LinearProgress />
						) : noOrders ? (
							<TableRow>
								<TableCell colSpan={7}>
									<Box m={3}>
										<Typography mb={2}>
											There are no upcoming orders yet.
										</Typography>
										<Button href="/" variant="contained">
											Explore snaqs
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						) : (
							typeof orders !== "undefined" &&
							Object.values(orders)
								.sort((a: any, b: any) => {
									const dateAArray = a.pickUpTime.split(" ••• ")[0].split("-");
									const dateBArray = b.pickUpTime.split(" ••• ")[0].split("-");
									const aDay = minimumIntegerDigits(dateAArray[1]);
									const aMonth = minimumIntegerDigits(dateAArray[1]);
									const aYear = dateAArray[1];

									const bDay = minimumIntegerDigits(dateBArray[1]);
									const bMonth = minimumIntegerDigits(dateBArray[1]);
									const bYear = dateBArray[1];

									const aDateFormatted = aYear + aMonth + aDay;
									const bDateFormatted = bYear + bMonth + bDay;
									return aDateFormatted - bDateFormatted;
								})
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((data) => {
									return (
										<OrdersRow
											data={data}
											key={data.orderId}
											refreshing={refreshing}
										/>
									);
								})
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 81 * emptyRows }}>
								<TableCell colSpan={5} />
							</TableRow>
						)}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={totalOrders}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>
		</>
	);
};

export default UpcomingOrders;
