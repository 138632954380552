/** @format */

import "./utilities/firebaseInit";

import React, { Component } from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import { LinearProgress } from "@mui/material";
import Loadable from "react-loadable";
import materialUITheme from "themes/materialUi";

const LoadApp = Loadable({
	loader: () => import("./App"),
	loading() {
		return <LinearProgress />;
	},
	timeout: 10000, // 10 seconds
});
export default class LoadableApp extends Component {
	render() {
		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={materialUITheme}>
					<LoadApp />
				</ThemeProvider>
			</StyledEngineProvider>
		);
	}
}
