/**
 *
 * Functions used globally
 *
 * @format */

import { PRODUCER_ID_PREFIX } from "./globalVariables";
import firebase from "firebase";

export const getProducerId = (userId: string | undefined) => {
	return PRODUCER_ID_PREFIX + userId;
};

export const getUserId = () => {
	const userId = firebase.auth().currentUser?.uid;
	return userId ? userId : undefined;
};

export const formatAMPM = (time: string) => {
	const timeArray = time.split(":");
	let hours = parseInt(timeArray[0]);
	const minutes = timeArray[1];
	hours = hours % 12;
	if (parseInt(timeArray[0], 10) === 12) {
		hours = 12;
	}
	const formattedTime =
		parseInt(timeArray[0]) >= 12 ? hours + ":" + minutes + " PM" : time + " AM";
	return formattedTime;
};

// format string date mm-dd-yyy into a Date format
export const convertDateStringToDate = (date: string) => {
	const dateArray = date.split("-");
	const formattedOpeningHour = new Date(
		parseInt(dateArray[2]),
		parseInt(dateArray[0]) - 1,
		parseInt(dateArray[1]),
	);
	return formattedOpeningHour;
};

// Check if date is in the past
export const dateInPast = (firstDate: any, secondDate: Date) => {
	firstDate = new Date(firstDate);
	secondDate = new Date(secondDate);
	if (firstDate.setHours(0, 0, 0, 0) > secondDate.setHours(0, 0, 0, 0)) {
		return true;
	}

	return false;
};

// get Claims / User Roles form auth entity
export const getRole = async (): Promise<string> => {
	const role = await firebase
		.auth()
		.currentUser?.getIdTokenResult()
		.then((tokenResult) => {
			return tokenResult.claims.role;
		});
	return role ? role : "";
};

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLocation = (): Array<any> => {
	let coords: Array<number> = [];
	navigator.geolocation.getCurrentPosition((position) => {
		coords.push(position.coords.latitude);
		coords.push(position.coords.longitude);
	});
	return coords.length > 0 ? coords : [34.0211224, -118.396466];
};

export const minimumIntegerDigits = (digits: any) => {
	return digits.toLocaleString("en-US", {
		minimumIntegerDigits: 2,
		useGrouping: false,
	});
};
