/**
 *
 * Component which shows the product item in the BasketScreen.
 *
 * @format
 */

import {
	Avatar,
	Box,
	Button,
	Card,
	CardHeader,
	CardMedia,
	Grid,
	IconButton,
	LinearProgress,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
	deleteProduct,
	useTotalProductPrice,
} from "../../scripts/order/orderQueries";

import { CardContent } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Title from "components/typography/Title";

type Props = {
	data: any;
	docId: string;
	func: Function;
	trigger: boolean;
};

const BasketItem: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const doc = props.data;
	const productId = doc.data.details.productId;
	const amount = doc.data.portions;
	const producerId = doc.data.details.producer.producerId;
	const modifierObjects: Array<any> = [];

	Object.entries(doc.data.additions).map((additionKeys: Array<any>) => {
		modifierObjects.push({
			modifierId: additionKeys[1].modifierId,
			modifierOptionName: additionKeys[0],
		});
	});

	const [loading, getTotalProductPrice, totalPrice] = useTotalProductPrice(
		producerId,
		productId,
		modifierObjects,
		amount,
	);

	useEffect(() => {
		return getTotalProductPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doc]);

	return (
		<Card
			sx={{
				// flexDirection: "column",
				display: "flex",
				margin: 2,
				width: "90%",
			}}
		>
			<Grid container spacing={3} m={2} display="flex" flex-direction="row">
				<Grid item xs={12} sm={4} md={3}>
					<CardMedia
						component="img"
						height="90"
						image={
							typeof doc.data.details.productImage !== "undefined"
								? doc.data.details.productImage
								: ""
						}
						sx={{
							// maxHeight: { xs: 110 },
							width: { xs: 160 },
							borderRadius: "10px",
						}}
						alt={doc.data.details.productName}
					/>
				</Grid>
				<Grid item xs={12} sm={8} md={8}>
					<Grid container display="flex" flex-direction="row">
						<Grid item xs={12} sm={8} md={9}>
							<Typography variant="h3">
								{doc.data.portions} x {doc.data.details.productName}{" "}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={3} md={2}>
							<Typography variant="h3">
								{`$ ${(
									Math.round(((totalPrice as any) + Number.EPSILON) * 100) / 100
								).toFixed(2)}`}
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
							sm={1}
							md={1}
							display="flex"
							justifyContent="flex-end"
						>
							<IconButton
								onClick={async () => {
									await deleteProduct(producerId, props.docId);
									props.func(!props.trigger);
								}}
								sx={{
									padding: "0",
									marginRight: "20px",
								}}
								size="small"
								edge="start"
							>
								<DeleteIcon />
							</IconButton>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Grid container>
								{Object.entries(doc.data.additions).map((addition) => {
									return (
										<Grid item>
											<Typography>{addition[0]} •&nbsp;</Typography>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
						<Grid item xs={12} sm={4} md={12}>
							{doc.data.specialRequest ? (
								<Box>
									<Typography fontWeight="bold">Special Request: </Typography>
									<Typography>{`${doc.data.specialRequest}`}</Typography>
								</Box>
							) : null}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

export default BasketItem;
