/**
 * AccountInformation of the user signed in with
 *
 * @format
 */

import AccountInformation from "../pages/AccountInformationPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const AccountInformationPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Account Information - Snaqing | Homemade food");

	return <AccountInformation />;
};
export default AccountInformationPage;
