/** @format */

import { PRODUCER_COLLECTION } from "scripts/globalVariables";
import firebase from "firebase";
import { getLocation } from "scripts/globalFunctions";
import { useState } from "react";

const geofire = require("geofire-common");

export const useMapData = (): [boolean, () => void, object] => {
	const [loading, setLoading] = useState(true);
	const [nearByProducerInformation, setNearByProducerInformation] = useState(
		[],
	);

	const getNearByProducers = async () => {
		const location: Array<any> = getLocation();
		const center = location;
		const radiusInM = 100000 * 1000;

		const promises = [];
		const q = firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.where("isActive", "==", true)
			.where("isApproved", "==", true);
		promises.push(await q.get());

		Promise.all(promises)
			.then((snapshots) => {
				const matchingDocs: Array<any> = [];
				snapshots.forEach((snap) => {
					snap.forEach((doc) => {
						const producerData = doc.data();
						console.log(JSON.stringify(producerData, null, 2));
						const lat = producerData.address.geolocation.latitude;
						const lng = producerData.address.geolocation.longitude;
						if (lat && lng) {
							const distanceInKm = geofire.distanceBetween([lat, lng], center);
							const distanceInM = distanceInKm * 1000;
							if (distanceInM <= radiusInM) {
								matchingDocs.push({
									...doc.data(),
									distanceInM: distanceInM.toFixed(),
								});
							}
						}
					});
				});
				return matchingDocs;
			})
			.then((matchingDocs: Array<any>) => {
				if (matchingDocs.length === 0) {
					setLoading(false);
				}
				//@ts-ignore
				setNearByProducerInformation(matchingDocs);
				setLoading(false);
			});
	};
	return [loading, getNearByProducers, nearByProducerInformation];
};
