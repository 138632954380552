/**
 *
 * Product slider horizontal to show producers products (e.g. BasketPage)
 *
 * @format
 */

import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import React from "react";

type Props = {
	productData: any;
	producerData: any;
	openingHours?: any;
};

const ProductSliderHorizontal: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const producerData = props.producerData;
	const products = props.productData;

	return (
		<Box
			overflow="auto"
			flexDirection="row"
			display="flex"
			py={2}
			sx={{
				scrollbarColor: "transparent",
				"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
					backgroundColor: "transparent",
				},
			}}
		>
			{products.map((productData: any, i: number) => {
				const productId = productData.productId;
				const producerId = productData.producerId;
				const producerDataCompleted = {
					...producerData,
					openingHours: props.openingHours,
				};
				return (
					<Link
						to={{
							pathname: `/snaq/${productId}/${producerId}`,
							state: {
								data: {
									...productData,
									...{ producer: producerDataCompleted },
								},
							},
						}}
						style={{ textDecoration: "none" }}
					>
						<Box key={i} mx={2}>
							<Card sx={{ width: 210, height: 255 }}>
								<CardMedia
									component="img"
									height="160"
									image={(productData as any).productImage}
								/>
								<CardContent
									sx={{
										margin: 0,
										padding: 0,
									}}
								>
									<Box
										flexDirection="column"
										sx={{ height: 100 }}
										p={1}
										display="flex"
										justifyContent="space-between"
									>
										<Box>
											<Typography gutterBottom component="div">
												{(productData as any).productName.length > 25
													? (productData as any).productName.slice(0, 25) +
													  "..."
													: (productData as any).productName}
											</Typography>
										</Box>
										<Box display="flex" justifyContent="flex-end" mb={0}>
											<Typography gutterBottom variant="body2" component="div">
												{`$ ${productData.price}`}
											</Typography>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Box>
					</Link>
				);
			})}
		</Box>
	);
};

export default ProductSliderHorizontal;
