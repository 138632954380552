/**
 *
 * Reset Password Page.
 *
 * @format */

import { Box, Button, Card, FormHelperText, Grid } from "@mui/material";
import { Field, Formik } from "formik";

import FormInput from "components/formik/FormInput";
import Layout from "../components/layout/Layout";
import Typography from "@mui/material/Typography";
import { resetPasswordValidationSchema } from "scripts/formValidation";
import { trackEvent } from "scripts/intercom";
import { useEffect } from "react";
import useResetPassword from "scripts/resetPassword";

const ResetPasswordPage: React.FunctionComponent = (): JSX.Element => {
	const [resetPasswordError, finished, resetPasswordFn] = useResetPassword();

	useEffect(() => {
		return trackEvent("ResetPasswordPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Reset Password">
			<Grid
				container
				display="flex"
				flexDirection="column"
				alignItems="center"
				py={3}
				spacing={2}
			>
				<Grid item xs={12}>
					<Card>
						<Box
							m={3}
							display="flex"
							alignItems="center"
							flexDirection="column"
						>
							<Typography variant="h3" mb={1}>
								Reset your password
							</Typography>
							<Typography>
								Please enter your email address and we will send you a link to
								reset your password.
							</Typography>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} sx={{ width: "100%" }}>
					<Card sx={{ padding: 2 }}>
						{finished && (
							<>
								<Typography>
									We've sent you an email to reset your password. Please click
									the link there to reset. Please check your spam folder if you
									do not see an email within the next two minutes.{" "}
								</Typography>
								<Button href="/">Go to the feed</Button>
							</>
						)}
						<Formik
							initialValues={{}}
							validationSchema={resetPasswordValidationSchema}
							onSubmit={resetPasswordFn}
						>
							{({ handleSubmit, isValid }) => (
								<>
									<Field
										component={FormInput}
										name="email"
										placeholder="Email"
										keyboardType="email-address"
									/>
									{resetPasswordError && (
										<FormHelperText error>{resetPasswordError}</FormHelperText>
									)}
									<Button
										onClick={async () => {
											handleSubmit();
										}}
										variant="contained"
										disabled={!isValid}
									>
										Submit
									</Button>
								</>
							)}
						</Formik>
					</Card>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default ResetPasswordPage;
