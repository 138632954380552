/**
 *
 * File for all firestore query's realted with messages
 *
 * @format */

import {
	MESSAGES_SUBCOLLECTION,
	PARTICIPANT_IDS_ARRAY,
	PRODUCER_COLLECTION,
	PRODUCER_ID_PREFIX,
	ROOMS_COLLECTION,
	USERS_COLLECTION,
} from "scripts/globalVariables";

import _ from "lodash";
import firebase from "firebase";
import { getUserId } from "scripts/globalFunctions";
import moment from "moment";
import { useState } from "react";

export const timeAndDate = (timestampObject: any | null = "now"): string => {
	const today = new Date();
	const firestoreTimestamp = timestampObject ? timestampObject.toDate() : "now";
	let showTimeDate = moment(firestoreTimestamp).format("LT");

	if (moment(firestoreTimestamp).isBefore(today)) {
		showTimeDate = moment(firestoreTimestamp).subtract("days").calendar();
	}
	if (moment(firestoreTimestamp).isSame(today, "day")) {
		showTimeDate = moment(firestoreTimestamp).format("LT");
	}
	return showTimeDate;
};

export const readAndUnreadStatus = (
	roomObject: any,
	senderId: string,
): boolean => {
	const lastMessageTimestamp = moment
		.unix(roomObject.lastMessageTimestamp)
		.format();
	let lastReadTimestamp: string;
	let readStatus: boolean = false;

	Object.entries(roomObject.readData).forEach(([key, value]) => {
		if (key === senderId) {
			lastReadTimestamp = moment.unix(value as any).format();
		}
	});
	// @ts-ignore
	if (moment(lastMessageTimestamp).isAfter(lastReadTimestamp as any)) {
		readStatus = false;
	}
	// @ts-ignore
	if (moment(lastMessageTimestamp).isBefore(lastReadTimestamp as any)) {
		readStatus = true;
	}
	return readStatus;
};

export const updateReadStatus = (roomId: string, senderId: string) => {
	firebase
		.firestore()
		.collection(ROOMS_COLLECTION)
		.doc(roomId)
		.set(
			{
				readData: {
					[senderId]: firebase.firestore.FieldValue.serverTimestamp(),
				},
			},
			{ merge: true },
		);
};

export const addMessage = (
	receiverId: string,
	senderId: string,
	message: string,
) => {
	const participants = [receiverId, senderId];
	participants.sort();
	const roomId = participants[0] + "-" + participants[1];

	const roomData = {
		participants: [receiverId, senderId],
		lastMessage: message,
		lastMessageTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
		readData: {
			[senderId]: firebase.firestore.FieldValue.serverTimestamp(),
		},
	};
	const messageData = {
		timestamp: firebase.firestore.FieldValue.serverTimestamp(),
		senderId: senderId,
		message: message,
	};

	const batch = firebase.firestore().batch();
	const roomRef = firebase.firestore().collection(ROOMS_COLLECTION).doc(roomId);
	const messageRef = firebase
		.firestore()
		.collection(ROOMS_COLLECTION)
		.doc(roomId)
		.collection(MESSAGES_SUBCOLLECTION)
		.doc();

	batch.set(roomRef, roomData, { merge: true });
	batch.set(messageRef, messageData);
	batch.commit();
};

// Render all rooms from a specific user/producer
export const useRooms = (
	senderId: string,
): [undefined | object, () => any, boolean, boolean] => {
	const [rooms, setRooms] = useState([]);
	const [loading, setLoading] = useState(true);
	const [emptyRooms, setEmptyRooms] = useState(true);
	let allReceiver: Array<string> = [];
	let allRooms: Array<Object> = [];

	// Get all room references from a specific sender
	const getRooms = () => {
		const roomRef = firebase
			.firestore()
			.collection(ROOMS_COLLECTION)
			.where(PARTICIPANT_IDS_ARRAY, "array-contains", senderId);

		return roomRef.onSnapshot((roomId) => {
			if (roomId.empty) {
				setEmptyRooms(true);
			} else {
				// get the individual Room with participant ids
				roomId.docs.map((individualRoom) => {
					const participantsOfRoom = individualRoom.get("participants");
					// loop through the participant array
					participantsOfRoom!.forEach((participant: any) => {
						// if sender doesn't match the current sender push the receiver to a new array
						if (participant !== senderId) {
							allReceiver.push(participant);
							// if the senderId includes producer in front the sender must be a producer so go into the user collection and get the user
							if (senderId.includes("producer")) {
								firebase
									.firestore()
									.collection(USERS_COLLECTION)
									.doc(participant)
									.get()
									.then((doc) => {
										const receiverData = doc.data();
										const newState = {
											receiver: receiverData,
											room: individualRoom,
											roomName: receiverData!.firstName,
										};
										allRooms.push(newState);
										// @ts-ignore
										setRooms(allRooms);
									});
							} else {
								// if participant and sender does not match it must be a user so go into the producer collection and get the producer which is in the participant array
								firebase
									.firestore()
									.collection(PRODUCER_COLLECTION)
									.doc(participant)
									.get()
									.then((doc) => {
										const receiverData = doc.data();
										const newState = {
											receiver: receiverData,
											room: individualRoom,
											roomName: receiverData!.displayName,
										};
										allRooms.push(newState);
										// @ts-ignore
										setRooms(allRooms);
									});
							}
						}
					});
				});
				setEmptyRooms(false);
			}
			setLoading(false);
		});
	};
	return [rooms, getRooms, loading, emptyRooms];
};

export const getReceiverData = async (
	receiverId: string,
	senderIsUser: boolean,
) => {
	let receiverCollection = PRODUCER_COLLECTION;
	if (!senderIsUser) {
		receiverCollection = USERS_COLLECTION;
	}

	const data = await firebase
		.firestore()
		.collection(receiverCollection)
		.doc(receiverId)
		.get();
	return data;
};

export const useMessage = (
	roomId: string,
): [object | undefined, () => void, boolean] => {
	const [message, setMessage] = useState();
	const [loading, setLoading] = useState(true);

	const getMessage = () => {
		const messageRef = firebase
			.firestore()
			.collection(ROOMS_COLLECTION)
			.doc(roomId)
			.collection(MESSAGES_SUBCOLLECTION)
			.orderBy("timestamp", "asc");

		return messageRef.onSnapshot(
			(doc) => {
				const messageArray: any = [];
				doc.forEach((docMessage) => {
					const individualMessageId = { messageId: docMessage.id };
					const messageData = docMessage.data();
					const newData = _.merge(individualMessageId, messageData);
					messageArray.push(newData);
				});
				setMessage(messageArray);
				setLoading(false);
			},
			(error) => {
				console.error("Error while getting messages in useMessage hook", error);
			},
		);
	};
	return [message, getMessage, loading];
};

export const checkSenderReceiverIds = (participants: any) => {
	let senderId: string;
	let receiverId: string;
	const userId = getUserId();
	participants.forEach((participant: any) => {
		if (participant === userId || participant === PRODUCER_ID_PREFIX + userId) {
			senderId = participant;
		} else {
			receiverId = participant;
		}
	});
	//@ts-ignore
	return { senderId: senderId, receiverId: receiverId };
};
