/** @format */

import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";

import {
	apiKey,
	appIdKey,
	authDomainKey,
	databaseURLKey,
	measurementIdKey,
	messagingSenderIdKey,
	projectIdKey,
	storageBucketKey,
} from "../helper/constant";

import firebase from "firebase/app";

// const admin = require("firebase-admin");
// var serviceAccount = require("../snaqing-dev-firebase-adminsdk-rzokv-2563104c9f.json");
// admin.initializeApp({
// 	credential: admin.credential.cert(serviceAccount),
// 	databaseURL: "https://snaqing-dev-default-rtdb.firebaseio.com",
// });

let firebaseConfig = {
	apiKey: apiKey,
	authDomain: authDomainKey,
	databaseURL: databaseURLKey,
	projectId: projectIdKey,
	storageBucket: storageBucketKey,
	messagingSenderId: messagingSenderIdKey,
	appId: appIdKey,
	measurementId: measurementIdKey,
};

const app = firebase.initializeApp(firebaseConfig);

// if (window.location.hostname === "localhost") {
// 	app.functions().useEmulator("localhost", 5001);
// 	app.auth().useEmulator("http://localhost:9099");
// 	app.firestore().settings({
// 		host: "localhost:8080",
// 		ssl: false,
// 	});
// 	app.storage().useEmulator("localhost", 9199);
// 	// app.appCheck().activate("6LfvBJUcAAAAAP7MB5Z1mBmwCHOzrzKpsqHzmVl1", true);
// }

export default app;
