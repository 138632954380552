/**
 * History handler for react-router.
 *
 * Note:
 *   This needs to be created an given to react-router since we want to have
 *   direct access to it, for example for Google Analytics.
 *
 * @format
 */

import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export default history;
