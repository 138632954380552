/**
 *
 * Message Page to show user and producer messages
 *
 * @format */

import { Box, LinearProgress, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";

import Layout from "components/layout/Layout";
import ProducerRoomList from "../../components/message/ProducerRoomList";
import UserRoomList from "../../components/message/UserRoomList";
import { trackEvent } from "scripts/intercom";
import { useUser } from "scripts/firestoreQueries";

const MessagePage: React.FunctionComponent = (): JSX.Element => {
	const [userInformation, loading, getUser] = useUser();
	const [value, setValue] = useState("1");

	useEffect(() => {
		return getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	useEffect(() => {
		return trackEvent("MessagePage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Messages">
			{loading ? (
				<LinearProgress />
			) : (userInformation as any).role === "producer" ? (
				<Box sx={{ width: "100%", typography: "body1" }}>
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<TabList
								onChange={handleChange}
								aria-label="lab API tabs example"
							>
								<Tab label="User Messages" value="1" />
								<Tab label="Snaqer Messages" value="2" />
							</TabList>
						</Box>
						<TabPanel value="1">
							<UserRoomList />
						</TabPanel>
						<TabPanel value="2">
							<ProducerRoomList />
						</TabPanel>
					</TabContext>
				</Box>
			) : (
				<UserRoomList />
			)}
		</Layout>
	);
};

export default MessagePage;
