/**
 *
 * Index page once a user logged in
 *
 * @format */

import { Field, Formik } from "formik";
import { Grid, IconButton, Snackbar } from "@mui/material";
import { updateModifier, useProductModifier } from "scripts/firestoreQueries";
import { useEffect, useState } from "react";

import AddProductModifierOptions from "components/productModifier/AddProductModifierOptions";
import { Box } from "@mui/system";
import Button from "components/Button";
import CloseIcon from "@mui/icons-material/Close";
import FormDropdown from "components/formik/FormDropdown";
import FormInput from "components/formik/FormInput";
import Layout from "../../components/layout/Layout";
import LinearProgress from "@mui/material/LinearProgress";
import { PRODUCER_ID_PREFIX } from "scripts/globalVariables";
import Title from "components/typography/Title";
import { amountOptions } from "components/product/FoodVariables";
import firebase from "firebase";
import { foodProductModifierSchema } from "scripts/formValidation";
import { trackEvent } from "scripts/intercom";
import { useParams } from "react-router-dom";

const UpdateProductModifierPage: React.FunctionComponent = (): JSX.Element => {
	let { modifierId }: any = useParams();
	const producerId = PRODUCER_ID_PREFIX + firebase.auth().currentUser?.uid;
	const [state, setState] = useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const { vertical, horizontal, open } = state;
	const [modifier, getModifier, loading] = useProductModifier(
		modifierId,
		producerId,
	);
	let initialValues = modifier ? modifier : {};

	useEffect(() => {
		getModifier();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return trackEvent("UpdateProductModifierPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	const onSubmit = (data: any) => {
		updateModifier(modifierId, producerId, data as any).then(() => {
			setState({
				open: true,
				vertical: "top",
				horizontal: "center",
			});
		});
	};
	return (
		<Layout title="Update a Modifier">
			<>
				{loading ? (
					<LinearProgress />
				) : (
					<>
						<Box py={2}>
							<Title>Modifier Details</Title>
							<Formik
								validationSchema={foodProductModifierSchema}
								onSubmit={onSubmit}
								initialValues={initialValues}
							>
								{({ handleSubmit, isValid, dirty }) => (
									<>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<Field
													component={FormInput}
													name="modifierName"
													// placeholder="Product name"
													autoComplete="modifier name"
													label="Modifier name"
												/>
											</Grid>
										</Grid>
										<Box py={2}>
											<Title>Conditions</Title>
										</Box>
										<Grid container spacing={3}>
											<Grid item xs={4}>
												<Field
													component={FormDropdown}
													name="atLeast"
													placeholder="at least"
													autoComplete="at least"
													label="Select at Least"
													list={amountOptions}
												/>
											</Grid>
											<Grid item xs={4}>
												<Field
													component={FormDropdown}
													name="atMost"
													placeholder="at most"
													autoComplete="at most"
													label="Select at Most"
													list={amountOptions}
												/>
											</Grid>
										</Grid>
										<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
											<Button
												variant="contained"
												sx={{ mt: 3, ml: 1 }}
												onClick={() => handleSubmit()}
												disabled={!(isValid && dirty)}
											>
												Save
											</Button>
										</Box>
									</>
								)}
							</Formik>
						</Box>
						<AddProductModifierOptions modifierId={modifierId} />
						<Snackbar
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
							open={open}
							message="Successfully saved"
							key={vertical + horizontal}
							action={
								<Box mt={0}>
									<Grid container>
										<Grid item>
											<Box mt={-3}>
												<Button
													variant="text"
													sx={{ mt: 3, ml: 1 }}
													onClick={() => history.back()}
												>
													Back
												</Button>
											</Box>
										</Grid>
										<Grid item>
											<IconButton
												aria-label="close"
												color="inherit"
												sx={{ p: 0.5 }}
												onClick={handleClose}
												size="large"
											>
												<CloseIcon />
											</IconButton>
										</Grid>
									</Grid>
								</Box>
							}
						/>
					</>
				)}
				<Button
					variant="contained"
					sx={{ mt: 3, ml: 1 }}
					href="/producer/product-modifier"
				>
					Back
				</Button>
			</>
		</Layout>
	);
};

export default UpdateProductModifierPage;
