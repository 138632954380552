/**
 *
 * Certificate upload page
 *
 * @format
 */

import CertificatePage from "../pages/CertificatePage";
import React from "react";
import useTitle from "../hooks/useTitle";

const AccountInformationPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Certificate Information - Snaqing | Homemade food");

	return <CertificatePage />;
};
export default AccountInformationPage;
