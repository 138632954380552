/**
 * No payment account exists components
 *
 * @format
 */

import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Button from "../Button";
import LinearProgress from "@mui/material/LinearProgress";
import MuiButton from "@mui/material/Button";
import Title from "../typography/Title";
import { useGenerateAccountCreationLink } from "../../scripts/stripeFunctions";

const NoAccountExists: React.FunctionComponent = (): JSX.Element => {
	const [linkCreation, setLinkCreation] = useState(false);
	const [loading, generateLink, generatedStripeLink] =
		useGenerateAccountCreationLink();
	const onSubmit = () => {
		setLinkCreation(true);
		generateLink();
	};

	useEffect(() => {
		if (generatedStripeLink) {
			setLinkCreation(false);
			window.open(generatedStripeLink, "_blank");
		}
	}, [generatedStripeLink]);

	return (
		<>
			<Title>No Account Connected</Title>
			<Typography>Please set up your payment account to get paid.</Typography>
			<Typography>
				Snaqing uses Stripe to make payouts to our Snaqers secure and fast.
			</Typography>

			{linkCreation ? (
				<Box py={2}>
					<LinearProgress />
				</Box>
			) : loading ? (
				<>
					<Box py={2}>
						<Typography>
							Click on the link below to generate a secure link which will
							forward you to the onboarding flow.
						</Typography>
					</Box>
					<Button variant="contained" onClick={onSubmit}>
						Generate Link
					</Button>
				</>
			) : (
				<>
					<Box py={2}>
						<MuiButton
							variant="contained"
							href={generatedStripeLink}
							target="_blank"
						>
							Begin the payment setup
						</MuiButton>
					</Box>
					<Divider />
					<Box py={2}>
						<Typography>
							Please generate a new link in case the old one doesn't work
							anymore. This is needed to keep your data secure.
						</Typography>
					</Box>
					<Button variant="contained" onClick={onSubmit} color="secondary">
						Generate new Link
					</Button>
				</>
			)}
		</>
	);
};

export default NoAccountExists;
