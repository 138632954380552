/**
 *
 * Index page once a user logged in
 *
 * @format */

import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import AddProductModifier from "components/productModifier/AddProductModifier";
import AddProductModifierOptions from "components/productModifier/AddProductModifierOptions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";
import Button from "components/Button";
import Layout from "../../components/layout/Layout";
import { trackEvent } from "scripts/intercom";

const steps = ["Name & Conditions", "Options"];

const NewProductModifierPage: React.FunctionComponent = (): JSX.Element => {
	const [activeStep, setActiveStep] = useState(0);
	const [productModifierId, setProductModifierId] = useState("");

	const pullStep = (data: number, modifierId: string) => {
		setProductModifierId(modifierId);
		setActiveStep(data);
	};

	useEffect(() => {
		return trackEvent("NewProductModifierPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getStepContent = (step: number) => {
		switch (step) {
			case 0:
				return <AddProductModifier func={pullStep} />;
			case 1:
				return (
					<>
						<AddProductModifierOptions
							func={pullStep}
							modifierId={productModifierId}
						/>
						<Button
							startIcon={<ArrowBackIosIcon />}
							sx={{ my: 3, mx: 1 }}
							href="/producer/product-modifier"
							variant="contained"
						>
							My Modifiers
						</Button>
					</>
				);
			default:
				throw new Error("Unknown step");
		}
	};
	return (
		<Layout title="Add a Modifier">
			<>
				{/* <ProductImageUpload producerId={producerId} /> */}
				<Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<>
					{activeStep === steps.length ? (
						<>
							<Typography variant="h2" gutterBottom color="primary">
								Your product is in the process to be saved.
							</Typography>
							<Typography>
								Please return to your Snaqs to see if it's uploaded correctly.
							</Typography>
							<Box py={3}>
								<Button variant="contained" href="/producer/product">
									Go to Snaqs
								</Button>
							</Box>
						</>
					) : (
						<>{getStepContent(activeStep)}</>
					)}
				</>
			</>
		</Layout>
	);
};

export default NewProductModifierPage;
