/**
 * Page which shows all the products for a producer
 *
 * @format
 */

import Product from "../pages/product/ProductPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const ProductPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("All Snaqs - Snaqing | Homemade food");

	return <Product />;
};

export default ProductPage;
