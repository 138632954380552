/**
 *
 * BasketPage which shows products in the basket as well as recommended products.
 *
 * @format
 */

import Basket from "pages/basket/BasketPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const BasketPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Basket - Snaqing | Homemade food");

	return <Basket />;
};

export default BasketPage;
