/** @format */

import {
	Box,
	CardMedia,
	Chip,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {
	PRODUCER_ID_PREFIX,
	PRODUCT_IMAGE_COLLECTION,
} from "scripts/globalVariables";
import { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LinearProgress from "@mui/material/LinearProgress";
import { deleteProduct } from "scripts/firestoreQueries";
import firebase from "firebase";

let noImageRef = "intern/images/no-product-image.png";

const MODIFIERS_IN_USE = <CheckCircleIcon />;
const MODIFIERS_NOT_IN_USE = <CancelIcon />;

export const Row = (props: any) => {
	const { row } = props;
	const userId = firebase.auth().currentUser?.uid;
	const producerId = PRODUCER_ID_PREFIX + userId;
	const productId = row.productId;
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [noItemImage, setNoItemImage] = useState();
	const [modifierStat, setModifierStat] = useState(MODIFIERS_NOT_IN_USE);
	const [state, setState] = useState<any>({
		image: null,
		progress: null,
		downloadURL: null,
	});
	const imageRef =
		PRODUCT_IMAGE_COLLECTION + "/producer" + userId + "/" + productId;

	useEffect(() => {
		(async () => {
			await firebase
				.storage()
				.ref()
				.child(imageRef)
				.getDownloadURL()
				.then((url) => {
					setState({
						downloadURL: url,
					});
					if (props.func) {
						props.func(1);
					}
					setLoading(false);
				})
				.catch((error) => {
					if (error.code === "storage/object-not-found") {
						(async () => {
							setNoItemImage(
								(await firebase
									.storage()
									.ref(noImageRef)
									.getDownloadURL()) as any,
							);
							setLoading(false);
						})();
					}
				});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (row.modifiers) {
			if (Object.keys(row.modifiers).length > 0) {
				setModifierStat(MODIFIERS_IN_USE);
			} else {
				setModifierStat(MODIFIERS_NOT_IN_USE);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }} key={row.productId}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>
					{loading ? (
						<LinearProgress />
					) : (
						<CardMedia
							component="img"
							src={state.downloadURL || noItemImage}
							style={{
								maxHeight: "40px",
								maxWidth: "70px",
								objectFit: "cover",
								borderRadius: 5,
							}}
						/>
					)}
				</TableCell>
				<TableCell component="th" scope="row">
					{row.isActive ? (
						<Chip label="Active" variant="filled" color="success" />
					) : (
						<Chip label="Inactive" variant="filled" color="error" />
					)}
				</TableCell>
				<TableCell component="th" scope="row" align="left">
					<Typography>{row.productName}</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography>{row.portionSize}</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography>{row.portionType}</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography>$ {row.price}</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography>{modifierStat}</Typography>
				</TableCell>
				<TableCell align="right" size="small">
					<IconButton
						aria-label="edit"
						size="small"
						href={`update-product/${row.productId}`}
					>
						<EditIcon />
					</IconButton>
				</TableCell>
				<TableCell align="right" size="small">
					<IconButton
						aria-label="delete"
						size="small"
						onClick={() => deleteProduct(producerId, productId)}
					>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow key={"productDetail" + row.productId}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow key={"productDetailHeader" + row.productId}>
										<TableCell>
											<Typography color="primary">Food Category</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">Ingredients</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">Cuisine</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">Description</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">Course</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">Dietary</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow key={"productDetailBody" + row.productId}>
										<TableCell>
											<Typography>{row?.foodCategory}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{row?.ingredients}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{row.cuisine}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{row.description}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{row.course}</Typography>
										</TableCell>
										<TableCell>
											{row.glutenFree && <Typography>Gluten-Free </Typography>}
											{row.vegan && <Typography>Vegan </Typography>}
											{row.vegetarian && <Typography>Vegetarian</Typography>}
											{row.dairyFree && <Typography>Dairy Free</Typography>}
											{row.containsNuts && (
												<Typography>Contains Nuts</Typography>
											)}
											{row.halal && <Typography>Halal</Typography>}
											{row.organic && <Typography>Organic</Typography>}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};
