/** @format */

import {
	PRODUCER_COLLECTION,
	PRODUCER_ID_PREFIX,
	STRIPE_ACCOUNT_COLLECTION,
} from "./globalVariables";

import firebase from "firebase";
import { useState } from "react";

export const useGenerateAccountCreationLink = (): [
	boolean,
	() => void,
	string,
] => {
	const [loading, setLoading] = useState(true);
	const [creationLink, setCreationLink] = useState("");

	const generateLink = async () => {
		let newAccount = firebase.functions().httpsCallable("createConnectAccount");
		await newAccount({
			producerId: PRODUCER_ID_PREFIX + firebase.auth().currentUser?.uid,
			country: "US",
		})
			.then((response) => {
				setCreationLink(response.data.url);
				setLoading(false);
			})
			.catch((error) => {
				console.error(
					"Error while creating a new Stripe Connect Account Link",
					error.code,
					error.message,
				);
			});
	};
	return [loading, generateLink, creationLink];
};

export const useStripeAccountInformation = (): [
	object | undefined,
	() => void,
	boolean,
] => {
	const [loading, setLoading] = useState(true);
	const [accountInformation, setAccountInformation] = useState<object>();

	const getAccountInformation = () => {
		const stripeAccountRef = firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(PRODUCER_ID_PREFIX + firebase.auth().currentUser?.uid)
			.collection(STRIPE_ACCOUNT_COLLECTION)
			.get();

		stripeAccountRef.then((doc) => {
			doc.forEach((accountInformationDoc) => {
				setAccountInformation(accountInformationDoc);
				setLoading(false);
			});
		});
	};

	return [accountInformation, getAccountInformation, loading];
};
