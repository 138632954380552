/**
 * FeedCard to represent foodProducts
 *
 * @format
 */

import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardMedia,
	Chip,
	Divider,
	Grid,
	IconButton,
} from "@mui/material";

import DietaryTags from "components/product/DietaryTags";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import Typography from "@mui/material/Typography";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { formatAMPM } from "scripts/globalFunctions";
import { generateOpeningHourTag } from "scripts/intervalFunctions";

type Props = {
	data: any;
};

const FeedCard: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const productId = props.data.productId;
	const producerId = props.data.producerId;
	const openingHours = props.data.producer.openingHours;
	const address =
		props.data.producer.address.postalCode +
		" " +
		props.data.producer.address.city +
		", " +
		props.data.producer.address.state;
	const productName =
		props.data.productName.length > 20
			? props.data.productName.slice(0, 20) + "..."
			: props.data.productName;
	const title =
		props.data.producer.displayName.length > 35
			? props.data.producer.displayName.slice(0, 35) + "..."
			: props.data.producer.displayName;
	const subTitle = address.length > 35 ? address.slice(0, 35) + "..." : address;
	const price = props.data.price;

	let openingHourTag: string = "";

	const tagStatus = generateOpeningHourTag(openingHours);
	if (tagStatus === "ON_REQUEST") {
		openingHourTag = "On request";
	} else if (tagStatus.includes("ORDER_UNTIL")) {
		const hour = tagStatus.split(" ")[1];
		openingHourTag = `Order by ${formatAMPM(hour)}`;
	} else if (tagStatus === "PRE_ORDER") {
		openingHourTag = "Pre-order";
	} else {
		openingHourTag = "";
	}
	return (
		<Grid
			item
			xs={12}
			sm={6}
			lg={4}
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			<Card
				sx={{ maxWidth: 360, maxHeight: 425, minWidth: 360, minHeight: 425 }}
			>
				<Grid item>
					<Link
						to={{
							pathname: `/snaq/${productId}/${producerId}`,
							state: { data: props.data },
						}}
						style={{ textDecoration: "none" }}
					>
						<CardMedia
							component="img"
							height="210"
							image={props.data.productImage}
							alt={props.data.productName}
						/>
						<Box mt={-4.0} ml={1}>
							<Chip
								label={openingHourTag}
								variant="filled"
								color="secondary"
								size="small"
								style={{ backgroundColor: "white", color: "#000" }}
								icon={<FontAwesomeIcon icon={faClock} />}
							/>
						</Box>
					</Link>
				</Grid>
				<CardContent>
					<Link
						to={{
							pathname: `/snaq/${productId}/${producerId}`,
							state: { data: props.data },
						}}
						style={{ textDecoration: "none" }}
					>
						<Box display="flex" flexDirection="row" my={0.5}>
							<Box flexGrow={1}>
								<Typography variant="h3">{productName}</Typography>
							</Box>
							<Box sx={{ fontWeight: "bold" }}>
								<Chip label={`$ ${price}`} variant="filled" color="secondary" />
							</Box>
						</Box>
						<Box
							overflow="auto"
							flexDirection="row"
							display="flex"
							my={2}
							sx={{
								scrollbarColor: "transparent",
								"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
									backgroundColor: "transparent",
								},
							}}
						>
							<DietaryTags data={props.data} />
						</Box>
					</Link>
					<Divider variant="middle">Snaqer</Divider>
					<Grid container flexDirection="row" display="flex">
						<Grid item xs={2} md={2}>
							<Link
								to={{
									pathname: `/snaqer/${producerId}`,
									state: { data: props.data },
								}}
							>
								<Avatar
									key={"profileImage"}
									src={props.data.producer.profileImage}
									sx={{ width: 40, height: 40 }}
								/>
							</Link>
						</Grid>
						<Grid item xs={8} md={8}>
							<Link
								to={{
									pathname: `/snaqer/${producerId}`,
									state: { data: props.data },
								}}
								style={{ textDecoration: "none" }}
							>
								<Typography variant="body1">{title}</Typography>
								<Typography variant="caption">{subTitle}</Typography>
							</Link>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

const classes = {
	priceTag: {
		fontWeight: "bold",
	},
};
export default FeedCard;
