/**
 * Import the generateEnv function to export the values depending on the environment
 *
 * @format */
//

import generateEnv from "./generateEnv";

const {
  stripePublishableKeyVal,
  googleWebclientIdKeyVal,
  googleIosIdKeyVal,
  googleAndroidIdKeyVal,
  apiKeyVal,
  authDomainKeyVal,
  databaseURLKeyVal,
  projectIdKeyVal,
  storageBucketKeyVal,
  messagingSenderIdKeyVal,
  appIdKeyVal,
  measurementIdKeyVal,
  algoliaAppKeyVal,
  algoliaKeyVal,
} = generateEnv();

export const stripePublishableKey = stripePublishableKeyVal;
export const googleWebclientIdKey = googleWebclientIdKeyVal;
export const googleIosIdKey = googleIosIdKeyVal;
export const googleAndroidIdKey = googleAndroidIdKeyVal;
export const apiKey = apiKeyVal;
export const authDomainKey = authDomainKeyVal;
export const databaseURLKey = databaseURLKeyVal;
export const projectIdKey = projectIdKeyVal;
export const storageBucketKey = storageBucketKeyVal;
export const messagingSenderIdKey = messagingSenderIdKeyVal;
export const appIdKey = appIdKeyVal;
export const measurementIdKey = measurementIdKeyVal;
export const algoliaApp = algoliaAppKeyVal;
export const algoliaKey = algoliaKeyVal;
