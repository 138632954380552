/**
 *
 * PriceCalculation for the basket screen to ge the total amount in dollar.
 *
 * @format
 */

import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import theme from "themes/materialUi";

type Props = {
	productPrice: number;
	amount: number;
	modifierSum?: number;
	getTotalPrice?: any;
};

const PriceCalculation: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const amount = props.amount ? props.amount : 0;
	const productPrice = props.productPrice ? props.productPrice : 0;
	let modifierSum = props.modifierSum ? props.modifierSum : 0;
	let totalAmount = (Number(productPrice) + Number(modifierSum)) * amount;
	totalAmount = Math.round((totalAmount + Number.EPSILON) * 100) / 100;

	useEffect(() => {
		props.getTotalPrice && props.getTotalPrice(totalAmount);
	});

	return (
		<Grid
			container
			display="flex"
			justifyContent="flex-end"
			flex-direction="row"
			m={3}
		>
			{props.modifierSum ? (
				<>
					<Grid xs={12} md={12} mr={6}>
						<Box display="flex">
							<Box flexGrow={1}>
								<Typography variant="h3">Additions </Typography>
							</Box>
							<Box>
								<Typography>
									${" "}
									{(
										Math.round((modifierSum * amount + Number.EPSILON) * 100) /
										100
									).toFixed(2)}
								</Typography>
							</Box>
						</Box>
					</Grid>
				</>
			) : null}
			<Grid item xs={12} md={12} mt={3} mr={3}>
				<Divider sx={{ marginRight: "30px" }} />
				<Box component="span" display="flex" justifyContent="flex-end" m={3}>
					<Typography variant="h6">Price: &nbsp;</Typography>
					<Typography variant="h6"> $ {totalAmount.toFixed(2)}</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};

export default PriceCalculation;
