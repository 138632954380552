/**
 *
 * All the products for a producer in the producer interface
 *
 * @format */

import Layout from "../../components/layout/Layout";
import ProductTable from "components/product/ProductTable";
import { trackEvent } from "scripts/intercom";
import { useEffect } from "react";

const IndexPage: React.FunctionComponent = (): JSX.Element => {
	useEffect(() => {
		return trackEvent("ProductPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Your Snaqs">
			<ProductTable />
		</Layout>
	);
};

export default IndexPage;
