/**
 *
 * Map for the producer location in the producer detail screen
 *
 * @format
 */

import { Box, Grid } from "@mui/material";
import React, { useState } from "react";

import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import mapStyle from "./MapStyle";

type Props = {
	addressInformation: any;
};

const mapStyles = {
	width: "100%",
	height: "30vh",
};

const ProducerMap: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const addressInformation = props.addressInformation;
	const api_key: string = process.env.REACT_APP_MAP_KEY!;
	const [zoom, setZoom] = useState(11);
	const marker = [
		{
			location: {
				lat: addressInformation.geolocation._lat,
				lng: addressInformation.geolocation._long,
			},
		},
	];

	const defaultCenter = {
		lat: addressInformation.geolocation._lat,
		lng: addressInformation.geolocation._long,
	};
	const getMapOptions = (_maps: any) => {
		return {
			disableDefaultUI: true,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: true,
			styles: mapStyle,
		};
	};

	return (
		<>
			<Box width="100%" height="40vh">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: "AIzaSyAbAH03x3Zo0vjzt1TABIaW5-eaGOdNZxM",
					}}
					defaultCenter={defaultCenter}
					defaultZoom={zoom}
					options={getMapOptions}
				>
					{marker.map((place) => (
						<Marker
							key={place.location.lat}
							lat={place.location.lat}
							lng={place.location.lng}
						/>
					))}
				</GoogleMapReact>
			</Box>
		</>
	);
};

export default ProducerMap;
