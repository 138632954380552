/** @format */

import { Alert, AlertTitle, Typography } from "@mui/material";

import LinearProgress from "@mui/material/LinearProgress";
import { OpeningHoursRow } from "./OpeningHoursRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import { useOpeningHours } from "scripts/firestoreOpeningHours";

export default function OpeningHoursTable() {
	const [hoursLoading, noHours, openingHours, getOpeningHours] =
		useOpeningHours();

	useEffect(() => {
		getOpeningHours();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (hoursLoading) {
		return <LinearProgress />;
	} else {
		return noHours ? (
			<>
				<Alert severity="info" elevation={3}>
					<AlertTitle>No hours added</AlertTitle>
					Please add your opening hours.
				</Alert>
			</>
		) : (
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table" key="id">
					<TableHead id="header">
						<TableRow key="rowHeader">
							<TableCell key="rowHeaderDate">Date</TableCell>
							<TableCell key="rowHeaderFrom">Open From</TableCell>
							<TableCell key="rowHeaderTill">Open Until</TableCell>
							<TableCell key="rowHeaderDelete" align="right" size="small">
								Delete
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody key="body">
						{openingHours === undefined ? (
							<Typography>...loading</Typography>
						) : (
							openingHours.map((data: any) => {
								const date = data[0];
								const timeArray = data[1];
								return timeArray.map((d: any) => {
									return (
										<OpeningHoursRow
											date={date}
											time={d[1]}
											key={date + d[1]}
										/>
									);
								});
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
}
