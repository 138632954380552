/**
 *
 * This file contains query functions for firestore
 *
 * @format */

import * as globalVariables from "./globalVariables";

import {
	convertDateStringToDate,
	dateInPast,
	getProducerId,
	getUserId,
} from "./globalFunctions";

import FoodProduct from "../interfaces/ProductInterface";
import Geocoder from "react-geocode";
import Producer from "interfaces/ProducerInterface";
import ProductModifierInterface from "interfaces/ProductModifierInterface";
import firebase from "firebase";
import { useState } from "react";

var geohash = require("ngeohash");

const {
	USERS_COLLECTION,
	PRODUCER_COLLECTION,
	ADDRESSES_COLLECTION,
	ORDERS_COLLECTION,
} = globalVariables;

// Get user information
export const useUser = (
	userId = getUserId(),
): [object | undefined, boolean, () => void] => {
	const [userInformation, setUserInformation] = useState<object | undefined>();
	const [loading, setLoading] = useState(true);

	const getUser = (newUserId = userId) => {
		return firebase
			.firestore()
			.collection(USERS_COLLECTION)
			.doc(newUserId)
			.onSnapshot((doc) => {
				setUserInformation(doc.data());
				setLoading(false);
			});
	};
	return [userInformation, loading, getUser];
};

// Change producer status
export const changeProducerStatus = async (
	producerId: string,
	status: boolean,
) => {
	firebase.firestore().collection(PRODUCER_COLLECTION).doc(producerId).update({
		isActive: status,
	});
};

// Change producer status
export const changeProductStatus = async (
	producerId: string,
	productId: string,
	status: boolean,
) => {
	firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS)
		.doc(productId)
		.update({
			isActive: status,
		});
};

// Get orders for a user
export const useOrders = (
	filterByName: string,
	filterById: string,
	filterByDate: string = "none",
): [undefined | object, () => void, boolean, boolean] => {
	const [orders, setOrders] = useState<object>();
	const [loading, setLoading] = useState(true);
	const [noOrders, setNoOrders] = useState(false);
	const getOrders = () => {
		try {
			return firebase
				.firestore()
				.collection(ORDERS_COLLECTION)
				.where(filterByName, "==", filterById)
				.orderBy("unixTime", "desc")
				.onSnapshot((data) => {
					if (data.empty) {
						setNoOrders(true);
						setLoading(false);
					} else {
						let orderArray: Array<object> = [];
						data.forEach((order) => {
							const today = new Date().setHours(0, 0, 0, 0);
							const pickUpTime = convertDateStringToDate(
								order.data().pickUpTime.split(" ••• ")[0],
							);
							if (filterByDate === "upcoming") {
								if (!dateInPast(today, pickUpTime)) {
									orderArray.push({ orderId: order.id, ...order.data() });
								}
							} else if (filterByDate === "past") {
								if (dateInPast(today, pickUpTime)) {
									orderArray.push({ orderId: order.id, ...order.data() });
								}
							} else {
								orderArray.push({ orderId: order.id, ...order.data() });
							}
						});
						setOrders(orderArray);
						setLoading(false);
					}
				});
		} catch (error) {
			console.error("Error while getting orderDetails", error);
		}
	};
	return [orders, getOrders, loading, noOrders];
};

// Get producer information
export const useProducer = (): [object, object, boolean, () => void] => {
	const [producerInformation, setProducerInformation] = useState<any>();
	const [address, setAddress] = useState<any>();
	const [loading, setLoading] = useState(true);
	const getProducer = (producerId: string = getProducerId(getUserId())) => {
		return firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.onSnapshot((doc) => {
				const subscribe = async () => {
					await doc.ref
						.collection(ADDRESSES_COLLECTION)
						.get()
						.then((addressDoc) => {
							addressDoc.forEach((data) => {
								setAddress(data.data());
							});
						});
					if (doc.exists) {
						const producerData = doc.data();
						const sub = () => {
							setProducerInformation(producerData);
							setLoading(false);
						};
						return sub();
					} else {
						const sub = () => {
							setProducerInformation({});
							setLoading(false);
						};
						return sub();
					}
				};
				return subscribe();
			});
	};
	return [producerInformation, address, loading, getProducer];
};

// Add producer Account Information like address and accepted terms
// That's the second step in BAP. In the first step (creating an account, 'createProducer' will be used)
export const addProducerAccount = async (data: Producer) => {
	const batch = firebase.firestore().batch();

	const accountRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(getProducerId(getUserId()));

	const addressRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(getProducerId(getUserId()))
		.collection(ADDRESSES_COLLECTION)
		.doc();

	const addressCollectionRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(getProducerId(getUserId()))
		.collection(ADDRESSES_COLLECTION);

	const accountData = {
		terms: data.terms,
		isApproved: false,
		isActive: false,
		isPickupEnabled: true,
		becomeAProducerStatus: {
			startedFlow: true,
			startedAccount: true,
			applied: true,
			appliedAt: firebase.firestore.FieldValue.serverTimestamp(),
		},
		producerId: getProducerId(getUserId()),
	};

	const getLatLng = () => {
		const addressToConvert = {
			street: data.street,
			houseNumber: data.houseNumber,
			postalCode: data.postalCode,
			city: data.city,
			state: data.state,
			country: data.country,
		};
		return convertAddressToLatLng(addressToConvert);
	};

	let lat = 0;
	let lng = 0;
	await getLatLng().then((coords) => {
		lat = (coords as any)?.lat;
		lng = (coords as any)?.lng;
	});
	const geohashLatLon = geohash.encode(lat, lng);

	const addressData = {
		street: data.street,
		houseNumber: data.houseNumber,
		postalCode: data.postalCode,
		city: data.city,
		state: data.state,
		country: data.country,
		geolocation: new firebase.firestore.GeoPoint(lat, lng),
		geohash: geohashLatLon,
		producerId: getProducerId(getUserId()),
	};

	try {
		batch.set(accountRef, accountData, { merge: true });
	} catch (error) {
		console.error("addProducerAccount Error: ", error);
	}

	await addressCollectionRef
		.get()
		.then((snap) => {
			if (snap.size > 0) {
				snap.forEach((doc) => {
					const ref = firebase
						.firestore()
						.collection(PRODUCER_COLLECTION)
						.doc(getProducerId(getUserId()))
						.collection(ADDRESSES_COLLECTION)
						.doc(doc.id);
					batch.update(ref, addressData);
				});
			} else {
				batch.set(addressRef, addressData, { merge: true });
			}
			batch.commit();
		})
		.catch((error) => {
			console.error("Error while adding address:", error);
		});
};

// Add FoodProduct
export const addProduct = (data: Object) => {
	const userId = getUserId();
	const producerId = getProducerId(getUserId());
	const userData = {
		userId: userId,
		producerId: producerId,
	};
	const dateTime = {
		createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
		createdUnix: Date.now(),
	};
	const setData = { ...data, ...userData, ...dateTime };
	const id = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS)
		.add(setData)
		.then((doc) => {
			return doc.id;
		})
		.catch((error) => {
			console.error(error);
		});
	return id;
};

// Add FoodProductModifier
export const addProductModifier = (data: Object) => {
	const userId = getUserId();
	const producerId = getProducerId(getUserId());
	const userData = {
		userId: userId,
		producerId: producerId,
	};
	const dateTime = {
		createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
		createdUnix: Date.now(),
	};
	const setData = { ...data, ...userData, ...dateTime };
	const id = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS_MODIFIERS)
		.add(setData)
		.then((doc) => {
			doc.set(
				{
					modifierId: doc.id,
				},
				{ merge: true },
			);
			return doc.id;
		})
		.catch((error) => {
			console.error(error);
		});
	return id;
};

// Add FoodProductModifier
export const addProductModifierOption = (modifierId: string, data: Object) => {
	const producerId = getProducerId(getUserId());

	const dateTime = {
		createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
		createdUnix: Date.now(),
	};
	const setData = { ...data, ...dateTime };

	const id = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS_MODIFIERS)
		.doc(modifierId)
		.set(
			{
				modifierOptions: {
					[`${(setData as any).optionName}`]: (setData as any).price,
				},
				modifierId: modifierId,
			},
			{ merge: true },
		)
		.catch((error) => {
			console.error(error);
		});
	return id;
};

// Adds modifierId and name ot foodProduct
export const addModifierToFoodProduct = (
	producerId: string,
	foodProductId: string,
	modifierId: string,
	modifierName: string,
) => {
	firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS)
		.doc(foodProductId)
		.set(
			{
				modifiers: {
					[modifierId]: modifierName,
				},
			},
			{ merge: true },
		);
};

// Check if modifier is in use
export const useModifierInUseStatus = (
	modifierId: string,
): [boolean, Object | undefined, () => void] => {
	const producerId = getProducerId(getUserId());
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState(false);

	const getModifierInUseStatus = () => {
		firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(globalVariables.FOOD_PRODUCTS)
			.get()
			.then(async (docs) => {
				docs.forEach((doc) => {
					const productData = doc.data();
					if (typeof productData.modifiers !== "undefined") {
						if (Object.keys(productData.modifiers).length > 0) {
							if (productData.modifiers.hasOwnProperty(modifierId)) {
								setStatus(true);
								setLoading(false);
							} else {
								setStatus(false);
								setLoading(false);
							}
						}
					}
				});
			});
	};
	return [loading, status, getModifierInUseStatus];
};

// Adds modifierId and name ot foodProduct
export const removeModifierToFoodProduct = (
	producerId: string,
	foodProductId: string,
	modifierId: string,
) => {
	firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS)
		.doc(foodProductId)
		.set(
			{
				modifiers: {
					[modifierId]: firebase.firestore.FieldValue.delete(),
				},
			},
			{ merge: true },
		);
};

// Fetch modifiers
export const useModifiers = (): [
	boolean,
	boolean,
	() => void,
	Array<ProductModifierInterface> | undefined,
] => {
	const producerId = getProducerId(getUserId());
	const [loading, setLoading] = useState(true);
	const [noModifiers, setNoModifiers] = useState(true);
	const [modifiers, setModifiers] = useState([]);

	const getModifiers = () => {
		firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(globalVariables.FOOD_PRODUCTS_MODIFIERS)
			.onSnapshot((data) => {
				if (!data.empty) {
					const modifierArray: any = [];
					data.forEach(async (modifier) => {
						const data = {
							...modifier.data(),
							modifierId: modifier.id ? modifier.id : undefined,
						};
						modifierArray.push(data);
					});
					setModifiers(modifierArray);
					setNoModifiers(false);
					setLoading(false);
				} else {
					setNoModifiers(true);
					setLoading(false);
				}
			});
	};

	return [loading, noModifiers, getModifiers, modifiers];
};

// Fetch modifier options
export const useModifierOptions = (
	modifierId: string,
): [boolean, Object | undefined, () => void] => {
	const producerId = getProducerId(getUserId());
	const [loading, setLoading] = useState(true);
	const [modifierOptions, setModifierOptions] = useState({});

	const getOptions = () => {
		firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(globalVariables.FOOD_PRODUCTS_MODIFIERS)
			.doc(modifierId)
			.onSnapshot((data) => {
				if (data.exists) {
					const modifierOption = (data as any)?.data();
					setModifierOptions(modifierOption);
					setLoading(false);
				}
			});
	};

	return [loading, modifierOptions, getOptions];
};

// Update Product
export const updateProduct = async (
	productId: string,
	producerId: string,
	data: any,
) => {
	try {
		firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(globalVariables.FOOD_PRODUCTS)
			.doc(productId)
			.set(data, { merge: true });
	} catch (error) {
		console.error("Error", error);
	}
};

// Update Product
export const updateModifier = async (
	modifierId: string,
	producerId: string,
	data: any,
) => {
	try {
		firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(globalVariables.FOOD_PRODUCTS_MODIFIERS)
			.doc(modifierId)
			.set(data, { merge: true });
	} catch (error) {
		console.error("Error", error);
	}
};

export const changeProductImagePath = async (
	producerId: string,
	productId: string,
	url: string,
) => {
	await firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS)
		.doc(productId)
		.set({ productImage: url }, { merge: true });
};

export const changeProducerProfileImagePath = async (
	producerId: string,
	url: string,
) => {
	await firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.set({ profileImage: url }, { merge: true });
};

export const changeProfileImagePath = async (userId: string, url: string) => {
	await firebase
		.firestore()
		.collection(USERS_COLLECTION)
		.doc(userId)
		.set({ profileImage: url }, { merge: true });
};

// Delete Product
export const deleteProduct = async (producerId: string, productId: string) => {
	const productRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.PRODUCTS_COLLECTION)
		.doc(productId);
	const querySnapshot = await productRef
		.collection(globalVariables.AVAILABILITY_COLLECTION)
		.get();
	querySnapshot.forEach((doc) => {
		doc.ref.delete().catch((error) => {
			console.error("error while deleting availability", error);
		});
	});
	await productRef.delete().catch((error) => {
		console.error("Error while deleting a product", error);
	});
};

// Update producer data in firestore -> when data gets updated in accountInformationScreen
export const updateProducer = async (producerAccountData: Producer) => {
	const getLatLng = () => {
		const addressToConvert = {
			street: producerAccountData.street,
			houseNumber: producerAccountData.houseNumber,
			postalCode: producerAccountData.postalCode,
			city: producerAccountData.city,
			state: producerAccountData.state,
			country: producerAccountData.country,
		};
		return convertAddressToLatLng(addressToConvert);
	};

	let lat = 0;
	let lng = 0;
	await getLatLng().then((coords) => {
		lat = (coords as any)?.lat;
		lng = (coords as any)?.lng;
	});
	const geohashLatLon = geohash.encode(lat, lng);

	const addressData = {
		street: producerAccountData.street,
		houseNumber: producerAccountData.houseNumber,
		postalCode: producerAccountData.postalCode,
		city: producerAccountData.city,
		state: producerAccountData.state,
		country: producerAccountData.country,
		geolocation: new firebase.firestore.GeoPoint(lat, lng),
		geohash: geohashLatLon,
		producerId: getProducerId(getUserId()),
	};

	const accountData = {
		displayName: producerAccountData.displayName,
		firstName: producerAccountData.firstName,
		lastName: producerAccountData.lastName,
		email: producerAccountData.email,
		phone: producerAccountData.phone,
		bio: producerAccountData.bio,
		isDeliveryEnabled: producerAccountData.isDeliveryEnabled,
		isPickupEnabled: true,
		lastUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
		writeCount: firebase.firestore.FieldValue.increment(1),
	};

	const batch = firebase.firestore().batch();

	const accountRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(getProducerId(getUserId()));

	const addressRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(getProducerId(getUserId()))
		.collection(ADDRESSES_COLLECTION)
		.doc();

	const addressCollectionRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(getProducerId(getUserId()))
		.collection(ADDRESSES_COLLECTION);

	await addressCollectionRef
		.get()
		.then((snap) => {
			if (snap.size > 0) {
				snap.forEach((doc) => {
					const ref = firebase
						.firestore()
						.collection(PRODUCER_COLLECTION)
						.doc(getProducerId(getUserId()))
						.collection(ADDRESSES_COLLECTION)
						.doc(doc.id);
					batch.update(ref, addressData);
				});
			} else {
				batch.set(addressRef, addressData, { merge: true });
			}
			batch.update(accountRef, accountData);

			batch.commit();
		})
		.catch((error) => {
			console.error("Error to update or set address", error);
		});
};

// Update user data in firestore
export const updateUser = async (userProfileData: any) => {
	const userId = getUserId();
	firebase
		.firestore()
		.collection(USERS_COLLECTION)
		.doc(userId)
		.update({
			...userProfileData,
			lastUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
			writeCount: firebase.firestore.FieldValue.increment(1),
		})
		.catch((error) => {
			console.error("Error while updating userInformation", error);
		});
	if (userProfileData.email) {
		firebase.auth().currentUser?.updateEmail(userProfileData.email);
	}
};

// Delete Modifier
export const deleteModifier = async (
	producerId: string,
	modifierId: string,
) => {
	const productRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS_MODIFIERS)
		.doc(modifierId);
	await productRef.delete().catch((error) => {
		console.error("Error while deleting a product", error);
	});
};

// Delete Modifier
export const deleteModifierOption = async (
	producerId: string,
	modifierId: string,
	name: string,
) => {
	firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(globalVariables.FOOD_PRODUCTS_MODIFIERS)
		.doc(modifierId)
		.set(
			{
				modifierOptions: {
					[name]: firebase.firestore.FieldValue.delete(),
				},
			},
			{ merge: true },
		)
		.catch((error) => {
			console.error("Error while deleting a product", error);
		});
};

// Update times when a user is logged in
export const updateLoginTimes = () => {
	firebase
		.firestore()
		.collection(USERS_COLLECTION)
		.doc(firebase.auth().currentUser?.uid)
		.update({
			lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
			loginCount: firebase.firestore.FieldValue.increment(1),
		});
};

// Delete account data in auth, firestore, and storage.
export const deleteUser = () => {
	const user = firebase.auth().currentUser;
	if (user) {
		user.delete().catch((error) => {
			console.error("Error while deleting user execution", error);
		});
	} else {
		console.error("Error: No user found to delete in Auth");
	}
};

// Create Producer
// This is used in the BAP flow on step 1
export const createProducer = async (producerData: Producer) => {
	const batch = firebase.firestore().batch();

	const producerRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(getProducerId(getUserId()));

	const data = {
		firstName: producerData.firstName,
		lastName: producerData.lastName,
		email: producerData.email,
		displayName: producerData.displayName,
		phone: producerData.phone,
		bio: producerData.bio,
		isActive: false,
		isApproved: false,
		lastUpdateTime: firebase.firestore.FieldValue.serverTimestamp(),
		writeCount: firebase.firestore.FieldValue.increment(1),
		becomeAProducerStatus: {
			startedFlow: true,
		},
		userId: getUserId(),
	};

	batch.set(producerRef, data, { merge: true });
	batch.set(
		firebase.firestore().collection(USERS_COLLECTION).doc(getUserId()),
		{
			additionalInformation: {
				startedProducerFlow: true,
			},
		},
		{ merge: true },
	);
	batch
		.commit()
		.then(() => {
			let newProducer = firebase.functions().httpsCallable("newProducer");
			newProducer({
				userId: getUserId(),
			}).catch((error) => {
				console.error("ERROR :", error);
			});
		})
		.catch((error) => {
			console.error("Error while writing batch for new producer", error);
		});
};

// Sign out
export const signOut = async () => {
	try {
		await firebase.auth().signOut();
	} catch (error) {
		console.error("Error while signing out", error);
	}
};

const convertAddressToLatLng = async (address: Object) => {
	let newAddress = Object.values(address);
	const formattedAddress = JSON.stringify(newAddress);

	let geoCoordinates;
	// Geocoder.fromAddress(formattedAddress).then((response) => {
	// 	const { lat, lng } = response.results[0].geometry.location;
	// });

	return Geocoder.fromAddress(formattedAddress)
		.then((json) => {
			if (json.status === "OK") {
				geoCoordinates = json.results[0].geometry.location;
				return geoCoordinates;
			} else if (json.results.length !== 0) {
				console.error("There is any error when using Geocoder");
			}
		})
		.catch((error) => {
			console.error(error);
		});
};

// Get Producers Product data
export const useProducerProducts = (): [
	undefined | object,
	() => void,
	boolean,
	boolean,
] => {
	const [products, setProducts] = useState<object>([]);
	const [loading, setLoading] = useState(true);
	const [emptyProducts, setEmptyProducts] = useState<boolean>(true);

	const getProducerProducts = (
		activeOnly: boolean = false,
		producerId = getProducerId(getUserId()),
	) => {
		try {
			return firebase
				.firestore()
				.collection(PRODUCER_COLLECTION)
				.doc(producerId)
				.collection(globalVariables.FOOD_PRODUCTS)
				.orderBy("createdDtm", "asc")
				.onSnapshot((data) => {
					if (!data.empty) {
						const productArray: any = [];
						data.forEach(async (singleProduct) => {
							const newProductDataPush = async () => {
								const productObject = singleProduct.data();
								productObject.productId = singleProduct.id;
								await productArray.push(productObject);
							};
							if (activeOnly) {
								if (singleProduct.get("isActive")) {
									await singleProduct.ref
										.collection(globalVariables.AVAILABILITY_COLLECTION)
										.get()
										.then(() => {
											newProductDataPush();
										});
								}
							} else {
								newProductDataPush();
							}
							setProducts(productArray.concat([]));
						});
						setEmptyProducts(false);
					} else {
						setEmptyProducts(true);
					}
					setLoading(false);
				});
		} catch (error) {
			console.error("Error while fetching producer products", error);
		}
	};

	return [products, getProducerProducts, loading, emptyProducts];
};

// Fetch Product information for Producer to edit
export const useProduct = (
	productId: string,
	producerId: string,
): [FoodProduct | undefined, () => void, boolean] => {
	const [product, setProduct] = useState<FoodProduct | undefined>();
	const [loading, setLoading] = useState(true);

	const getProduct = async () => {
		const unsubscribe = () =>
			firebase
				.firestore()
				.collection(PRODUCER_COLLECTION)
				.doc(producerId)
				.collection(globalVariables.FOOD_PRODUCTS)
				.doc(productId)
				.onSnapshot((doc) => {
					const subscribe = () => {
						if (doc.exists) {
							const sub = () => {
								setProduct(doc.data() as any);
								setLoading(false);
							};
							return sub();
						} else {
							const sub = () => {
								setLoading(false);
								setProduct({} as any);
							};
							return sub();
						}
					};
					return subscribe();
				});
		unsubscribe();
	};
	return [product, getProduct, loading];
};

// Fetch Product information for Producer to edit
export const useProductModifier = (
	modifierId: string,
	producerId: string,
): [ProductModifierInterface | undefined, () => void, boolean] => {
	const [modifier, setModifier] = useState<
		ProductModifierInterface | undefined
	>();
	const [loading, setLoading] = useState(true);

	const getModifier = async () => {
		const unsubscribe = () =>
			firebase
				.firestore()
				.collection(PRODUCER_COLLECTION)
				.doc(producerId)
				.collection(globalVariables.FOOD_PRODUCTS_MODIFIERS)
				.doc(modifierId)
				.onSnapshot((doc) => {
					const subscribe = () => {
						if (doc.exists) {
							const sub = () => {
								setModifier(doc.data() as any);
								setLoading(false);
							};
							return sub();
						} else {
							const sub = () => {
								setLoading(false);
								setModifier({} as any);
							};
							return sub();
						}
					};
					return subscribe();
				});
		unsubscribe();
	};
	return [modifier, getModifier, loading];
};

export const useRole = (): [boolean, string, () => void] => {
	const [loading, setLoading] = useState(true);
	const [role, setRole] = useState("");
	const userId = getUserId();

	const getRole = async () => {
		await firebase
			.firestore()
			.collection(USERS_COLLECTION)
			.doc(userId)
			.onSnapshot((user) => {
				const userData = user.data()!;
				if (typeof userData === "undefined") {
					setRole("user");
					setLoading(false);
				} else if (userData.role === "user") {
					setRole("user");
					setLoading(false);
				} else if (userData.role === "producer") {
					setRole("producer");
					setLoading(false);
				} else {
					setRole("");
					setLoading(false);
				}
			});
	};
	return [loading, role, getRole];
};
