/**
 *
 * Map for all the producer location in the map screen
 *
 * @format
 */

import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";

import Card from "@mui/material/Card";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import { getLocation } from "scripts/globalFunctions";
import mapStyle from "./MapStyle";

type Props = {
	data?: any;
};

const mapStyles = {
	width: "100%",
	height: "100vh",
};

const Map: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
	const location = getLocation();
	const api_key: string = process.env.REACT_APP_MAP_KEY!;
	const [zoom, setZoom] = useState(11);
	const [visible, setVisible] = useState(false);

	const defaultCenter = {
		lat: location[0],
		lng: location[1],
	};

	const getMapOptions = (_maps: any) => {
		return {
			disableDefaultUI: true,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: true,
			styles: mapStyle,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_TOP,
			},
		};
	};

	return (
		<>
			{visible && (
				<Card>
					<Box p={2}>
						<Typography>Test</Typography>
					</Box>
				</Card>
			)}
			<Box width="100%" height="100vh">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: "AIzaSyAbAH03x3Zo0vjzt1TABIaW5-eaGOdNZxM",
					}}
					defaultCenter={defaultCenter}
					defaultZoom={zoom}
					options={getMapOptions}
				>
					{props.data.map((producerAddress: any) => {
						return (
							<Marker
								key={
									producerAddress.address.geolocation.latitude +
									producerAddress.address.geolocation.longitude
								}
								data={producerAddress}
								lat={producerAddress.address.geolocation.latitude}
								lng={producerAddress.address.geolocation.longitude}
							/>
						);
					})}
				</GoogleMapReact>
			</Box>
		</>
	);
};

export default Map;
