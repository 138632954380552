/**
 * Address form to apply as a Snaqer - Step 2
 *
 * @format
 */

import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	LinearProgress,
} from "@mui/material";
import { Field, Formik } from "formik";
import React, { useEffect } from "react";
import {
	addProducerAccount,
	useProducer,
} from "../../scripts/firestoreQueries";

import Button from "../../components/Button";
import CheckboxInput from "../../components/formik/CheckboxInput";
import FormInput from "../../components/formik/FormInput";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { becomeAProducerSchemaStep2 } from "../../scripts/formValidation";
import firebase from "firebase";

type Props = {
	func: Function;
};

const AddressForm: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const [producerInformation, producerAddress, producerLoading, getProducer] =
		useProducer();
	let initialValues = {
		...producerInformation,
		...producerAddress,
		terms: true,
	};

	useEffect(() => {
		return getProducer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onBackClick = () => {
		props.func(0);
	};

	const onSubmit = async (data: any) => {
		await addProducerAccount(data);
		const userAppliedAsProducer = firebase
			.functions()
			.httpsCallable("userAppliedAsProducer");
		userAppliedAsProducer(firebase.auth().currentUser?.uid).catch((error) => {
			console.error("ERROR while submitting BAP step2:", error);
		});
		props.func(2);
	};
	return (
		<>
			<Typography variant="h6" gutterBottom>
				Snaqer address
			</Typography>
			{producerLoading && <LinearProgress />}
			{!producerLoading && (
				<Formik
					initialValues={initialValues}
					validationSchema={becomeAProducerSchemaStep2}
					onSubmit={onSubmit}
				>
					{({ handleSubmit, isValid, dirty }) => (
						<>
							<Grid container spacing={3}>
								<Grid item xs={9}>
									<Field
										component={FormInput}
										name="street"
										placeholder="Street"
										autoComplete="Street"
										label="Street"
									/>
								</Grid>
								<Grid item xs={3}>
									<Field
										component={FormInput}
										name="houseNumber"
										placeholder="House number"
										label="House number"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="city"
										placeholder="City"
										label="City"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="state"
										placeholder="State/Province/Region"
										label="State/Province/Region"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="postalCode"
										placeholder="Zip / Postal code"
										label="Zip / Postal code"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="country"
										placeholder="Country"
										label="Country"
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										component={CheckboxInput}
										name="terms"
										placeholder="Terms"
										label={
											<Link
												href="https://www.snaqing.com/terms-of-service"
												target="_blank"
												color="inherit"
											>
												I agree to the Terms of Service
											</Link>
										}
										value={true}
									/>
								</Grid>
							</Grid>
							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<Button sx={{ my: 3, mx: 1 }} onClick={() => onBackClick()}>
									Back
								</Button>
								<Button
									variant="contained"
									sx={{ my: 3, mx: 1 }}
									onClick={() => handleSubmit()}
									disabled={!(isValid && dirty)}
								>
									Apply as a Snaqer
								</Button>
							</Box>
						</>
					)}
				</Formik>
			)}
			<Divider />
		</>
	);
};

export default AddressForm;
