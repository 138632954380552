/**
 *
 * Main order page
 *
 * @format */

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import Layout from "../../components/layout/Layout";
import PastOrders from "./PastOrders";
import Tab from "@mui/material/Tab";
import UpcomingOrders from "./UpcomingOrders";
import { trackEvent } from "scripts/intercom";

const OrderPage: React.FunctionComponent = (): JSX.Element => {
	const [value, setValue] = useState("1");

	const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	useEffect(() => {
		return trackEvent("OrderPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Orders">
			<TabContext value={value}>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: "divider",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<TabList onChange={handleChange} aria-label="lab API tabs example">
						<Tab label="Upcoming Orders" value="1" />
						<Tab label="Past Orders" value="2" />
					</TabList>
				</Box>
				<TabPanel value="1">
					<UpcomingOrders />
				</TabPanel>
				<TabPanel value="2">
					<PastOrders />
				</TabPanel>
			</TabContext>
		</Layout>
	);
};

export default OrderPage;
