/**
 *
 * Page for single conversation between user and producer
 *
 * @format */

import {
	Box,
	Card,
	Grid,
	IconButton,
	LinearProgress,
	Typography,
} from "@mui/material";
import { Field, Formik } from "formik";
import { addMessage, useMessage } from "scripts/messages/messageQueries";

import FormInput from "components/formik/FormInput";
import Layout from "components/layout/Layout";
import SendIcon from "@mui/icons-material/Send";
import SubNavigationBar from "components/navigation/SubNavigationBar";
import { checkSenderReceiverIds } from "scripts/messages/messageFunctions";
import { getUserId } from "scripts/globalFunctions";
import { trackEvent } from "scripts/intercom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ConversationPage: React.FunctionComponent = (): JSX.Element => {
	const location: any = useLocation();
	const userId = getUserId();
	const roomData = location.state.data;
	const roomId = roomData.roomId;
	const [message, getMessage, loading] = useMessage(roomId as string);
	const { senderId, receiverId } = checkSenderReceiverIds(
		roomData.participants,
	);
	const senderIsUser = senderId === userId;

	const onSubmit = (data: any) => {
		addMessage(receiverId, senderId, data.message);
	};

	useEffect(() => {
		return getMessage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return trackEvent("ConversationPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getReceiverName = () => {
		let name = "";
		if (typeof roomData.receiver !== "undefined") {
			if (senderIsUser) {
				name = roomData.receiver.displayName;
			} else {
				if (
					typeof roomData.receiver.firstName === "undefined" ||
					roomData.receiver.firstName === null
				) {
					if (typeof roomData.receiver.fullName === "undefined") {
						name = roomData.receiver.userId;
					} else {
						name = roomData.receiver.fullName;
					}
				} else {
					name = roomData.receiver.firstName;
				}
			}
		} else {
			name = "";
		}
		return name;
	};
	const receiverName = getReceiverName();

	return (
		<Layout title="Conversation">
			<SubNavigationBar />
			{loading ? (
				<>
					<LinearProgress />
				</>
			) : (
				<>
					{typeof roomData.receiver !== "undefined" && (
						<Typography>Conversation with {receiverName}</Typography>
					)}
					<Box m={3} mb={20}>
						<Card>
							{(message as any).map((message: any, index: number) => {
								let messageWasSentFromCurrentId = false;
								if ((message as any).senderId === senderId) {
									messageWasSentFromCurrentId = true;
								}
								return (
									<>
										{messageWasSentFromCurrentId ? (
											<Box
												p={2}
												display="flex"
												alignContent="flex-end"
												justifyContent="flex-end"
												alignItems="flex-end"
												alignSelf="flex-end"
											>
												<Box sx={{ margin: 1, width: "60%" }} key={index}>
													<Typography color="primary">
														{(message as any).message}
													</Typography>
												</Box>
											</Box>
										) : (
											<Box
												p={2}
												display="flex"
												justifyContent="flex-start"
												sx={{ margin: 1, width: "60%" }}
												key={index}
											>
												<Typography>{(message as any).message}</Typography>
											</Box>
										)}
									</>
								);
							})}
						</Card>
					</Box>
					<Box
						p={2}
						m={2}
						sx={{
							position: "fixed",
							bottom: 0,
							width: "80%",
						}}
					>
						<Card>
							<Box p={2}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Formik
										initialValues={{}}
										onSubmit={onSubmit}
										enableReinitialize
									>
										{({ handleSubmit }) => (
											<>
												<Grid item xs={11}>
													<Box m={1}>
														<Field
															component={FormInput}
															name="message"
															placeholder="Message"
															label="Message"
														/>
													</Box>
												</Grid>
												<Grid
													item
													xs={1}
													display="flex"
													justifyContent="center"
												>
													<IconButton
														onClick={() => {
															handleSubmit();
														}}
													>
														<SendIcon />
													</IconButton>
												</Grid>
											</>
										)}
									</Formik>
								</Grid>
							</Box>
						</Card>
					</Box>
				</>
			)}
		</Layout>
	);
};

export default ConversationPage;
