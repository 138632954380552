/**
 * Reset Password Page
 *
 * @format
 */

import React from "react";
import ResetPassord from "../pages/ResetPasswordPage";
import useTitle from "../hooks/useTitle";

const ResetPasswordPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Reset Password - Snaqing | Homemade food");

	return <ResetPassord />;
};

export default ResetPasswordPage;
