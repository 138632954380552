/**
 *
 * The card which shows main product information like name and description.
 *
 * @format
 */

import {
	Badge,
	Box,
	Card,
	CardMedia,
	Collapse,
	Divider,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import React, { useState } from "react";

import DietaryTags from "components/product/DietaryTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWalking } from "@fortawesome/free-solid-svg-icons";

type Props = {
	data: any;
};
const ProductInformationCard: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const productData = props.data;
	const [expanded, setExpanded] = useState(true);

	return (
		<>
			<Grid
				container
				display="flex"
				flexDirection="row"
				justifyContent="center"
				rowSpacing={2}
				columnSpacing={3}
			>
				<Grid item xs={12} md={6} lg={6}>
					<CardMedia
						component="img"
						height="400"
						image={productData.productImage}
						alt={productData.productName}
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<Box mb={1}>
						<Typography variant="h2">{productData.productName}</Typography>
						<Typography variant="caption">
							from: {productData.producer.displayName}
						</Typography>
					</Box>
					<Box mb={2}>
						<Typography>{productData.description}</Typography>
					</Box>
					<Box mb={2}>
						<Typography variant="h3">Ingredients</Typography>
						<Typography variant="body1">{productData.ingredients}</Typography>
					</Box>

					<Box mb={2}>
						<Typography variant="h3">Dietary</Typography>
						<Box
							overflow="auto"
							flexDirection="row"
							display="flex"
							sx={{
								scrollbarColor: "transparent",
								"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
									backgroundColor: "transparent",
								},
							}}
						>
							<DietaryTags data={props.data} />
						</Box>
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<Box>
							{/* <Badge color="secondary" badgeContent={0}>
								<FontAwesomeIcon icon={faWalking} />
							</Badge> */}
						</Box>
						<Box display="flex" justifyContent="flex-end">
							<Typography variant="h3">Price: &nbsp;</Typography>
							<Typography variant="h3"> $ {productData.price}</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default ProductInformationCard;
