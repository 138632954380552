/**
 * We import the environments variable is defined in .env
 * so we can write a function to generate variable base on the environment (TEST | STAGING | PROD)
 *
 * @format */

export default function genEnv() {
	let stripePublishableKeyVal = "";
	let googleWebclientIdKeyVal = "";
	let googleIosIdKeyVal = "";
	let googleAndroidIdKeyVal = "";
	let apiKeyVal = "";
	let authDomainKeyVal = "";
	let databaseURLKeyVal = "";
	let projectIdKeyVal = "";
	let storageBucketKeyVal = "";
	let messagingSenderIdKeyVal = "";
	let appIdKeyVal;
	let measurementIdKeyVal = "";
	let algoliaAppKeyVal = "";
	let algoliaKeyVal = "";

	switch (process.env.REACT_APP_ENV) {
		case "PROD":
			stripePublishableKeyVal =
				process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY!.toString();
			googleWebclientIdKeyVal =
				process.env.REACT_APP_GOOGLE_PROD_WEBCLIENT_ID!.toString();
			googleIosIdKeyVal = process.env.REACT_APP_GOOGLE_PROD_IOS_ID!.toString();
			googleAndroidIdKeyVal =
				process.env.REACT_APP_GOOGLE_PROD_ANDROID_ID!.toString();
			algoliaAppKeyVal = process.env.REACT_APP_ALGOLIA_PROD_APP!.toString();
			algoliaKeyVal = process.env.REACT_APP_ALGOLIA_PROD_KEY!.toString();
			apiKeyVal = process.env.REACT_APP_FIREBASE_PROD_apiKey!.toString();
			authDomainKeyVal =
				process.env.REACT_APP_FIREBASE_PROD_authDomain!.toString();
			databaseURLKeyVal =
				process.env.REACT_APP_FIREBASE_PROD_databaseURL!.toString();
			projectIdKeyVal =
				process.env.REACT_APP_FIREBASE_PROD_projectId!.toString();
			storageBucketKeyVal =
				process.env.REACT_APP_FIREBASE_PROD_storageBucket!.toString();
			messagingSenderIdKeyVal =
				process.env.REACT_APP_FIREBASE_PROD_messagingSenderId!.toString();
			appIdKeyVal = process.env.REACT_APP_FIREBASE_PROD_appId!.toString();
			measurementIdKeyVal =
				process.env.REACT_APP_FIREBASE_PROD_measurementId!.toString();
			break;
		case "STAGING":
			stripePublishableKeyVal =
				process.env.REACT_APP_STRIPE_STAGING_PUBLISHABLE_KEY!.toString();
			googleWebclientIdKeyVal =
				process.env.REACT_APP_GOOGLE_STAGING_WEBCLIENT_ID!.toString();
			googleIosIdKeyVal =
				process.env.REACT_APP_GOOGLE_STAGING_IOS_ID!.toString();
			googleAndroidIdKeyVal =
				process.env.REACT_APP_GOOGLE_STAGING_ANDROID_ID!.toString();
			algoliaAppKeyVal = process.env.REACT_APP_ALGOLIA_STAGING_APP!.toString();
			algoliaKeyVal = process.env.REACT_APP_ALGOLIA_STAGING_KEY!.toString();
			apiKeyVal = process.env.REACT_APP_FIREBASE_STAGING_apiKey!.toString();
			authDomainKeyVal =
				process.env.REACT_APP_FIREBASE_STAGING_authDomain!.toString();
			databaseURLKeyVal =
				process.env.REACT_APP_FIREBASE_STAGING_databaseURL!.toString();
			projectIdKeyVal =
				process.env.REACT_APP_FIREBASE_STAGING_projectId!.toString();
			storageBucketKeyVal =
				process.env.REACT_APP_FIREBASE_STAGING_storageBucket!.toString();
			messagingSenderIdKeyVal =
				process.env.REACT_APP_FIREBASE_STAGING_messagingSenderId!.toString();
			appIdKeyVal = process.env.REACT_APP_FIREBASE_STAGING_appId!.toString();
			measurementIdKeyVal =
				process.env.REACT_APP_FIREBASE_STAGING_measurementId!.toString();
			break;
		default:
			stripePublishableKeyVal =
				process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_KEY!.toString();
			googleWebclientIdKeyVal =
				process.env.REACT_APP_GOOGLE_DEV_WEBCLIENT_ID!.toString();
			googleIosIdKeyVal = process.env.REACT_APP_GOOGLE_DEV_IOS_ID!.toString();
			googleAndroidIdKeyVal =
				process.env.REACT_APP_GOOGLE_DEV_ANDROID_ID!.toString();
			algoliaAppKeyVal = process.env.REACT_APP_ALGOLIA_DEV_APP!.toString();
			algoliaKeyVal = process.env.REACT_APP_ALGOLIA_DEV_KEY!.toString();
			apiKeyVal = process.env.REACT_APP_FIREBASE_DEV_apiKey!.toString();
			authDomainKeyVal =
				process.env.REACT_APP_FIREBASE_DEV_authDomain!.toString();
			databaseURLKeyVal =
				process.env.REACT_APP_FIREBASE_DEV_databaseURL!.toString();
			projectIdKeyVal =
				process.env.REACT_APP_FIREBASE_DEV_projectId!.toString();
			storageBucketKeyVal =
				process.env.REACT_APP_FIREBASE_DEV_storageBucket!.toString();
			messagingSenderIdKeyVal =
				process.env.REACT_APP_FIREBASE_DEV_messagingSenderId!.toString();
			appIdKeyVal = process.env.REACT_APP_FIREBASE_DEV_appId!.toString();
			measurementIdKeyVal =
				process.env.REACT_APP_FIREBASE_DEV_measurementId!.toString();
	}

	return {
		stripePublishableKeyVal,
		googleWebclientIdKeyVal,
		googleIosIdKeyVal,
		googleAndroidIdKeyVal,
		algoliaAppKeyVal,
		algoliaKeyVal,
		apiKeyVal,
		authDomainKeyVal,
		databaseURLKeyVal,
		projectIdKeyVal,
		storageBucketKeyVal,
		messagingSenderIdKeyVal,
		appIdKeyVal,
		measurementIdKeyVal,
	};
}
