/**
 *
 * Intercom functions
 *
 * @format */

import firebase from "firebase";

declare const window: any;

export const intercomBoot = async () => {
	const email = firebase.auth().currentUser!.email;
	const user_id = firebase.auth().currentUser!.uid;
	window.Intercom("boot", {
		app_id: "na4056u8",
		email: email,
		user_id: user_id,
	});
};

export const trackEvent = (eventName: string, eventData?: any) => {
	window.Intercom("trackEvent", eventName, eventData);
};
function getUserEmail() {
	throw new Error("Function not implemented.");
}
