/** @format */

import { Badge, Box, Divider } from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { CompassCalibration } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import FeedbackIcon from "@mui/icons-material/Feedback";
import HelpIcon from "@mui/icons-material/Help";
import InputIcon from "@mui/icons-material/Input";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import PaymentIcon from "@mui/icons-material/Payment";
import PeopleIcon from "@mui/icons-material/People";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RoomIcon from "@mui/icons-material/Room";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import WorkIcon from "@mui/icons-material/Work";
import { signOut } from "scripts/firestoreQueries";

export const userListItems = (
	<Box>
		<ListItem button component="a" href="/">
			<ListItemIcon>
				<DynamicFeedIcon />
			</ListItemIcon>
			<ListItemText primary="Feed" />
		</ListItem>
		<ListItem button component="a" href="/search">
			<ListItemIcon>
				<SearchIcon />
			</ListItemIcon>
			<ListItemText primary="Search" />
		</ListItem>
		<ListItem button component="a" href="/map">
			<ListItemIcon>
				<RoomIcon />
			</ListItemIcon>
			<ListItemText primary="Map" />
		</ListItem>
		<ListItem button component="a" href="/basket">
			<ListItemIcon>
				<ShoppingCartIcon />
			</ListItemIcon>
			<ListItemText primary="Basket" />
		</ListItem>
		<ListItem button component="a" href="/order">
			<ListItemIcon>
				<ReceiptIcon />
			</ListItemIcon>
			<ListItemText primary="Orders" />
		</ListItem>
		<ListItem button component="a" href="/message">
			<ListItemIcon>
				<SendIcon />
			</ListItemIcon>
			<ListItemText primary="Messages" />
		</ListItem>
		<ListItem button component="a" href="/account">
			<ListItemIcon>
				<PeopleIcon />
			</ListItemIcon>
			<ListItemText primary="Account Information" />
		</ListItem>
		<ListItem button component="a" href="/payment-method">
			<ListItemIcon>
				<PaymentIcon />
			</ListItemIcon>
			<ListItemText primary="Payment" />
		</ListItem>
		<ListItem
			button
			component="a"
			href="https://dz96qzmjype.typeform.com/to/sLBY4sMn"
			target="_blank"
		>
			<ListItemIcon>
				<CompassCalibration />
			</ListItemIcon>
			<ListItemText primary="Request a new location" />
		</ListItem>
	</Box>
);

export const becomeASnaqerItems = (
	<Box>
		<ListItem button component="a" href="/apply">
			<ListItemIcon>
				<WorkIcon />
			</ListItemIcon>
			<ListItemText primary="Become a Chef" />
		</ListItem>
	</Box>
);

export const snaqerListItems = (
	<Box>
		<Divider />
		<ListSubheader inset>Snaqer Menu</ListSubheader>
		<ListItem button component="a" href="/producer/dashboard">
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="Dashboard" />
		</ListItem>
		<ListItem button component="a" href="/producer/order">
			<ListItemIcon>
				<ReceiptIcon />
			</ListItemIcon>
			<ListItemText primary="Customer orders" />
		</ListItem>
		<ListItem button component="a" href="/producer/product">
			<ListItemIcon>
				<FastfoodIcon />
			</ListItemIcon>
			<ListItemText primary="Products" />
		</ListItem>
		<ListItem button component="a" href="/producer/opening-hours">
			<ListItemIcon>
				<AccessTimeIcon />
			</ListItemIcon>
			<ListItemText primary="Opening Hours" />
		</ListItem>
		<ListItem button component="a" href="/producer/account">
			<ListItemIcon>
				<PeopleIcon />
			</ListItemIcon>
			<ListItemText primary="Business Information" />
		</ListItem>
		<ListItem button component="a" href="/producer/payment">
			<ListItemIcon>
				<PaymentIcon />
			</ListItemIcon>
			<ListItemText primary="Payout setting" />
		</ListItem>
		<ListItem button component="a" href="/producer/certificate">
			<ListItemIcon>
				<AssignmentTurnedInIcon />
			</ListItemIcon>
			<ListItemText primary="Certificate" />
		</ListItem>
		<ListItem
			button
			component="a"
			href="https://snaqing.app.link/e/marketing-hub"
			target="_blank"
		>
			<ListItemIcon>
				<SnippetFolderIcon />
			</ListItemIcon>
			<ListItemText primary="Marketing Hub" />
		</ListItem>
	</Box>
);

export const secondaryListItems = (
	<div>
		<ListSubheader inset>Support</ListSubheader>
		<ListItem
			button
			component="a"
			href="https://www.snaqing.com/faq"
			target="_blank"
		>
			<ListItemIcon>
				<HelpIcon />
			</ListItemIcon>
			<ListItemText primary="How Snaqing works" />
		</ListItem>
		<ListItem
			button
			component="a"
			href="mailto:support@snaqing.com"
			target="_blank"
		>
			<ListItemIcon>
				<QuestionAnswerIcon />
			</ListItemIcon>
			<ListItemText primary="Get Help" />
		</ListItem>
		<ListItem
			button
			component="a"
			href="mailto:product@snaqing.com"
			target="_blank"
		>
			<ListItemIcon>
				<FeedbackIcon />
			</ListItemIcon>
			<ListItemText primary="Give us feedback" />
		</ListItem>
		<ListSubheader inset>Legal & More</ListSubheader>
		<ListItem
			button
			component="a"
			href="https://www.snaqing.com/terms-of-service"
			target="_blank"
		>
			<ListItemIcon>
				<InputIcon />
			</ListItemIcon>
			<ListItemText primary="Terms" />
		</ListItem>
		<ListItem
			button
			component="a"
			href="https://www.snaqing.com/privacy-policy"
			target="_blank"
		>
			<ListItemIcon>
				<InputIcon />
			</ListItemIcon>
			<ListItemText primary="Privacy" />
		</ListItem>
		<ListItem
			button
			component="a"
			href="https://www.snaqing.com/privacy-policy"
			target="_blank"
		>
			<ListItemIcon>
				<InputIcon />
			</ListItemIcon>
			<ListItemText primary="Privacy" />
		</ListItem>
		<ListItem
			button
			component="a"
			onClick={() => {
				signOut();
			}}
		>
			<ListItemIcon>
				<InputIcon />
			</ListItemIcon>
			<ListItemText primary="Logout" />
		</ListItem>
	</div>
);
