/** @format */

import { Alert, AlertTitle, Box, Button, Grid } from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import Conditional from "components/Conditional";
import LinearProgress from "@mui/material/LinearProgress";
import { PRODUCER_ID_PREFIX } from "scripts/globalVariables";
import Paper from "@mui/material/Paper";
import { Row } from "./ProductRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import firebase from "firebase";
import { useEffect } from "react";
import { useProducerProducts } from "scripts/firestoreQueries";

export default function ProductTable() {
	const producerId = PRODUCER_ID_PREFIX + firebase.auth().currentUser?.uid;

	const [products, getProducerProducts, loading, emptyProducts] =
		useProducerProducts();

	const AddProductButton = () => (
		<>
			<Grid container>
				<Grid item>
					<Box py={2} pr={2}>
						<Button
							startIcon={<AddCircleIcon />}
							href="/producer/new-product"
							variant="contained"
						>
							Add a product
						</Button>
					</Box>
				</Grid>
				<Grid item>
					<Box py={2}>
						<Button
							startIcon={<CallSplitIcon />}
							href="/producer/product-modifier"
							variant="contained"
							color="secondary"
						>
							Modifiers
						</Button>
					</Box>
				</Grid>
			</Grid>
		</>
	);

	useEffect(() => {
		// @ts-ignore
		return getProducerProducts(false, producerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <LinearProgress />;
	} else {
		return emptyProducts ? (
			<>
				<AddProductButton />
				<Alert severity="info" elevation={3}>
					<AlertTitle>No Snaqs added</AlertTitle>
					Please add your first Snaqs.
				</Alert>
			</>
		) : (
			<Conditional if={products !== undefined}>
				<AddProductButton />
				<TableContainer component={Paper}>
					<Table aria-label="collapsible table" key="id">
						<TableHead id="header">
							<TableRow key="rowHeader">
								<TableCell key="rowHeaderSpace" />
								<TableCell key="rowHeaderImage">Image</TableCell>
								<TableCell key="rowHeaderStatus">Product status</TableCell>
								<TableCell key="rowHeaderProduct" align="left">
									Product
								</TableCell>
								<TableCell key="rowHeaderPortions" align="right">
									Portions
								</TableCell>
								<TableCell key="rowHeaderPortionType" align="right">
									Portion type
								</TableCell>
								<TableCell key="rowHeaderPrice" align="right">
									Price
								</TableCell>
								<TableCell key="rowHeaderModifier" align="right">
									Modifiers
								</TableCell>
								<TableCell key="rowHeaderEdit" align="right" size="small">
									Edit
								</TableCell>
								<TableCell key="rowHeaderDelete" align="right" size="small">
									Delete
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody key="body">
							{products === undefined ? (
								<Typography>...loading</Typography>
							) : (
								(products as Array<Object>).map((product) => (
									<Row key={(product as any).productId} row={product} />
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Conditional>
		);
	}
}
