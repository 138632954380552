/**
 * Snaqer account information
 *
 * @format
 */

import { Field, Formik } from "formik";
import {
	FormControlLabel,
	IconButton,
	LinearProgress,
	Snackbar,
	Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	changeProducerStatus,
	updateProducer,
	useProducer,
} from "../scripts/firestoreQueries";

import Box from "@mui/material/Box";
import Button from "../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import FormInput from "../components/formik/FormInput";
import Grid from "@mui/material/Grid";
import Layout from "../components/layout/Layout";
import { PRODUCER_ID_PREFIX } from "../scripts/globalVariables";
import ProducerProfileImageUpload from "components/imageUpload/ProducerProfileImageUpload";
import Title from "components/typography/Title";
import Typography from "@mui/material/Typography";
import firebase from "firebase";
import { producerAccountInformationSchema } from "../scripts/formValidation";
import { trackEvent } from "scripts/intercom";

const ProducerAccountInformation: React.FunctionComponent = (): JSX.Element => {
	const producerId = PRODUCER_ID_PREFIX + firebase.auth().currentUser?.uid;
	const [producerInformation, producerAddress, producerLoading, getProducer] =
		useProducer();
	const [accountStatus, setAccountStatus] = useState(false);

	const generateInitialValue = () => {
		let initialValues = {
			...producerInformation,
			...producerAddress,
		};
		if (typeof (initialValues as any).isDeliveryEnabled === "undefined") {
			(initialValues as any).isDeliveryEnabled = false;
		}
		if (typeof (initialValues as any).isPickupEnabled === "undefined") {
			(initialValues as any).isPickupEnabled = false;
		}
		return initialValues;
	};
	const [state, setState] = useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});

	const toggleChecked = () => {
		const newStatus = !accountStatus;
		setAccountStatus(newStatus);
		changeProducerStatus(producerId, newStatus);
	};

	useEffect(() => {
		return getProducer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!producerLoading) {
			setAccountStatus((producerInformation as any).isActive);
		}
	}, [producerLoading, producerInformation]);

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	const onSubmit = (data: any) => {
		updateProducer(data as any).then(() => {
			setState({
				open: true,
				vertical: "top",
				horizontal: "center",
			});
		});
	};

	useEffect(() => {
		return trackEvent("ProducerAccountInformationPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { vertical, horizontal, open } = state;

	return (
		<Layout title="Account Information">
			<Typography variant="h6" gutterBottom>
				Your Snaqer Information
			</Typography>
			{producerLoading && <LinearProgress />}
			{!producerLoading && (
				<>
					<Box py={2}>
						<FormControlLabel
							control={
								<Switch checked={accountStatus} onChange={toggleChecked} />
							}
							label="Account status"
							labelPlacement="end"
						/>
					</Box>
					<Formik
						initialValues={generateInitialValue()}
						validationSchema={producerAccountInformationSchema}
						onSubmit={onSubmit}
					>
						{({ handleSubmit, isValid, dirty }) => (
							<>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={12}>
										<ProducerProfileImageUpload />
									</Grid>
									<Grid item xs={12} sm={12}>
										<Title>Account Information</Title>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="firstName"
											placeholder="First name"
											autoComplete="first name"
											label="First name"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="lastName"
											placeholder="Last name"
											autoComplete="last name"
											label="Last name"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="displayName"
											placeholder="Display name"
											autoComplete="display name"
											label="display name"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="email"
											placeholder="Email"
											autoComplete="email"
											label="Email"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="phone"
											placeholder="Phone number"
											autoComplete="phone number"
											label="phone number"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="bio"
											placeholder="Biography"
											autoComplete="bio"
											label="Biography"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="street"
											placeholder="Street"
											autoComplete="Street"
											label="Street"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="houseNumber"
											placeholder="House number"
											label="House number"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="city"
											placeholder="City"
											label="City"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="state"
											placeholder="State/Province/Region"
											label="State/Province/Region"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="postalCode"
											placeholder="Zip / Postal code"
											label="Zip / Postal code"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="country"
											placeholder="Country"
											label="Country"
										/>
									</Grid>
									{/* <Grid item xs={12} sm={12}>
										<Title>Delivery Options</Title>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={CheckboxInput}
											name="isDeliveryEnabled"
											placeholder="Delivery"
											label="Delivery"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={CheckboxInput}
											name="isPickupEnabled"
											placeholder="Pick up"
											label="Pick up"
										/>
									</Grid> */}
								</Grid>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<Button
										variant="contained"
										sx={{ mt: 3, ml: 1 }}
										onClick={() => handleSubmit()}
										disabled={!(isValid && dirty)}
									>
										Save
									</Button>
								</Box>
							</>
						)}
					</Formik>
				</>
			)}
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				message="Successfully saved"
				key={vertical + horizontal}
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						sx={{ p: 0.5 }}
						onClick={handleClose}
						size="large"
					>
						<CloseIcon />
					</IconButton>
				}
			/>
		</Layout>
	);
};
export default ProducerAccountInformation;
