/**
 * Profile Page of a producer which will be seen by a user
 *
 * @format
 */

import ProducerDetail from "../pages/ProducerDetailPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const ProducerDetailPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Snaqer Profile Page - Snaqing | Homemade food");

	return <ProducerDetail />;
};

export default ProducerDetailPage;
