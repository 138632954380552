/**
 * Root of the application
 *
 * @format
 */

import React from "react";
import UpdateProduct from "../pages/product/UpdateProductPage";
import useTitle from "../hooks/useTitle";

const UpdateProductPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Update Product - Snaqing | Homemade food");

	return <UpdateProduct />;
};

export default UpdateProductPage;
