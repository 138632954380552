/**
 *
 * Payment Method Selector to select a payment method before ordering.
 *
 * @format
 */

import { Button, LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { Field } from "formik";
import FormDropdown from "components/formik/FormDropdown";
import { usePaymentMethod } from "scripts/order/orderQueries";

const PaymentMethodSelector: React.FunctionComponent = (): JSX.Element => {
	const [
		loadingPaymentMethods,
		noPaymentMethod,
		getPaymentMethod,
		paymentMethods,
	] = usePaymentMethod();

	useEffect(() => {
		return getPaymentMethod();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{loadingPaymentMethods ? (
				<LinearProgress />
			) : noPaymentMethod ? (
				<>
					<Typography>
						No Payment Method Available. Please add a payment method to your
						account.
					</Typography>
					<Button variant="contained" href="/payment-method">
						Add Payment Method
					</Button>
				</>
			) : (
				<>
					<Field
						component={FormDropdown}
						name="paymentMethod"
						placeholder="Payment Method"
						autoComplete="Payment Method"
						// label="Pick Up Slots"
						list={paymentMethods}
					/>
				</>
			)}
		</>
	);
};

export default PaymentMethodSelector;
