/**
 *
 * Queries to fetch data for feed and feedCard
 *
 * @format */

const geofire = require("geofire-common");

import {
	ADDRESSES_COLLECTION,
	FOOD_PRODUCTS,
	OPENING_HOURS_COLLECTION,
	PRODUCER_COLLECTION,
} from "./globalVariables";

import firebase from "firebase";
import { getLocation } from "./globalFunctions";
import { useState } from "react";

export const useFeedInformation = (): [
	boolean,
	boolean,
	() => void,
	object,
] => {
	const [loading, setLoading] = useState(true);
	const [isEmpty, setIsEmpty] = useState(true);
	const [feedInformation, setFeedInformation] = useState([]);

	const getFeedInformation = (data: any) => {
		const producerIds: Array<any> = [];
		let producerDistance = {};
		Object.values(data).map((producerObject: any) => {
			const producerId = producerObject.address.data().producerId;
			const distanceInM = producerObject.distanceInM;
			producerIds.push(producerId);
			(producerDistance as any)[producerId] = distanceInM;
		});
		const producerRef = firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.where("isApproved", "==", true)
			.where("isActive", "==", true)
			.where("stripeIsActive", "==", true)
			.where("certificateSubmitted", "==", true);
		// .where("producerId", "in", producerIds);

		producerRef.get().then((producerDocs) => {
			if (!producerDocs.empty) {
				setIsEmpty(false);
				let feedData: Array<Object> = [];
				producerDocs.forEach(async (producerData) => {
					let producerDataSet: any = producerData.data();
					const addressRef = producerData.ref.collection(ADDRESSES_COLLECTION);
					const producerDataRef = producerData.ref;
					const distanceInM = (producerDistance as any)[
						(producerDataSet as any).producerId
					];
					const foodProductData = producerDataRef
						.collection(FOOD_PRODUCTS)
						.where("isActive", "==", true);
					await addressRef.get().then((addressDocs) => {
						addressDocs.forEach((address) => {
							const addressDataSet = address.data();
							producerDataSet = {
								...producerDataSet,
								address: addressDataSet,
								distanceInM: distanceInM,
							};
						});
					});
					await producerDataRef
						.collection(OPENING_HOURS_COLLECTION)
						.get()
						.then((openingHourDocs) => {
							if (!openingHourDocs.empty) {
								let openingHourDataSet = {};
								openingHourDocs.forEach((openingHourDoc) => {
									openingHourDataSet = {
										...openingHourDataSet,
										[openingHourDoc.id]: openingHourDoc.data(),
									};
									producerDataSet = {
										...producerDataSet,
										openingHours: openingHourDataSet,
									};
								});
							} else {
								return null;
							}
						});
					await foodProductData.get().then((foodProducts) => {
						foodProducts.forEach((foodProduct) => {
							let dataSet = foodProduct.data();
							dataSet = { ...dataSet, producer: producerDataSet };
							feedData.push(dataSet);
						});
					});
					if (feedData.length > 0) {
						let sortedFeedData = feedData.sort((a: any, b: any) => {
							return a.producer.distanceInM - b.producer.distanceInM;
						});
						setFeedInformation((sortedFeedData as any).concat([]));
						setIsEmpty(false);
						setLoading(false);
					} else {
						setIsEmpty(true);
						setLoading(false);
					}
				});
			} else {
				setIsEmpty(true);
				setLoading(false);
			}
		});
	};
	//@ts-ignore
	return [loading, isEmpty, getFeedInformation, feedInformation];
};
export const useNearByProducer = (): [boolean, () => void, object] => {
	const [loading, setLoading] = useState(true);
	const [nearByProducerInformation, setNearByProducerInformation] = useState(
		[],
	);

	const getNearByProducers = async () => {
		const location: Array<any> = getLocation();
		const center = location;
		const radiusInM = 100000 * 1000;

		// const bounds = geofire.geohashQueryBounds(center, radiusInM);
		const promises = [];
		// for (const b of bounds) { // we need this comment for the nearby function if enabled below
		const q = firebase
			.firestore()
			.collectionGroup(ADDRESSES_COLLECTION)
			.orderBy("geohash");
		// Enable this if you want to get only results around the user.
		// .startAt(b[0])
		// .endAt(b[1]);
		promises.push(await q.get());
		// }
		// Collect all the query results together into a single list
		Promise.all(promises)
			.then((snapshots) => {
				const matchingDocs: Array<any> = [];
				snapshots.forEach((snap) => {
					snap.forEach((doc) => {
						const addressData = doc.data();
						const lat = addressData.geolocation._lat;
						const lng = addressData.geolocation._long;
						const distanceInKm = geofire.distanceBetween([lat, lng], center);
						const distanceInM = distanceInKm * 1000;
						if (distanceInM <= radiusInM) {
							matchingDocs.push({
								address: doc,
								distanceInM: distanceInM.toFixed(),
							});
						}
					});
				});
				return matchingDocs;
			})
			.then((matchingDocs: Array<any>) => {
				if (matchingDocs.length === 0) {
					setLoading(false);
				}
				//@ts-ignore
				setNearByProducerInformation(matchingDocs);
				setLoading(false);
			});
	};
	return [loading, getNearByProducers, nearByProducerInformation];
};
