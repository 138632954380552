/**
 *
 * AccountInformation of the producer signed
 *
 * @format
 */

import ProducerAccountInformation from "../pages/ProducerAccountInformationPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const ProducerAccountInformationPage: React.FunctionComponent =
	(): JSX.Element => {
		useTitle("Producer Account Information - Snaqing | Homemade food");

		return <ProducerAccountInformation />;
	};
export default ProducerAccountInformationPage;
