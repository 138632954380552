/**
 *
 * Input component for formik forms
 * This component automatically hooks some props to make reading the formik form easier
 *
 * @format */

import TextField from "@mui/material/TextField";

const FormInput = (props: any): JSX.Element => {
	const {
		field: { name, onBlur, onChange, value, placeholder, autoComplete },
		// @ts-ignore
		form: { errors, touched, setFieldTouched },
		...inputProps
	} = props;

	return (
		<>
			<TextField
				fullWidth
				autoComplete={autoComplete}
				variant="outlined"
				value={value}
				name={name}
				placeholder={placeholder}
				margin="dense"
				onChange={(text) => onChange(name)(text)}
				onBlur={() => {
					setFieldTouched(name);
					onBlur(name);
				}}
				helperText={errors[name]}
				error={errors[name] ? true : false}
				{...inputProps}
			/>
		</>
	);
};

export default FormInput;
