/** @format */

import { Alert, AlertTitle, Box, Button, Grid } from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Conditional from "components/Conditional";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { Row } from "./ProductModifierRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useModifiers } from "scripts/firestoreQueries";

export default function ProductTable() {
	const [loading, noModifiers, getModifiers, modifiers] = useModifiers();

	useEffect(() => {
		return getModifiers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const AddProductButton = () => (
		<>
			<Grid container>
				<Grid item>
					<Box py={2} pr={2}>
						<Button
							startIcon={<ArrowBackIosIcon />}
							href="/producer/product"
							variant="contained"
							color="secondary"
						>
							Back
						</Button>
					</Box>
				</Grid>
				<Grid item>
					<Box py={2}>
						<Button
							startIcon={<AddCircleIcon />}
							href="/producer/new-product-modifier"
							variant="contained"
						>
							Add a modifier
						</Button>
					</Box>
				</Grid>
			</Grid>
		</>
	);

	if (loading) {
		return <LinearProgress />;
	} else {
		return noModifiers ? (
			<>
				<AddProductButton />
				<Alert severity="info" elevation={3}>
					<AlertTitle>No modifiers added</AlertTitle>
					Please add your first modifier.
				</Alert>
			</>
		) : (
			<>
				<Conditional if={modifiers !== undefined}>
					<AddProductButton />
					<TableContainer component={Paper}>
						<Table aria-label="collapsible table">
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell>Name</TableCell>
									<TableCell align="left">at Least</TableCell>
									<TableCell align="right">at Most</TableCell>
									<TableCell align="right">in use</TableCell>
									<TableCell align="right" size="small">
										Edit
									</TableCell>
									<TableCell align="right" size="small">
										Delete
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{modifiers === undefined ? (
									<Typography>...loading</Typography>
								) : (
									(modifiers as Array<Object>).map((modifier) => (
										<Row key={(modifier as any).productId} row={modifier} />
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Conditional>
			</>
		);
	}
}
