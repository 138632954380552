/**
 * Theme definition for Material UI
 *
 * This is added to all documents created to give a consistent styling across
 * the app.
 *
 * @format
 */
import { Theme, createTheme } from "@mui/material/styles";
const defaultTheme = createTheme();

const color = {
	primary: {
		light: "#A0B5B7",
		main: "#3B6E6E",
		dark: "#1E4747",
		contrastText: "#fff",
	},
	secondary: {
		light: "#E4E5E6",
		main: "#C9CBCD",
		dark: "#94999C",
		contrastText: "#fff",
	},
	textDefaultColor: {
		light: "#333",
		main: "#000",
	},
};

const theme: Theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	typography: {
		fontFamily: "Montserrat, Helvetica Neue, sans-serif",
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontSize: 14,
		// Title in the header
		h1: {
			fontSize: "45px",
			color: color.textDefaultColor.main,
			fontWeight: 400,
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: "36px",
			},
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: "21px",
				textShadow: "1px 1px 1px #fff",
			},
		},
		// Sub-title in the header
		subtitle1: {
			fontWeight: 200,
			fontSize: "43px",
			color: color.textDefaultColor.main,
			[defaultTheme.breakpoints.down("md")]: {
				fontSize: "18px",
				textShadow: "1px 1px 5px #fff",
			},
		},
		// Headline on pages
		h2: {
			fontWeight: 400,
			fontSize: "31px",
			paddingBottom: "20px",
			color: color.textDefaultColor.main,
		},
		h3: {
			fontWeight: 330,
			fontSize: "21px",
			color: color.textDefaultColor.main,
		},
		// Sub-Headline on pages (bold)
		h4: {
			fontWeight: 600,
			fontSize: "21px",
			color: color.textDefaultColor.main,
		},
		// Footer header
		h5: {
			fontWeight: 600,
			fontSize: "18px",
			textTransform: "uppercase",
			color: color.secondary.dark,
		},
		body1: {
			// lineHeight: 1.5,
			color: "black",
			fontSize: "14px",
		},
		// Used for headlines with same fontSize as regular text
		body2: {
			fontSize: "1.1rem",
			lineHeight: 1.3,
			fontWeight: 450,
		},
		caption: {
			fontSize: "0.8rem",
			color: "#999",
			"&a": {
				color: "red",
			},
		},
	},
	palette: {
		primary: {
			light: color.primary.light,
			main: color.primary.main,
			dark: color.primary.dark,
			contrastText: color.primary.contrastText,
		},
		secondary: {
			light: color.secondary.light,
			main: color.secondary.main,
			dark: color.secondary.dark,
			contrastText: color.secondary.contrastText,
		},
		success: {
			main: "#4caf50",
		},
		text: {
			primary: color.textDefaultColor.main,
		},
		background: {
			default: "#fff",
		},
	},
});

export default theme;
