/**
 * The component to upload a product Images
 *
 * @format
 */

import {
	CardMedia,
	CircularProgress,
	Grid,
	Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Box } from "@mui/system";
import Button from "components/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { PRODUCER_ID_PREFIX } from "../../scripts/globalVariables";
import { PRODUCT_IMAGE_COLLECTION } from "scripts/globalVariables";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import PublishIcon from "@mui/icons-material/Publish";
import { changeProductImagePath } from "scripts/firestoreQueries";
import firebase from "firebase";

let noImageRef = "intern/images/no-product-image.png";

type Props = {
	productId: string;
	func?: Function;
};

const ProductImageUpload: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const ref = useRef(null);
	const userId = firebase.auth().currentUser?.uid;
	const producerId = PRODUCER_ID_PREFIX + userId;
	const [noItemImage, setNoItemImage] = useState();
	const [imageSelected, setImageSelected] = useState(false);
	const [loading, setLoading] = useState(true);
	const [state, setState] = useState<any>({
		image: null,
		progress: null,
		downloadURL: null,
	});
	const [preview, setPreview] = useState("");

	const imageName = props.productId;
	const imageRef =
		PRODUCT_IMAGE_COLLECTION + "/producer" + userId + "/" + imageName;

	useEffect(() => {
		(async () => {
			await firebase
				.storage()
				.ref()
				.child(imageRef)
				.getDownloadURL()
				.then((url) => {
					setState({
						downloadURL: url,
					});
					if (props.func) {
						props.func(1);
					}
					setLoading(false);
				})
				.catch((error) => {
					if (error.code === "storage/object-not-found") {
						(async () => {
							setNoItemImage(
								(await firebase
									.storage()
									.ref(noImageRef)
									.getDownloadURL()) as any,
							);
							setLoading(false);
						})();
					}
					console.warn("Warning", error);
				});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e: any) => {
		if (e.target.files[0]) {
			setState({
				image: e.target.files[0],
			});
			setImageSelected(true);
			setPreview(URL.createObjectURL(e.target.files[0]));
			setLoading(false);
		}
	};

	const handleFileSelection = () => {
		if (ref) {
			(ref as any).current.click();
		}
	};

	const handleUpload = () => {
		let file = (state as any).image;
		var storage = firebase.storage();
		var storageRef = storage.ref();
		var uploadTask = storageRef.child(imageRef).put(file);

		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				var progress =
					Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setState({ progress });
			},
			(error) => {
				throw error;
			},
			() => {
				uploadTask.snapshot.ref.getDownloadURL().then((url) => {
					setState({
						downloadURL: url,
					});
					setImageSelected(false);
					changeProductImagePath(producerId, props.productId, url);
				});
			},
		);
	};

	return loading ? (
		<LinearProgress />
	) : (
		<Box py={2}>
			<Typography>Please select an image and then upload it.</Typography>
			<Box
				py={2}
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Grid container>
					<Grid item xs={12} md={12} lg={12}>
						{imageSelected && (
							<Typography>Now upload your new image</Typography>
						)}
						<CardMedia
							component="img"
							src={preview || state.downloadURL || noItemImage}
							style={{
								maxHeight: "400px",
								maxWidth: "600px",
								objectFit: "cover",
								borderRadius: 15,
							}}
						/>
					</Grid>

					<Grid item xs={12} md={4} lg={4}>
						<Box py={2}>
							1.{" "}
							<Button
								onClick={() => handleFileSelection()}
								variant="contained"
								startIcon={<PhotoSizeSelectActualIcon />}
							>
								Choose File
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<Box py={2}>
							2.{" "}
							<Button
								onClick={handleUpload}
								variant="contained"
								startIcon={<PublishIcon />}
								disabled={!imageSelected}
							>
								Upload File
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<Box sx={{ width: "100%" }}>
				<Box position="relative" display="inline-flex">
					<CircularProgress variant="determinate" value={state.progress} />
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Typography variant="caption" component="div" color="textSecondary">
							{state.progress}
						</Typography>
					</Box>
				</Box>
			</Box>
			<input
				type="file"
				accept=".png, .jpg, .jpeg, pdf"
				hidden
				ref={ref}
				onChange={handleChange}
			/>
		</Box>
	);
};

export default ProductImageUpload;
