/** @format */

import { IconButton, TableCell, TableRow, Typography } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { deleteOpenHours } from "scripts/firestoreOpeningHours";
import { formatAMPM } from "scripts/globalFunctions";

export const OpeningHoursRow = (data: any, _time: Array<any>) => {
	const date = data.date;
	const timeFrom = data.time[0];
	const timeTo = data.time[1];
	const formattedTimeFrom = formatAMPM(data.time[0]);
	const formattedTimeTo = formatAMPM(data.time[1]);

	return (
		<>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell component="th" scope="row" align="left">
					<Typography>{date}</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography>{formattedTimeFrom}</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography>{formattedTimeTo}</Typography>
				</TableCell>
				<TableCell align="right" size="small">
					<IconButton
						aria-label="delete"
						size="small"
						onClick={() => {
							deleteOpenHours(date, timeFrom, timeTo);
						}}
					>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
		</>
	);
};
