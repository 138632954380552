/**
 *
 * Root of the application
 *
 * @format
 */

import Conversation from "../pages/message/ConversationPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const ConversationPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Conversation - Snaqing | Homemade food");

	return <Conversation />;
};

export default ConversationPage;
