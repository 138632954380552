/** @format */

import {
	Box,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { PRODUCER_ID_PREFIX } from "scripts/globalVariables";
import { deleteModifier } from "scripts/firestoreQueries";
import firebase from "firebase";
import { useModifierInUseStatus } from "../../scripts/firestoreQueries";

export const Row = (props: any) => {
	const { row } = props;
	const userId = firebase.auth().currentUser?.uid;
	const producerId = PRODUCER_ID_PREFIX + userId;
	const modifierId = row.modifierId;
	const [open, setOpen] = useState(false);
	const [_loading, status, getModifierInUseStatus] = useModifierInUseStatus(
		row.modifierId,
	);

	useEffect(() => {
		getModifierInUseStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>{row.modifierName}</TableCell>

				<TableCell component="th" scope="row" align="left">
					<Typography>{row.atLeast}</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography>{row.atMost}</Typography>
				</TableCell>
				<TableCell align="right">
					{status ? <CheckCircleIcon /> : <CancelIcon />}
				</TableCell>
				<TableCell align="right" size="small">
					<IconButton
						aria-label="edit"
						size="small"
						href={`update-product-modifier/${row.modifierId}`}
					>
						<EditIcon />
					</IconButton>
				</TableCell>
				<TableCell align="right" size="small">
					<IconButton
						aria-label="delete"
						size="small"
						onClick={() => {
							if (status) {
								alert(
									"This modifier is in use. Please remove it from all products before deleting it.",
								);
							} else {
								deleteModifier(producerId, modifierId);
							}
						}}
					>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell colSpan={4}>
											<Typography color="primary">Option name</Typography>
										</TableCell>
										<TableCell colSpan={3}>
											<Typography color="primary">Price</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.modifierOptions &&
										Object.entries(row?.modifierOptions).map((data: any) => {
											for (const [_index, _option] of Object.entries(data)) {
												for (const item of Object.values([data])) {
													return (
														<>
															<TableRow>
																<TableCell colSpan={4}>
																	<Typography>{item[0]}</Typography>
																</TableCell>
																<TableCell colSpan={3}>
																	<Typography>$ {item[1]}</Typography>
																</TableCell>
															</TableRow>
														</>
													);
												}
											}
										})}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};
