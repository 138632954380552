/**
 * Add Product Information
 *
 * @format
 */

import { Field, Formik } from "formik";
import { Grid, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import {
	addProductModifierOption,
	useModifierOptions,
} from "scripts/firestoreQueries";

import { Box } from "@mui/system";
import Button from "components/Button";
import Conditional from "components/Conditional";
import FormInput from "components/formik/FormInput";
import ProductModifierOptionTable from "./ProductModifierOptionTable";
import Title from "components/typography/Title";
import { foodProductModifierOptionSchema } from "scripts/formValidation";

type Props = {
	func?: Function;
	modifierId: string;
};

const AddProductModifierOptions: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const modifierId = props.modifierId;
	const onSubmit = async (data: any) => {
		await addProductModifierOption(modifierId, data as any);
	};
	const [loading, modifiers, getOptions] = useModifierOptions(modifierId);

	const initialValues = {};

	useEffect(() => {
		return getOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{loading ? (
				<LinearProgress />
			) : (
				<Conditional if="modifiers !== undefined">
					<ProductModifierOptionTable
						modifier={modifiers}
						modifierId={modifierId}
					/>
				</Conditional>
			)}
			<Box py={2}>
				<Title>Add option</Title>
			</Box>
			<Formik
				validationSchema={foodProductModifierOptionSchema}
				onSubmit={onSubmit}
				initialValues={initialValues}
			>
				{({ handleSubmit, isValid, dirty }) => (
					<>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<Field
									component={FormInput}
									name="optionName"
									autoComplete="option name"
									label="Option name"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									component={FormInput}
									name="price"
									autoComplete="price"
									label="+ $"
								/>
							</Grid>
						</Grid>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								variant="contained"
								sx={{ mt: 3, ml: 1 }}
								onClick={() => handleSubmit()}
								disabled={!(isValid && dirty)}
							>
								Add Option
							</Button>
						</Box>
					</>
				)}
			</Formik>
		</>
	);
};

export default AddProductModifierOptions;
