/**
 * User account information
 *
 * @format
 */

import { Field, Formik } from "formik";
import {
	FormControlLabel,
	IconButton,
	LinearProgress,
	Snackbar,
	Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	changeProducerStatus,
	updateProducer,
	updateUser,
	useProducer,
	useUser,
} from "../scripts/firestoreQueries";

import Box from "@mui/material/Box";
import Button from "../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import FormInput from "../components/formik/FormInput";
import Grid from "@mui/material/Grid";
import Layout from "../components/layout/Layout";
import { PRODUCER_ID_PREFIX } from "../scripts/globalVariables";
import ProfileImageUpload from "components/imageUpload/ProfileImageUpload";
import Title from "components/typography/Title";
import Typography from "@mui/material/Typography";
import { accountInformationSchema } from "../scripts/formValidation";
import firebase from "firebase";
import { trackEvent } from "scripts/intercom";

const AccountInformation: React.FunctionComponent = (): JSX.Element => {
	const [userInformation, userLoading, getUser] = useUser();

	const [state, setState] = useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});

	useEffect(() => {
		return trackEvent("AccountInformationPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	const onSubmit = (data: any) => {
		updateUser(data as any).then(() => {
			setState({
				open: true,
				vertical: "top",
				horizontal: "center",
			});
		});
	};

	const { vertical, horizontal, open } = state;

	return (
		<Layout title="Account Information- Snaqing | Homemade food">
			<Typography variant="h6" gutterBottom>
				Your Account Information
			</Typography>
			{userLoading ? (
				<LinearProgress />
			) : (
				<>
					<Formik
						initialValues={
							typeof userInformation === "undefined" ? {} : userInformation
						}
						validationSchema={accountInformationSchema}
						onSubmit={onSubmit}
					>
						{({ handleSubmit, isValid, dirty }) => (
							<>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={12}>
										<ProfileImageUpload />
									</Grid>
									<Grid item xs={12} sm={12}>
										<Title>Account Information</Title>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="firstName"
											placeholder="First name"
											autoComplete="first name"
											label="First name"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="lastName"
											placeholder="Last name"
											autoComplete="last name"
											label="Last name"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="email"
											placeholder="Email"
											autoComplete="email"
											label="Email"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="phone"
											placeholder="Phone number"
											autoComplete="phone number"
											label="phone number"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="street"
											placeholder="Street"
											autoComplete="Street"
											label="Street"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="houseNumber"
											placeholder="House number"
											label="House number"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="city"
											placeholder="City"
											label="City"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="state"
											placeholder="State/Province/Region"
											label="State/Province/Region"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="postalCode"
											placeholder="Zip / Postal code"
											label="Zip / Postal code"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											component={FormInput}
											name="country"
											placeholder="Country"
											label="Country"
										/>
									</Grid>
								</Grid>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<Button
										variant="contained"
										sx={{ mt: 3, ml: 1 }}
										onClick={() => handleSubmit()}
										disabled={!(isValid && dirty)}
									>
										Save
									</Button>
								</Box>
							</>
						)}
					</Formik>
				</>
			)}
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				message="Successfully saved"
				key={vertical + horizontal}
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						sx={{ p: 0.5 }}
						onClick={handleClose}
						size="large"
					>
						<CloseIcon />
					</IconButton>
				}
			/>
		</Layout>
	);
};
export default AccountInformation;
