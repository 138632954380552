/**
 *
 * MUI custom style components
 *
 * @format */

import { borders, compose, spacing } from "@mui/system";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export default styled(TextField)(compose(spacing, borders));
