/**
 *
 * Index page once a user logged in
 *
 * @format */

import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import {
	convertTime,
	validateOpeningHours,
} from "scripts/firestoreOpeningHours";
import { useEffect, useState } from "react";

import Conditional from "components/Conditional";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Layout from "../../components/layout/Layout";
import ListOpeningHours from "components/openingHours/ListOpeningHours";
import TimePicker from "@mui/lab/TimePicker";
import Title from "components/typography/Title";
import { trackEvent } from "scripts/intercom";

const IndexPage: React.FunctionComponent = (): JSX.Element => {
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);
	const [date, setDate] = useState<any | null>(null);
	const [timeFrom, setTimeFrom] = useState<any | null>(null);
	const [timeTo, setTimeTo] = useState<any | null>(null);
	const [warningHours, setWarningHours] = useState<any | null>(null);
	const [isValid, setIsValid] = useState<any | null>(false);
	const [addNewHoursMessage, setAddNewHoursMessage] = useState<any | null>(
		null,
	);

	const handleDateChange = (newValue: Date | null) => {
		setDate(newValue);
	};
	const handleTimeFromChange = (newValue: Date | null) => {
		setTimeFrom(newValue);
	};

	const handleTimeToChange = (newValue: Date | null) => {
		setTimeTo(newValue);
	};

	useEffect(() => {
		return trackEvent("OpeningHoursPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const check = () => {
			if (timeFrom > timeTo) {
				setWarningHours(true);
			} else if (timeFrom < timeTo) {
				setWarningHours(false);
			}
		};
		return check();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeFrom, timeTo, date]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (date != null && warningHours === false) {
			setIsValid(true);
		}
	});

	const onSubmit = async (_date: Date, timeFrom: Date, timeTo: Date) => {
		const convertedTimeFrom = convertTime(timeFrom);
		const convertedTimeTo = convertTime(timeTo);
		const newIntervals = [convertedTimeFrom, convertedTimeTo];
		const addInterval = await validateOpeningHours(date, newIntervals);
		setAddNewHoursMessage(addInterval);
	};

	return (
		<Layout title="Your Opening Hours">
			{warningHours ? (
				<Box py={2}>
					<Alert severity="warning">
						<AlertTitle>Wrong Times</AlertTitle>
						You set the wrong opening hours. The closing hour has to be after
						opening hour.
					</Alert>
				</Box>
			) : null}
			{addNewHoursMessage ? (
				<Box py={2}>
					<Conditional if={addNewHoursMessage === true}>
						<Alert
							severity="success"
							onClose={() => setAddNewHoursMessage(null)}
						>
							<AlertTitle>Successfully saved!</AlertTitle>
							{addNewHoursMessage}
						</Alert>
					</Conditional>
					<Conditional if={addNewHoursMessage !== true}>
						<Alert severity="error" onClose={() => setAddNewHoursMessage(null)}>
							<AlertTitle>Error</AlertTitle>
							{addNewHoursMessage}
						</Alert>
					</Conditional>
				</Box>
			) : null}
			<Box py={2}>
				<>
					<Paper elevation={3}>
						<Box p={3}>
							<Title>Add date and time</Title>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<DesktopDatePicker
										label="Date"
										inputFormat="MM/dd/yyyy"
										minDate={today}
										value={date}
										onChange={(newValue) => handleDateChange(newValue)}
										//@ts-ignore
										renderInput={(params) => <TextField {...params} />}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TimePicker
										label="Open From"
										value={timeFrom}
										views={["hours", "minutes"]}
										onChange={(newValue) => handleTimeFromChange(newValue)}
										minutesStep={30}
										//@ts-ignore
										renderInput={(params) => <TextField {...params} />}
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TimePicker
										label="Open Until"
										value={timeTo}
										views={["hours", "minutes"]}
										onChange={(newValue) => handleTimeToChange(newValue)}
										minutesStep={30}
										//@ts-ignore
										renderInput={(params) => <TextField {...params} />}
									/>
								</Grid>
							</Grid>
						</Box>
						<Box p={2}>
							<Typography>Selected:</Typography>
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="center"
							>
								<Grid item xs={12}></Grid>
								<Grid item xs={6}>
									<Typography
										component="h3"
										variant="h3"
										color="primary"
										gutterBottom
									>
										Date:{" "}
										<strong>
											{date
												? date.getMonth() +
												  1 +
												  "-" +
												  date.getDate() +
												  "-" +
												  date.getFullYear()
												: "__-__-__"}
										</strong>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography
										component="h3"
										variant="h3"
										color="primary"
										gutterBottom
									>
										From{" "}
										<strong>
											{timeFrom
												? timeFrom.getHours().toString() +
												  ":" +
												  (timeFrom.getMinutes() < 10
														? "00"
														: timeFrom.getMinutes())
												: "__:__"}
										</strong>{" "}
										to{" "}
										<strong>
											{timeTo
												? timeTo.getHours().toString() +
												  ":" +
												  (timeTo.getMinutes() < 10
														? "00"
														: timeTo.getMinutes())
												: "__:__"}
										</strong>
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Paper>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							variant="contained"
							sx={{ mt: 3, ml: 1 }}
							disabled={!isValid}
							onClick={() => onSubmit(date, timeFrom, timeTo)}
						>
							Add
						</Button>
					</Box>
				</>
			</Box>
			<ListOpeningHours />
		</Layout>
	);
};

export default IndexPage;
