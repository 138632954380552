/**
 * This component lists all opening hours (CRUD)
 *
 * @format
 */

import OpeningHoursTable from "./OpeningHoursTable";
import React from "react";
import Title from "components/typography/Title";

const ListOpeningHours: React.FunctionComponent = (): JSX.Element => {
	return (
		<>
			<Title>Your Opening Hours</Title>
			<OpeningHoursTable />
		</>
	);
};

export default ListOpeningHours;
