/**
 * Product form input fields
 *
 * @format
 */

import { FormGroup, Grid } from "@mui/material";
import {
	courseType,
	foodCategory,
	foodCuisines,
	portionType,
} from "./FoodVariables";

import CheckboxInput from "components/formik/CheckboxInput";
import { Field } from "formik";
import FormDropdown from "components/formik/FormDropdown";
import FormInput from "components/formik/FormInput";
import React from "react";
import Title from "components/typography/Title";

const ProductForms: React.FunctionComponent = (): JSX.Element => {
	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormInput}
						name="productName"
						// placeholder="Product name"
						autoComplete="product name"
						label="Product name"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormInput}
						name="description"
						placeholder="Description"
						autoComplete="description"
						label="Description"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormInput}
						name="ingredients"
						placeholder="Ingredients"
						autoComplete="ingredients"
						label="Ingredients"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormDropdown}
						name="course"
						placeholder="Course"
						autoComplete="Course"
						label="Course"
						list={courseType}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormInput}
						name="portionSize"
						placeholder="Portion size"
						autoComplete="portionSize"
						label="Portion size"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormDropdown}
						name="portionType"
						placeholder="Portion"
						autoComplete="Portion"
						label="Portion"
						list={portionType}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormDropdown}
						name="cuisine"
						placeholder="Cuisine"
						autoComplete="cuisine"
						label="Cuisine"
						list={foodCuisines}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormDropdown}
						name="foodCategory"
						placeholder="Food Category"
						autoComplete="food category"
						label="Food Category"
						list={foodCategory}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormInput}
						name="price"
						placeholder="Price"
						autoComplete="price"
						label="Price"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						component={FormInput}
						name="preparationTime"
						autoComplete="Preparation Time (minutes)"
						label="Preparation Time (minutes)"
						placeholder="Preparation Time in minutes"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Field
						component={FormInput}
						name="specialInstructions"
						autoComplete="Special Instructions"
						label="Special Instructions"
						placeholder="Special Instructions"
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Title>Dietary</Title>
					<FormGroup>
						<Grid item xs={12} md={12} sm={12}>
							<Grid
								container
								spacing={5}
								direction="row"
								justifyContent="space-between"
							>
								<Grid item xs={12} md={4} sm={3}>
									<Field
										component={CheckboxInput}
										name="vegetarian"
										placeholder="Vegetarian"
										label="Vegetarian"
									/>
								</Grid>
								<Grid item xs={12} md={4} sm={3}>
									<Field
										component={CheckboxInput}
										name="vegan"
										placeholder="Vegan"
										label="Vegan"
									/>
								</Grid>
								<Grid item xs={12} md={4} sm={3}>
									<Field
										component={CheckboxInput}
										name="glutenFree"
										placeholder="Gluten Free"
										label="Gluten Free"
									/>
								</Grid>
								<Grid item xs={12} md={4} sm={3}>
									<Field
										component={CheckboxInput}
										name="dairyFree"
										placeholder="Dairy Free"
										label="Dairy Free"
									/>
								</Grid>
								<Grid item xs={12} md={4} sm={3}>
									<Field
										component={CheckboxInput}
										name="containsNuts"
										placeholder="Contains Nuts"
										label="Contains Nuts"
									/>
								</Grid>
								<Grid item xs={12} md={4} sm={3}>
									<Field
										component={CheckboxInput}
										name="halal"
										placeholder="Halal"
										label="Halal"
									/>
								</Grid>
								<Grid item xs={12} md={4} sm={3}>
									<Field
										component={CheckboxInput}
										name="organic"
										placeholder="Organic"
										label="Organic"
									/>
								</Grid>
							</Grid>
						</Grid>
					</FormGroup>
				</Grid>
			</Grid>
		</>
	);
};

export default ProductForms;
