/**
 *
 * Application Form to apply as a Snaqer
 *
 * @format */

import { useEffect, useState } from "react";

import AddressForm from "../application/AddressForm";
import Box from "@mui/material/Box";
import Button from "../../components/Button";
import Grid from "@mui/material/Grid";
import Layout from "../../components/layout/Layout";
import Paper from "@mui/material/Paper";
import SnaqerInformationForm from "../application/SnaqerInformation";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Title from "../../components/typography/Title";
import Typography from "@mui/material/Typography";
import { trackEvent } from "scripts/intercom";

const steps = ["Snaqer Details", "Address"];

const ApplyPage: React.FunctionComponent = (): JSX.Element => {
	const [activeStep, setActiveStep] = useState(0);

	const pullStep = (data: any) => {
		setActiveStep(data);
	};

	useEffect(() => {
		return trackEvent("ApplyPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getStepContent = (step: number) => {
		switch (step) {
			case 0:
				return <SnaqerInformationForm func={pullStep} />;
			case 1:
				return <AddressForm func={pullStep} />;
			default:
				throw new Error("Unknown step");
		}
	};

	return (
		<Layout title="Apply as a Snaqer">
			<Paper
				variant="outlined"
				sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
			>
				<Typography component="h1" variant="h4" align="center">
					Apply as a Snaqer
				</Typography>
				<Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<>
					{activeStep === steps.length ? (
						<>
							<Typography variant="h2" gutterBottom color="primary">
								Thank you for your application.
							</Typography>
							<Typography>
								We will review your application as soon as possible. Please make
								sure to upload your proof that you are allowed to handle food
								and set up your payment information.
							</Typography>
							<Box py={3}>
								<Button variant="contained" href="/producer/dashboard">
									Go to the dashboard
								</Button>
							</Box>
						</>
					) : (
						<>{getStepContent(activeStep)}</>
					)}
				</>
			</Paper>
			<Grid item xs={12} sm={12}>
				<Box py={0}>
					<Title>What does it mean to become a Snaqer?</Title>
					<Typography>
						Snaqing focuses on authentic homemade or homegrown products. We want
						to bring back authentic food to the community and give everyone the
						opportunity to taste authentic cuisine from around the world. The
						focus is to support locals who love to cook, bake or grow food
						products from home. {"\n"}
						{"\n"}As a Snaqer you will be your own boss and earn extra money by
						offering homemade prepared dishes or homegrown products to other
						people. {"\n"}
						{"\n"}Create your favorite, most popular dishes and let others
						experience the taste they have never imagined exists.
					</Typography>
				</Box>
			</Grid>
		</Layout>
	);
};

export default ApplyPage;
