/**
 *
 * Queries for modifiers
 *
 * @format */

import {
	FOOD_PRODUCTS,
	FOOD_PRODUCTS_MODIFIERS,
	MODIFIER_COLLECTION,
	PRODUCER_COLLECTION,
	PRODUCER_ID_PREFIX,
} from "scripts/globalVariables";
import { getProducerId, getUserId } from "scripts/globalFunctions";

import firebase from "firebase";
import { useState } from "react";

// Get Producers Product data on producer home screen
export const useModifiers = (): [
	boolean,
	boolean,
	() => void,
	undefined | object,
] => {
	const [modifiers, setModifiers] = useState<object>();
	const [loading, setLoading] = useState(true);
	const [emptyModifiers, setEmptyModifiers] = useState<boolean>(true);
	const userId = getUserId();
	const producerId = getProducerId(userId);

	const getModifiers = () => {
		try {
			return firebase
				.firestore()
				.collection(PRODUCER_COLLECTION)
				.doc(producerId)
				.collection(MODIFIER_COLLECTION)
				.onSnapshot((data) => {
					if (!data.empty) {
						const modifierArray: any = [];
						data.forEach((singleModifier) => {
							const newProductDataPush = () => {
								const modifierObject = singleModifier.data();
								modifierObject.productId = singleModifier.id;
								modifierArray.push(modifierObject);
							};
							newProductDataPush();
							setModifiers(modifierArray.concat([]));
						});
						setEmptyModifiers(false);
					} else {
						setEmptyModifiers(true);
					}
					setLoading(false);
				});
		} catch (error) {
			console.error("Error while fetching producer modifiers", error);
		}
	};
	return [loading, emptyModifiers, getModifiers, modifiers];
};

// Get specific modifier for producer
export const useModifier = (
	modifierId: string,
): [object | undefined, () => void, boolean] => {
	const [modifier, setModifier] = useState<Object | undefined>({});
	const [loading, setLoading] = useState(true);
	const userId = getUserId();
	const producerId = getProducerId(userId);

	const getModifier = () => {
		firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(MODIFIER_COLLECTION)
			.doc(modifierId)
			.get()
			.then((modifierData) => {
				const data = modifierData.data();
				setModifier(data);
				setLoading(false);
			})
			.catch((error: any) => {
				console.error("Error While getting modifiers: ", error);
			});
	};
	return [modifier, getModifier, loading];
};

// Get modifiers for users (e.g. ProductDetailScreen)
export const useModifiersOnProduct = (
	producerId: string,
	modifierIds: Array<string>,
): [() => void, object, boolean] => {
	const [loading, setLoading] = useState(true);
	const [modifiers, setModifiers] = useState<Array<Object>>([]);

	const getModifiers = () => {
		const productRef = firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(FOOD_PRODUCTS_MODIFIERS);

		productRef
			.where("modifierId", "in", modifierIds)
			.get()
			.then((modifierDocs) => {
				const modifierArray: Array<Object> = [];
				modifierDocs.forEach((modifier) => {
					modifierArray.push(modifier.data());
				});
				setModifiers(modifierArray.concat([]));
				setLoading(false);
			})
			.catch((error: any) => {
				console.error(
					"Error while getting modifiers in UseModifiersOnProduct: ",
					error,
				);
			});
	};

	return [getModifiers, modifiers, loading];
};

// Add FoodProductModifier
export const addProductModifier = (data: Object) => {
	const userId = getUserId();
	const producerId = PRODUCER_ID_PREFIX + userId;
	const userData = {
		userId: userId,
		producerId: producerId,
	};
	const dateTime = {
		createdUnix: Date.now(),
	};
	const setData = { ...data, ...userData, ...dateTime };
	const id = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(FOOD_PRODUCTS_MODIFIERS)
		.add(setData)
		.then((doc) => {
			doc.set(
				{
					modifierId: doc.id,
				},
				{ merge: true },
			);
			return doc.id;
		})
		.catch((error) => {
			console.error(error);
		});
	return id;
};

// Add FoodProductModifierOption
export const addProductModifierOption = (modifierId: string, data: Object) => {
	const userId = getUserId();
	const producerId = getProducerId(userId);

	const dateTime = {
		createdUnix: Date.now(),
	};
	const setData = { ...data, ...dateTime };

	const id = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(FOOD_PRODUCTS_MODIFIERS)
		.doc(modifierId)
		.set(
			{
				modifierOptions: {
					[`${(setData as any).optionName}`]: (setData as any).price,
				},
				modifierId: modifierId,
			},
			{ merge: true },
		)
		.catch((error) => {
			console.error(error);
		});
	return id;
};

// Delete Modifier
export const deleteModifier = (producerId: string, modifierId: string) => {
	firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(FOOD_PRODUCTS_MODIFIERS)
		.doc(modifierId)
		.delete()
		.catch((error) => {
			console.error("Error while deleting a product", error);
		})
		.catch((error: any) => {
			console.error("Error while trying to delete a modifier: ", error);
		});
};

// Update Modifier
export const updateModifier = async (
	modifierId: string,
	producerId: string,
	data: any,
) => {
	try {
		firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(FOOD_PRODUCTS_MODIFIERS)
			.doc(modifierId)
			.set(data, { merge: true });
	} catch (error) {
		console.error("Error", error);
	}
};

// Adds modifierId and name ot foodProduct
export const addModifierToFoodProduct = (
	producerId: string,
	foodProductId: string,
	modifierId: string,
	modifierName: string,
) => {
	firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(FOOD_PRODUCTS)
		.doc(foodProductId)
		.set(
			{
				modifiers: {
					[modifierId]: modifierName,
				},
			},
			{ merge: true },
		)
		.catch((error: any) => {
			console.error("Error while setting modifier to food product: ", error);
		});
};
