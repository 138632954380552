/**
 * Add Product Information
 *
 * @format
 */

import { Box } from "@mui/system";
import Button from "components/Button";
import { Formik } from "formik";
import { PRODUCER_ID_PREFIX } from "scripts/globalVariables";
import ProductForms from "./ProductForms";
import React from "react";
import Title from "components/typography/Title";
import { addProduct } from "scripts/firestoreQueries";
import firebase from "firebase";
import { foodProductSchema } from "scripts/formValidation";
import { getUserId } from "scripts/globalFunctions";

type Props = {
	func: Function;
};

const AddProduct: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const onSubmit = async (data: any) => {
		const productId = await addProduct(data as any);
		let newProduct = firebase.functions().httpsCallable("newProduct");
		newProduct({
			productId: productId,
			userId: getUserId(),
			producerId: PRODUCER_ID_PREFIX + getUserId(),
		}).catch((error) => {
			console.error("Error while adding new product :", error);
		});
		props.func(1, productId);
	};

	const initialValues = {};

	return (
		<>
			<Box py={2}>
				<Title>Product Details</Title>
			</Box>
			<Formik
				validationSchema={foodProductSchema}
				onSubmit={onSubmit}
				initialValues={initialValues}
			>
				{({ handleSubmit, isValid, dirty }) => (
					<>
						<ProductForms />
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
							}}
							py={2}
						>
							<Button
								variant="contained"
								sx={{ mt: 3, ml: 1 }}
								onClick={() => handleSubmit()}
								disabled={!(isValid && dirty)}
							>
								Next
							</Button>
						</Box>
					</>
				)}
			</Formik>
		</>
	);
};

export default AddProduct;
