/**
 *
 * Order Page
 *
 * @format
 */

import Order from "../pages/order/OrderPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const OrderPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Orders - Snaqing | Homemade food");

	return <Order />;
};

export default OrderPage;
