/**
 * Table of product modifier options to delete or edit.
 *
 * @format
 */

import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { PRODUCER_ID_PREFIX } from "scripts/globalVariables";
import React from "react";
import Title from "components/typography/Title";
import { deleteModifierOption } from "scripts/firestoreQueries";
import firebase from "firebase";

type Props = {
	modifier: any;
	modifierId: string;
};

const ProductModifierOptionTable: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const userId = firebase.auth().currentUser?.uid;
	const producerId = PRODUCER_ID_PREFIX + userId;

	return (
		<>
			<Title>Options</Title>
			<Table size="small">
				<TableBody>
					{/* <Conditional if={props.modifier?.modifierOptions !== undefined}>
						<Conditional if={props.modifier?.modifierOptions !== null}> */}
					{props.modifier?.modifierOptions &&
						Object.entries(props.modifier?.modifierOptions).map((data: any) => {
							for (const [_index, _option] of Object.entries(data)) {
								for (const item of Object.values([data])) {
									return (
										<>
											<TableRow>
												<TableCell>
													<Typography>{item[0]}</Typography>
												</TableCell>
												<TableCell>
													<Typography>$ {item[1]}</Typography>
												</TableCell>
												<TableCell align="right" size="small">
													<IconButton
														aria-label="delete"
														size="small"
														onClick={() => {
															deleteModifierOption(
																producerId,
																props.modifierId,
																item[0],
															);
														}}
													>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										</>
									);
								}
							}
						})}
					{/* </Conditional>
					</Conditional> */}
				</TableBody>
			</Table>
		</>
	);
};

export default ProductModifierOptionTable;
