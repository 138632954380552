/**
 *
 * New Modifier Page
 *
 * @format
 */

import NewProductModifier from "../pages/productModifier/NewProductModifierPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const NewProductModifierPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("New modifier - Snaqing | Homemade food");

	return <NewProductModifier />;
};

export default NewProductModifierPage;
