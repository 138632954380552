/**
 * Order page for a producer
 *
 * @format
 */

import Order from "../pages/order/ProducerOrderPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const ProducerOrderPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Snaqer Orders - Snaqing | Homemade food");

	return <Order />;
};

export default ProducerOrderPage;
