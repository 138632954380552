/**
 *
 * Component to show dietary tags for the product
 *
 * @format
 */

import { Box, Chip } from "@mui/material";
import {
	faBrain,
	faDrumstickBite,
	faGlobeAmericas,
	faLeaf,
	faRecycle,
	faSeedling,
	faSpa,
	faWineBottle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { capitalizeFirstLetter } from "../../scripts/globalFunctions";

type Props = {
	data: any;
};

const DietaryTags: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const tags = [];

	tags.push({
		name: capitalizeFirstLetter(props.data.cuisine),
		icon: faGlobeAmericas as any,
	});
	props.data.containsNuts
		? tags.push({ name: "Contains nuts", icon: faBrain })
		: null;
	props.data.dairyFree
		? tags.push({ name: "Dairy free", icon: faWineBottle })
		: null;
	props.data.glutenFree
		? tags.push({ name: "Gluten free", icon: faSpa })
		: null;
	props.data.halal ? tags.push({ name: "Halal", icon: faDrumstickBite }) : null;
	props.data.organic ? tags.push({ name: "Organic", icon: faRecycle }) : null;
	props.data.vegan ? tags.push({ name: "Vegan", icon: faSeedling }) : null;
	props.data.vegetarian
		? tags.push({ name: "Vegetarian", icon: faLeaf })
		: null;
	return (
		<>
			{tags.map((tag, i) => {
				return (
					<Box mr={0.5} my={1} key={i + tag.name}>
						<Chip
							label={tag.name}
							variant="outlined"
							key={i}
							icon={<FontAwesomeIcon icon={tag.icon} />}
						/>
					</Box>
				);
			})}
		</>
	);
};

export default DietaryTags;
