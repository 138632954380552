/**
 *
 * ProducerProfilePage which shows the producer profile information with location, products etc.
 *
 * @format */

import {
	Box,
	Button,
	Card,
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useProducer, useProducerProducts } from "scripts/firestoreQueries";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Layout from "../components/layout/Layout";
import ProducerMap from "components/map/ProducerMap";
import ProducerProfileHeader from "components/producer/ProducerProfileHeader";
import ProductSliderHorizontal from "components/product/ProductSliderHorizontal";
import React from "react";
import SubNavigationBar from "components/navigation/SubNavigationBar";
import firebase from "firebase";
import { getUserId } from "scripts/globalFunctions";
import theme from "themes/materialUi";
import { trackEvent } from "scripts/intercom";
import { useOpeningHours } from "scripts/firestoreOpeningHours";

const ProducerDetailPage: React.FunctionComponent = (): JSX.Element => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const userId = getUserId();
	const [loadingOpeningHours, noHours, openingHours, getOpeningHours] =
		useOpeningHours();
	const { producerId }: any = useParams();
	const participants = [userId, producerId].sort();
	const roomId = participants[0] + "-" + participants[1];
	const location = useLocation();
	const url = `https://portal.snaqing.com${location.pathname}`;
	const [
		producerInformation,
		addressInformation,
		loading,
		getProducerInformation,
	] = useProducer();
	const [products, getProducerProducts, _loading, emptyProducts] =
		useProducerProducts();

	useEffect(() => {
		//@ts-ignore
		return getOpeningHours(producerId);
	});

	useEffect(() => {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});
	});

	useEffect(() => {
		//@ts-ignore
		return getProducerInformation(producerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		//@ts-ignore
		return getProducerProducts(true, producerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return trackEvent("ProducerDetailPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title={"Snaqers Profile"} showToolbar={isLoggedIn}>
			<SubNavigationBar />
			{loading ? (
				<LinearProgress />
			) : (
				<>
					<Grid container spacing={5}>
						<Grid item xs={12} lg={12} display="flex" justifyContent="center">
							<Card
								sx={{
									width: "50%",
									paddingTop: "20px",
									// [theme.breakpoints.only("xs")]: {
									minWidth: "100%",
									// },
								}}
							>
								<ProducerProfileHeader
									producerInformation={producerInformation}
								/>
								<Box
									p={3}
									justifyContent="center"
									alignItems="center"
									display="flex"
									flexDirection="column"
								>
									<Typography variant="h3" mb={0.5}>
										{`Hey I'm ${(producerInformation as any).displayName}`}
									</Typography>
									<Typography variant="caption">
										{`from ${
											typeof (producerInformation as any).address !==
											"undefined"
												? (producerInformation as any).address.city +
												  ", " +
												  (producerInformation as any).address.street
												: ""
										}`}
									</Typography>
									<Typography mt={2}>
										{(producerInformation as any).bio}
									</Typography>
								</Box>
							</Card>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<Typography mb={1}>
									Share my profile with your friends
								</Typography>
								<CopyToClipboard text={url} onCopy={() => {}}>
									<Button variant="contained" onClick={() => {}}>
										Copy my profile link
									</Button>
								</CopyToClipboard>
							</Box>
						</Grid>
						<Grid item xs={12} md={6} display="flex" justifyContent="center">
							<Box display="flex" flexDirection="column" alignItems="center">
								{isLoggedIn ? (
									<>
										<Typography mb={1}>Send me a message</Typography>
										<Link
											to={{
												pathname: "/message/conversation",
												state: {
													data: {
														roomId: roomId,
														participants: [userId, producerId].sort(),
													},
												},
											}}
											style={{ textDecoration: "none" }}
										>
											<Button variant="contained">Message me</Button>
										</Link>
									</>
								) : (
									<>
										<Typography mb={1}>Send me a message</Typography>
										<Button variant="contained" href="/auth">
											Login to send a message
										</Button>
									</>
								)}
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h3" mb={0.5}>
								Other snaqs I make
							</Typography>
							<ProductSliderHorizontal
								productData={products}
								producerData={producerInformation as any}
								openingHours={openingHours}
							/>
						</Grid>
						<Grid item xs={12}>
							<Card sx={{ padding: 2, height: "500px" }}>
								<Box py={2}>
									<Typography variant="h3"> Where you can find me</Typography>
								</Box>
								<ProducerMap addressInformation={addressInformation} />
							</Card>
						</Grid>
					</Grid>
				</>
			)}
		</Layout>
	);
};

export default ProducerDetailPage;
