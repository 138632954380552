/**
 * Root of the application
 *
 * @format
 */

import React from "react";
import ThankYou from "../pages/ThankYouPage";
import useTitle from "../hooks/useTitle";

const ThankYouPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Thank you - Snaqing | Homemade food");

	return <ThankYou />;
};

export default ThankYouPage;
