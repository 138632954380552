/**
 *
 * Thank you page after order process finished.
 *
 * @format */

import { Box, Button, Card, Grid } from "@mui/material";

import Layout from "../components/layout/Layout";
import Typography from "@mui/material/Typography";
import { trackEvent } from "scripts/intercom";
import { useEffect } from "react";

const ThankYouPage: React.FunctionComponent = (): JSX.Element => {
	useEffect(() => {
		return trackEvent("ThankYouPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Thank you for ordering on Snaqing">
			<Grid
				container
				display="flex"
				flexDirection="column"
				alignItems="center"
				py={3}
				spacing={2}
			>
				<Grid item xs={12}>
					<Card>
						<Box
							m={3}
							display="flex"
							alignItems="center"
							flexDirection="column"
						>
							<Typography variant="h3" mb={1}>
								THANK YOU! 🎉
							</Typography>
							<Typography>
								Thank you for your order with Snaqing and supporting our Snaqers
								by choosing a homemade snaq. ❤️
							</Typography>
							<Typography mt={2}>
								Please check your order page to see if the order was successful.
							</Typography>
						</Box>
					</Card>
				</Grid>

				<Grid item xs={12} lg={6} display="flex" alignItems="center">
					<Button href="/order" variant="contained">
						Check orders
					</Button>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Button href="/" variant="contained">
						Explore more Snaqs
					</Button>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default ThankYouPage;
