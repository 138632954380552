/**
 *
 * Opening Hours Page
 *
 * @format
 */

import OpeningHours from "../pages/product/OpeningHoursPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const OpeningHoursPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Opening Hours - Snaqing | Homemade food");

	return <OpeningHours />;
};

export default OpeningHoursPage;
