/**
 *
 * Pick Up Time Selector to pick a pickup slot before ordering.
 *
 * @format
 */

import { Field, Formik } from "formik";
import { LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
	convertDateFormatToString,
	convertStringFormatToDateTime,
	twoDigitMinutes,
} from "scripts/intervalFunctions";
import { formatAMPM, minimumIntegerDigits } from "scripts/globalFunctions";

import FormDropdown from "components/formik/FormDropdown";
import { useOpeningHours } from "scripts/firestoreOpeningHours";

type Props = {
	producerData: any;
};

const PickUpTimeSelector: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const times: Array<Object> = [];
	const [loading, noHours, openingHours, getOpeningHours] = useOpeningHours();
	const producerData = props.producerData;

	useEffect(() => {
		//@ts-ignore
		return getOpeningHours(producerData.producerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const restructureOpeningHours = () => {
		openingHours
			.sort((a: any, b: any) => {
				const dateAArray = a[0].split("-");
				const dateBArray = b[0].split("-");
				const aDay = minimumIntegerDigits(dateAArray[1]);
				const aMonth = minimumIntegerDigits(dateAArray[1]);
				const aYear = dateAArray[1];

				const bDay = minimumIntegerDigits(dateBArray[1]);
				const bMonth = minimumIntegerDigits(dateBArray[1]);
				const bYear = dateBArray[1];

				const aDateFormatted = aYear + aMonth + aDay;
				const bDateFormatted = bYear + bMonth + bDay;
				return aDateFormatted + bDateFormatted;
			})
			.map((openingDate: any) => {
				const date = openingDate[0];
				openingDate[1].map((openingHourArray: any) => {
					const openingHour = openingHourArray[1][0];
					const closingHour = openingHourArray[1][1];
					let openingDateTime = convertStringFormatToDateTime(
						date,
						openingHour,
					);
					const closingDateTime = convertStringFormatToDateTime(
						date,
						closingHour,
					);
					for (
						const time: any = openingDateTime;
						time < closingDateTime;
						time.setMinutes(time.getMinutes() + 30)
					) {
						const dateString = convertDateFormatToString(time);
						const minutes = twoDigitMinutes(time);
						const timeString = time.getHours().toString() + ":" + minutes;
						const formattedTime = formatAMPM(timeString);
						const timeFormatted = dateString + " ••• " + formattedTime;
						times.push({
							label: timeFormatted,
							value: timeFormatted,
						});
					}
				});
			});
	};
	openingHours.length > 0 && restructureOpeningHours();
	return (
		<>
			{loading ? (
				<LinearProgress />
			) : noHours ? (
				<Typography>
					There are no times left. Please message the Snaqer directly or choose
					other snaqs from another Snaqer. We are sorry for any inconvenience.
				</Typography>
			) : (
				<>
					<Field
						component={FormDropdown}
						name="pickUpTime"
						placeholder="Pick up time"
						autoComplete="Pick up time"
						// label="Pick Up Slots"
						list={times}
					/>
				</>
			)}
		</>
	);
};

export default PickUpTimeSelector;
