/**
 * Product Detail Page for a use
 *
 * @format
 */

import ProductDetail from "../pages/ProductDetailPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const ProductDetailPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Snaq detail - Snaqing | Homemade food");

	return <ProductDetail />;
};

export default ProductDetailPage;
