/**
 *
 * Map Page
 *
 * @format
 */

import Map from "../pages/map/MapPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const MapPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Map - Snaqing homemade food");

	return <Map />;
};

export default MapPage;
