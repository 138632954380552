/**
 * Router for all pages.
 *
 * @format
 */

import type { History, LocationState } from "history";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { LinearProgress } from "@mui/material";
import PageRoutesAccountInformation from "./AccountInformationPage";
import PageRoutesApply from "./ApplyPage";
import PageRoutesAuthController from "./AuthControllerPage";
import PageRoutesBasket from "./BasketPage";
import PageRoutesCertificate from "./CertificatePage";
import PageRoutesCheckout from "./CheckoutPage";
import PageRoutesConversation from "./ConversationPage";
import PageRoutesDashboard from "../pages/DashboardPage";
import PageRoutesIndex from "./IndexPage";
import PageRoutesMap from "./MapPage";
import PageRoutesMessage from "./MessagePage";
import PageRoutesNewProduct from "./NewProductPage";
import PageRoutesNewProductModifier from "./NewProductModifierPage";
import PageRoutesOpeningHours from "./OpeningHoursPage";
import PageRoutesOrder from "./OrderPage";
import PageRoutesPayment from "./PaymentPage";
import PageRoutesPaymentMethod from "./PaymentMethodPage";
import PageRoutesProducerAccountInformation from "./ProducerAccountInformationPage";
import PageRoutesProducerDetail from "./ProducerDetailPage";
import PageRoutesProducerOrder from "./ProducerOrderPage";
import PageRoutesProduct from "./ProductPage";
import PageRoutesProductDetail from "./ProductDetailPage";
import PageRoutesProductModifier from "./ProductModifierPage";
import PageRoutesResetPassword from "./ResetPasswordPage";
import PageRoutesSearch from "./SearchPage";
import PageRoutesThankYou from "./ThankYouPage";
import PageRoutesUpdateProduct from "./UpdateProductPage";
import PageRoutesUpdateProductModifier from "./UpdateProductModifierPage";
import { useUser } from "scripts/firestoreQueries";

type Props = {
	history: History<LocationState>;
};

export const PrivateRoutes: React.FunctionComponent<Props> = ({
	history,
}: Props): JSX.Element => {
	const [userInformation, loading, getUser] = useUser();
	const [role, setRole] = useState<string>("");

	useEffect(() => {
		return getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (userInformation) {
			setRole((userInformation as any).role);
		}
	}, [userInformation]);

	return loading ? (
		<LinearProgress />
	) : (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path="/producer/account"
					component={
						role === "producer"
							? PageRoutesProducerAccountInformation
							: PageRoutesAuthController
					}
				/>
				<Route exact path="/account" component={PageRoutesAccountInformation} />
				<Route
					exact
					path="/apply"
					component={
						role === "producer" ? PageRoutesDashboard : PageRoutesApply
					}
				/>
				<Route
					exact
					path="/producer/certificate"
					component={
						role === "producer"
							? PageRoutesCertificate
							: PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/order"
					component={
						role === "producer"
							? PageRoutesProducerOrder
							: PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/payment"
					component={
						role === "producer" ? PageRoutesPayment : PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/product"
					component={
						role === "producer" ? PageRoutesProduct : PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/opening-hours"
					component={
						role === "producer"
							? PageRoutesOpeningHours
							: PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/product-modifier"
					component={
						role === "producer"
							? PageRoutesProductModifier
							: PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/new-product"
					component={
						role === "producer"
							? PageRoutesNewProduct
							: PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/new-product-modifier"
					component={
						role === "producer"
							? PageRoutesNewProductModifier
							: PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/update-product/:productId"
					component={
						role === "producer"
							? PageRoutesUpdateProduct
							: PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/update-product-modifier/:modifierId"
					component={
						role === "producer"
							? PageRoutesUpdateProductModifier
							: PageRoutesAuthController
					}
				/>
				<Route
					exact
					path="/producer/dashboard"
					component={
						role === "producer" ? PageRoutesDashboard : PageRoutesIndex
					}
				/>
				<Route
					exact
					path="/snaqer/:producerId"
					component={PageRoutesProducerDetail}
				/>
				<Route
					exact
					path="/snaq/:productId/:producerId"
					component={PageRoutesProductDetail}
				/>
				<Route exact path="/basket" component={PageRoutesBasket} />
				<Route exact path="/checkout" component={PageRoutesCheckout} />
				<Route exact path="/message" component={PageRoutesMessage} />
				<Route
					exact
					path="/message/conversation"
					component={PageRoutesConversation}
				/>
				<Route exact path="/order" component={PageRoutesOrder} />
				<Route exact path="/thank-you" component={PageRoutesThankYou} />
				<Route
					exact
					path="/payment-method"
					component={PageRoutesPaymentMethod}
				/>
				<Route exact path="/map" component={PageRoutesMap} />
				<Route exact path="/search" component={PageRoutesSearch} />
				<Route exact path="/" component={PageRoutesIndex} />
				<Route path="*" component={PageRoutesIndex} />
			</Switch>
		</Router>
	);
};

export const PublicRoutes: React.FunctionComponent<Props> = ({
	history,
}: Props): JSX.Element => {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/auth" component={PageRoutesAuthController} />
				<Route exact path="/apply" component={PageRoutesAuthController} />
				<Route
					exact
					path="/reset-password"
					component={PageRoutesResetPassword}
				/>
				<Route
					exact
					path="/snaqer/:producerId"
					component={PageRoutesAuthController}
				/>
				<Route
					exact
					path="/snaq/:productId/:producerId"
					component={PageRoutesAuthController}
				/>
				<Route exact path="/map" component={PageRoutesAuthController} />
				<Route exact path="/search" component={PageRoutesAuthController} />
				<Route exact path="/" component={PageRoutesAuthController} />
				<Route path="*" component={PageRoutesAuthController} />
			</Switch>
		</Router>
	);
};
