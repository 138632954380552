/**
 *
 * Appbar - The AppBar for all the screen elements
 *
 * @format */

import MuiAppBar, {
	AppBarProps as MuiAppBarProps,
} from "@mui/material/AppBar";

import { drawerWidth } from "../../scripts/globalVariables";
import { experimentalStyled as styled } from "@mui/material/styles";

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default AppBar;
