/**
 *
 * Component to use conditional rendering more easily
 *
 * @format
 */

const Conditional = (condition: any) => {
	return !!condition.if && condition.children;
};

export default Conditional;
