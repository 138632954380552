/**
 *
 * Payment page for a producer
 *
 * @format
 */

import Payment from "../pages/PaymentPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const PaymentPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Snaqer Payment Information - Snaqing | Homemade food");

	return <Payment />;
};

export default PaymentPage;
