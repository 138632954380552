/**
 *
 * Index page once a user logged in
 *
 * @format */

import {
	PRODUCER_COLLECTION,
	PRODUCER_ID_PREFIX,
	STRIPE_ACCOUNT_COLLECTION,
} from "../scripts/globalVariables";
import { useEffect, useState } from "react";

import AccountExists from "../components/producerPayment/AccountExists";
import Layout from "../components/layout/Layout";
import LinearProgress from "@mui/material/LinearProgress";
import NoAccountExists from "../components/producerPayment/NoAccountExists";
import firebase from "firebase";
import { trackEvent } from "scripts/intercom";

const IndexPage: React.FunctionComponent = (): JSX.Element => {
	const producerId = PRODUCER_ID_PREFIX + firebase.auth().currentUser?.uid;
	const [accountExistence, setAccountExistence] = useState<boolean>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const stripeRef = firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(STRIPE_ACCOUNT_COLLECTION)
			.get()
			.catch((error) => {
				console.error("Error while fetching stripe docs", error);
			});

		stripeRef
			.then((stripeDocs) => {
				if ((stripeDocs as any).empty) {
					setAccountExistence(false);
				} else {
					setAccountExistence(true);
				}
			})
			.catch((error) => {
				console.error("Error while checking stripe doc existence", error);
			});
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return trackEvent("PaymentPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Payment Information">
			{loading ? (
				<LinearProgress />
			) : accountExistence ? (
				<AccountExists />
			) : (
				<NoAccountExists />
			)}
		</Layout>
	);
};

export default IndexPage;
