/**
 *
 * Switch Component for the Formik forms.
 * This component automatically hooks some props to make reading the formik form easier.
 *
 * @format */

import { FormControlLabel, FormHelperText, Switch } from "@mui/material";

import React from "react";
import { capitalizeFirstLetter } from "scripts/globalFunctions";

const FormSwitch = (props: any): JSX.Element => {
	const {
		field: { name, onBlur, value },
		form: { errors, touched, setFieldTouched, setFieldValue },
		...inputProps
	} = props;

	const hasError = errors[name] && touched[name];

	return (
		<>
			<FormControlLabel
				control={
					<Switch
						checked={value}
						label="name"
						onChange={() => {
							setFieldValue(name, !value);
							props.func &&
								props.func(
									name,
									!value,
									props.price,
									props.modifierName,
									props.modifierId,
								);
						}}
						onBlur={() => {
							setFieldTouched(name);
							onBlur(name);
						}}
						{...inputProps}
					/>
				}
				label={capitalizeFirstLetter(name)}
			/>
			{!hasError && <FormHelperText>{errors[name]}</FormHelperText>}
		</>
	);
};

export default FormSwitch;
