/**
 *
 * These global variables will be used in multiple locations to refference to firestore locations
 * (collections, docs) or set any global settings.
 *
 * @format */

export const USERS_COLLECTION = "users";
export const CHARGES_COLLECTION = "charges";
export const PAYMENT_METHODS_COLLECTION = "paymentMethods";
export const STRIPE_TOKENS_COLLECTION = "stripeTokens";
export const BASKET_COLLECTION = "basket";
export const PRODUCER_COLLECTION = "producers";
export const PRODUCER_ID_PREFIX = "producer";
export const PRODUCER_VERIFICATION_STATUS = "isApproved";
export const PRODUCER_ACCOUNT_STATUS = "isActive";
export const FAVORITE_PRODUCERS_COLLECTION = "favoriteProducers";
export const PRODUCTS_COLLECTION = "foodProducts";
export const PRODUCT_IMAGE_COLLECTION = "products";
export const FEED_ORDER_BY_ITEM = "price";
export const ROOMS_COLLECTION = "rooms";
export const MESSAGES_SUBCOLLECTION = "messages";
export const PARTICIPANT_IDS_ARRAY = "participants";
export const FOOD_PRODUCTS = "foodProducts";
export const FOOD_PRODUCTS_MODIFIERS = "foodProductsModifiers";
export const AVAILABILITY_COLLECTION = "availability";
export const OPENING_HOURS_COLLECTION = "openingHours";
export const ADDRESSES_COLLECTION = "addresses";
export const LISTS_COLLECTION = "remoteLists";
export const STRIPE_ACCOUNT_COLLECTION = "stripe";
export const ORDERS_COLLECTION = "orders";
export const SEND_MAIL = "sendMail";
export const PROMOTION = "promotions";
export const FEED_PROMOTION = "feedPromotion";
export const CERTIFICATE_IMAGE_PREFIX = "certificate";
export const PRODUCER_PROFILE_IMAGE_PREFIX = "profile-picture";
export const MODIFIER_COLLECTION = "modifiers";
export const BASKET_PRODUCTS = "products";

export const drawerWidth: number = 240;
