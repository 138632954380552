/**
 *
 * Order Page
 *
 * @format
 */

import React from "react";
import Search from "../pages/SearchPage";
import useTitle from "../hooks/useTitle";

const SearchPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Orders - Snaqing | Homemade food");

	return <Search />;
};

export default SearchPage;
