/**
 *
 * Firestore functions for opening hours engine
 *
 * @format */

import {
	OPENING_HOURS_COLLECTION,
	PRODUCER_COLLECTION,
} from "./globalVariables";
import {
	convertDateFormatToDate,
	isGoodInterval,
	isOverlapping,
} from "./intervalFunctions";
import {
	convertDateStringToDate,
	dateInPast,
	getProducerId,
	getUserId,
} from "./globalFunctions";

import firebase from "firebase";
import { useState } from "react";

export const convertDate = (date: Date) => {
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const year = date.getFullYear();
	return month + "-" + day + "-" + year;
};

export const convertTime = (date: Date) => {
	const hours = date.getHours();
	let minutes = date.getMinutes().toString();
	if (minutes === "0") {
		minutes = "00";
	}
	return hours + ":" + minutes;
};

export const addOpeningHours = async (date: Date, intervals: any) => {
	const producerId = getProducerId(getUserId());
	const dateFormat = convertDateFormatToDate(date); // returns MM:DD:YYYY format

	const openingHoursRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(OPENING_HOURS_COLLECTION)
		.doc(dateFormat);

	await openingHoursRef.set(
		{
			producerId: getProducerId(getUserId()),
			userId: getUserId(),
			createdDtm: new Intl.DateTimeFormat("en-US", {
				timeZone: "America/Los_Angeles",
				dateStyle: "long",
				timeStyle: "long",
				hour12: false,
			}).format(Date.now()),
			// [dateFormat]: firebase.firestore.FieldValue.arrayUnion(intervals),
			hours: {
				[intervals[0] + "-" + intervals[1]]: intervals,
			},
		},
		{ merge: true },
	);

	const data = await (await openingHoursRef.get()).data();
	const hours: Object = data?.hours;
	let hoursArray: Array<any> = [];
	Object.values(hours).map((hour) => {
		const stringify = JSON.stringify(hour);
		hoursArray.push(stringify);
	});
};

export const validateOpeningHours = async (date: Date, newInterval: any) => {
	const dateFormat = convertDateFormatToDate(date);
	const producerId = getProducerId(getUserId());

	const openingHoursRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(OPENING_HOURS_COLLECTION)
		.doc(dateFormat);
	if (isGoodInterval(newInterval)) {
		const data = (await openingHoursRef.get()).data();
		const hours: Object = data?.hours;

		let currentIntervals: Array<any> = [];

		if (typeof hours != "undefined") {
			Object.values(hours).map((hour) => {
				currentIntervals.push(hour);
			});
			if (isOverlapping(currentIntervals, newInterval)) {
				return "Hours are overlapping with existing ones. Please adjust new hours.";
			} else {
				addOpeningHours(date, newInterval);
				return true;
			}
		} else {
			addOpeningHours(date, newInterval);
		}
	} else return "Hours are not valid. Please adjust new hours.";
};

export const useOpeningHours = (): [
	boolean,
	boolean,
	Array<object>,
	() => void,
] => {
	const [hoursLoading, setHoursLoading] = useState(true);
	const [openingHours, setOpeningHours] = useState<any>([]);
	const [noHours, setNoHours] = useState(true);

	const getOpeningHours = (producerId = getProducerId(getUserId())) => {
		firebase
			.firestore()
			.collection(PRODUCER_COLLECTION)
			.doc(producerId)
			.collection(OPENING_HOURS_COLLECTION)
			.onSnapshot((openingHoursDoc) => {
				if (openingHoursDoc.size > 0) {
					setNoHours(false);
				} else {
					setNoHours(true);
				}

				const today = new Date();
				const openingHoursArray: any[] = [];
				const tomorrow = new Date(today);
				tomorrow.setDate(tomorrow.getDate() + 1);
				openingHoursDoc.forEach((openingHour) => {
					const openingDateInDateFormat = convertDateStringToDate(
						openingHour.id,
					);
					if (!dateInPast(today, openingDateInDateFormat)) {
						const hours = openingHour.data().hours;
						const hoursArray: Array<any> = [];
						Object.entries(hours).map((hour: any) => {
							hoursArray.push(hour);
						});
						openingHoursArray.push([openingHour.id, hoursArray]);
					}
				});
				setOpeningHours(openingHoursArray);
				setHoursLoading(false);
			});
	};
	return [hoursLoading, noHours, openingHours, getOpeningHours];
};

export const deleteOpenHours = async (
	date: string,
	timeFrom: string,
	timeTo: string,
) => {
	const producerId = getProducerId(getUserId());
	const docRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(OPENING_HOURS_COLLECTION)
		.doc(date);
	await docRef.set(
		{
			hours: {
				[timeFrom + "-" + timeTo]: firebase.firestore.FieldValue.delete(),
			},
		},
		{ merge: true },
	);
	await docRef.get().then((doc) => {
		const hours = doc.data()?.hours;
		if (Object.keys(hours).length === 0) {
			docRef.delete();
		}
	});
};
