/**
 *
 * Component which shows the different rooms for a user.
 *
 * @format
 */

import {
	Box,
	Button,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import {
	PARTICIPANT_IDS_ARRAY,
	ROOMS_COLLECTION,
} from "../../scripts/globalVariables";
import React, { useEffect, useState } from "react";
import { getProducerId, getUserId } from "../../scripts/globalFunctions";

import { Link } from "react-router-dom";
import MessageRow from "./MessageRow";
import firebase from "firebase";
import { getReceiverData } from "scripts/messages/messageFunctions";

const UserRoomList: React.FunctionComponent = (): JSX.Element => {
	const senderId = getUserId();
	const [rooms, setRooms] = useState<any>([]);
	const [loading, setLoading] = useState(true);
	const [emptyRooms, setEmptyRooms] = useState(false);
	const [totalMessages, setTotalMessages] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const handleChangePage = (
		_event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	};
	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, totalMessages - page * rowsPerPage);

	useEffect(() => {
		if (typeof rooms !== "undefined") {
			const total = Object.values(rooms).length;
			setTotalMessages(total);
		}
	}, [rooms]);

	useEffect(() => {
		setLoading(true);
		const subscribe = firebase
			.firestore()
			.collection(ROOMS_COLLECTION)
			.where(PARTICIPANT_IDS_ARRAY, "array-contains", senderId)
			.onSnapshot((roomDocs) => {
				let roomArray: Array<object> = [];
				if (!roomDocs.empty) {
					setEmptyRooms(false);
					roomDocs.forEach(async (room: any) => {
						const participants: Array<string> = room.data().participants;
						let senderIsUser = true;
						let receiverId: string;
						const generateReceiverFilter = async () => {
							participants.forEach((participantId) => {
								if (senderId!.includes("producer")) {
									senderIsUser = false;
								}
								if (participantId !== senderId) {
									receiverId = participantId;
								}
							});
						};
						await generateReceiverFilter().then(async () => {
							await getReceiverData(receiverId, senderIsUser).then(
								(receiver) => {
									roomArray.push({
										...room.data(),
										roomId: room.id,
										receiver: { ...receiver.data() },
									});
								},
							);
						});
						const sortedRoomArray = roomArray.sort((a: any, b: any) => {
							const keyA = new Date(a.lastMessageTimestamp.nanoseconds);
							const keyB = new Date(b.lastMessageTimestamp.nanoseconds);
							// Compare the 2 dates
							if (keyA < keyB) {
								return -1;
							}
							if (keyA > keyB) {
								return 1;
							}
							return 0;
						});
						setRooms(sortedRoomArray);
					});
					setLoading(false);
				} else {
					setEmptyRooms(true);
					setLoading(false);
				}
			});
		return () => subscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table" key="id">
					<TableHead id="header">
						<TableRow key="rowHeader">
							<TableCell key="rowHeaderName">Snaqer Name</TableCell>
							<TableCell key="rowHeaderLastMessage">Last message</TableCell>
							<TableCell key="rowHeaderAction"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody key="body">
						{loading ? (
							<LinearProgress />
						) : emptyRooms ? (
							<TableRow sx={{ backgroundColor: "#f1f1f1" }}>
								<TableCell colSpan={3}>
									<Box m={3}>
										<Typography mb={2}>
											You've no messages sent or received yet.
										</Typography>
										<Button href="/" variant="contained">
											Explore snaqs
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						) : (
							typeof rooms !== "undefined" &&
							Object.entries(rooms as any)
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((data: any, index: number) => {
									return <MessageRow data={data} key={index} isUser={true} />;
								})
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 81 * emptyRows }}>
								<TableCell colSpan={5} />
							</TableRow>
						)}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={totalMessages}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>
		</>
	);
};

export default UserRoomList;
