/**
 *
 * BoxWithTitleSubtitleAndLink
 *
 * @format
 */

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import React from "react";
import Title from "../typography/Title";
import Typography from "@mui/material/Typography";

type Props = {
	title: string;
	subTitle: string;
	description: string;
	linkTitle: string;
	link: string;
	icon?: JSX.Element;
};

const BoxWithTitleSubtitleAndLink: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	return (
		<>
			<Box mt={2}>
				<Paper
					sx={{
						p: 2,
						display: "flex",
						flexDirection: "column",
						// height: 240,
					}}
				>
					<Title>
						{props.icon && props.icon} {props.title}
					</Title>
					<Typography component="p" variant="h4">
						{props.subTitle}
					</Typography>
					<Typography>{props.description}</Typography>
					<Box py={2}>
						<Link color="primary" href={props.link}>
							{props.linkTitle}
						</Link>
					</Box>
				</Paper>
			</Box>
		</>
	);
};

export default BoxWithTitleSubtitleAndLink;
