/**
 *
 * Checkout Page to select time and payment method
 *
 * @format
 */

import Checkout from "../pages/order/CheckoutPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const CheckoutPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Checkout - Snaqing | Homemade food");

	return <Checkout />;
};

export default CheckoutPage;
