/**
 *
 * Search Page
 *
 * @format */

import {
	Avatar,
	Box,
	Card,
	CardContent,
	FormControl,
	Grid,
	InputLabel,
	Link,
	OutlinedInput,
	Typography,
} from "@mui/material";
import {
	Configure,
	InstantSearch,
	connectHits,
	connectSearchBox,
} from "react-instantsearch-dom";
import { algoliaApp, algoliaKey } from "helper/constant";
import { useEffect, useState } from "react";

import Layout from "../components/layout/Layout";
import SearchIcon from "@mui/icons-material/Search";
import algoliasearch from "algoliasearch";
import firebase from "firebase";
import { trackEvent } from "scripts/intercom";

const SearchPage: React.FunctionComponent = (): JSX.Element => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const algoliaKeys = algoliasearch(algoliaApp, algoliaKey);
	const searchClient = {
		...algoliaKeys,
		search(requests: any) {
			if (requests.every(({ params }: any) => !params.query)) {
				return Promise.resolve({
					results: requests.map(() => ({
						hits: [],
						nbHits: 0,
						nbPages: 0,
						page: 0,
						processingTimeMS: 0,
					})),
				});
			}

			return algoliaKeys.search(requests);
		},
	};

	useEffect(() => {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});
	});

	useEffect(() => {
		return trackEvent("Searchpage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
		return (
			<Box
				flexDirection="row"
				display="flex"
				alignItems="center"
				pt={3}
				sx={{
					border: "0",
					maxWidth: "100%",
				}}
			>
				<FormControl sx={{ m: 1, width: "100%" }}>
					<InputLabel>Search for Snaqer, city, and more</InputLabel>
					<OutlinedInput
						id="outlined-adornment-amount"
						value={currentRefinement}
						onChange={(e: any) => {
							refine(e.target.value);
						}}
						startAdornment={
							<SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
						}
						label="Search for Snaqer, city, and more"
						sx={{
							backgroundColor: "white",
							boxShadow: "0px 5px 5px 0px rgba(0,0,0,.1)",
						}}
					/>
				</FormControl>
			</Box>
		);
	});

	const Hits = (props: any) => {
		return props.hits.map((hit: any) => {
			if (hit.isActive && hit.isApproved) {
				if (hit["address.street"] != "undefined") {
					const address =
						hit["address.street"] +
						" " +
						hit["address.city"] +
						"-" +
						hit["address.state"];
					return (
						<Link href={`/snaqer/${hit.objectID}`} underline="none">
							<Grid container marginX={1} marginY={1}>
								<Card sx={{ display: "flex", width: 1 }}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "column",
											paddingX: 2,
										}}
									>
										<Avatar
											key={"profileImage"}
											src={hit.profileImage ? hit.profileImage : ""}
											sx={{ width: 25, height: 25 }}
										/>
									</Box>
									<CardContent sx={{ width: 1 }}>
										<Typography>{hit.displayName}</Typography>
										<Typography>{address.slice(0, 25)}</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Link>
					);
				} else {
					return <Box></Box>;
				}
			} else {
				return <Box></Box>;
			}
		});
	};

	const CustomHits = connectHits(Hits);
	return (
		<Layout title="Search Snaqing | Homemade Food" showToolbar={isLoggedIn}>
			<InstantSearch searchClient={searchClient} indexName="producers">
				<Configure hitsPerPage={5} />
				<SearchBox />
				<CustomHits />
			</InstantSearch>
		</Layout>
	);
};

export default SearchPage;
