/**
 *
 * Page to show all modifiers to the producer
 *
 * @format
 */

import ProductModifier from "../pages/productModifier/ProductModifierPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const ProductModifierPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("All Modifires - Snaqing | Homemade food");

	return <ProductModifier />;
};

export default ProductModifierPage;
