/**
 *
 * ProductDetailPage which shows the details about a single product.
 *
 * @format */

import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Snackbar,
	Tab,
	Tabs,
} from "@mui/material";
import { Link, Redirect, useLocation, useParams } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { addToBasket, clearBasket } from "scripts/order/orderQueries";
import { faIdBadge, faMortarPestle } from "@fortawesome/free-solid-svg-icons";
import { formatAMPM, getUserId } from "scripts/globalFunctions";
import { useEffect, useState } from "react";

import AddToBasketForm from "components/forms/AddToBasketForm";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import Layout from "../components/layout/Layout";
import ModifierForm from "components/forms/ModifierForm";
import OpeningHoursHorizontalScroll from "../components/openingHours/OpeningHoursHorizontalScroll";
import PriceCalculation from "components/PriceCalculation";
import ProducerMap from "components/map/ProducerMap";
import ProductInformationCard from "components/product/ProductInformationCard";
import ProfileCardHeader from "components/product/ProfileCardHeader";
import SubNavigationBar from "components/navigation/SubNavigationBar";
import Typography from "@mui/material/Typography";
import { addToBasketSchema } from "scripts/formValidation";
import firebase from "firebase";
import { generateOpeningHourTag } from "scripts/intervalFunctions";
import theme from "themes/materialUi";
import { trackEvent } from "scripts/intercom";
import { useHistory } from "react-router-dom";

const initialValues = {
	portions: "",
};

const ProductDetailPage: React.FunctionComponent = (): JSX.Element => {
	let productData: any;
	const history = useHistory();
	const userId = getUserId();
	const { productId, producerId }: any = useParams();
	const location: any = useLocation();
	// Redirect to producerProfile in case someone comes to the productDetail page directly.
	if (typeof location.state === "undefined") {
		history.push(`/snaqer/${producerId}`);
	} else {
		productData = location.state.data;
	}
	const participants = [userId, producerId].sort();
	const roomId = participants[0] + "-" + participants[1];
	const [modifierSum, setModifierSum] = useState<number>();
	const [modifierValidation, setModifierValidation] = useState(true);
	const [modifierSelection, setModifierSelection] = useState({});
	const modifierLength = productData.modifiers
		? Object.keys(productData.modifiers).length
		: 0;
	const [snackBarVisible, setSnackBarVisible] = useState(true);
	const [totalPrice, setTotalPrice] = useState();
	const [portions, setPortions] = useState(1);
	const [showBasketClearDialog, setShowBasketClearDialog] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loginDialogVisibility, setLoginDialogVisibility] = useState(false);

	const [value, setValue] = useState("1");

	const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	useEffect(() => {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});
	}, []);

	useEffect(() => {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});
	});

	useEffect(() => {
		return trackEvent("ProductDetailPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let openingHourTag: string = "";
	const tagStatus = generateOpeningHourTag(productData.producer.openingHours);
	if (tagStatus === "ON_REQUEST") {
		openingHourTag = "On request";
	} else if (tagStatus.includes("ORDER_UNTIL")) {
		const hour = tagStatus.split(" ")[1];
		openingHourTag = `Order by ${formatAMPM(hour)}`;
	} else if (tagStatus === "PRE_ORDER") {
		openingHourTag = "Pre-order";
	} else {
		openingHourTag = "";
	}

	const snackBarAction = (
		<>
			<Button color="secondary" size="small" href="/basket">
				Go to my cart
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={() => setSnackBarVisible(!snackBarVisible)}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);
	return (
		<Layout title={"Welcome to Snaqing"} showToolbar={isLoggedIn}>
			<SubNavigationBar />
			<ProductInformationCard data={productData} />
			<Box>
				<TabContext value={value}>
					<Box
						py={3}
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<TabList onChange={handleChange} aria-label="lab API tabs example">
							<Tab
								label={
									<>
										<FontAwesomeIcon icon={faMortarPestle} size="2x" />
										<Typography>Snaq</Typography>
									</>
								}
								value="1"
							/>
							<Tab
								label={
									<>
										<FontAwesomeIcon icon={faIdBadge} size="2x" />
										<Typography>Snaqer</Typography>
									</>
								}
								value="2"
							/>
						</TabList>
					</Box>
					<TabPanel value="1">
						<Grid container display="flex" justifyContent="center">
							<Card
								sx={{
									width: "80%",
									[theme.breakpoints.only("xs")]: {
										minWidth: "100%",
									},
								}}
							>
								<Box m={3}>
									<Typography variant="h3" mb={0.5}>
										Opening hours
									</Typography>
									<OpeningHoursHorizontalScroll data={productData} />
								</Box>
								{tagStatus === "ON_REQUEST" ? (
									<Grid item xs={12}>
										<Box m={3}>
											<Typography>
												Please reach out to the Snaqer directly via our
												messaging feature in our App.
											</Typography>
											<Box py={2}>
												{isLoggedIn ? (
													<Link
														to={{
															pathname: "/message/conversation",
															state: {
																data: {
																	roomId: roomId,
																	participants: [userId, producerId].sort(),
																},
															},
														}}
														style={{ textDecoration: "none" }}
													>
														<Button variant="contained">Message me</Button>
													</Link>
												) : (
													<Button variant="contained" href="/auth">
														Login to send a message
													</Button>
												)}
											</Box>
										</Box>
									</Grid>
								) : (
									<Grid item xs={12}>
										<Formik
											initialValues={initialValues}
											validationSchema={addToBasketSchema}
											// @ts-ignore
											onSubmit={async (orderDetails) => {
												const orderData = {
													...orderDetails,
													price: totalPrice,
													portions: portions,
													additions: { ...modifierSelection },
													details: { ...productData },
												};
												if (isLoggedIn) {
													try {
														if (await addToBasket(orderData)) {
															setSnackBarVisible(!snackBarVisible);
														} else {
															setShowBasketClearDialog(!showBasketClearDialog);
														}
													} catch {
														(error: any) => {
															console.error(
																"Error while adding product to basket: ",
																error,
															);
														};
													}
												} else {
													setLoginDialogVisibility(true);
												}
											}}
										>
											{({ handleSubmit, isValid }) => {
												return (
													<>
														{modifierLength > 0 && (
															<ModifierForm
																data={productData}
																calculationFunc={setModifierSum}
																modifierValidationErrors={setModifierValidation}
																getModifierSelection={setModifierSelection}
															/>
														)}
														<AddToBasketForm
															data={productData}
															funcToSetPortion={setPortions}
														/>
														<PriceCalculation
															productPrice={productData.price}
															amount={portions}
															modifierSum={modifierSum as number}
															getTotalPrice={setTotalPrice}
														/>
														<Box
															mb={3}
															mr={3}
															sx={{
																display: "flex",
																justifyContent: "flex-end",
															}}
														>
															<Button
																onClick={async () => {
																	handleSubmit();
																}}
																variant="contained"
																disabled={
																	isValid && modifierValidation ? false : true
																}
															>
																Add to basket
															</Button>
															{showBasketClearDialog && (
																<Dialog open={showBasketClearDialog}>
																	<DialogTitle id="responsive-dialog-title">
																		{
																			"You have already a snaq from another Snaqer in your basket."
																		}
																	</DialogTitle>
																	<DialogContent>
																		<DialogContentText>
																			We need to clear your basket to add this
																			snaq to it. Do you want to continue with
																			clearing your basket?
																		</DialogContentText>
																	</DialogContent>
																	<DialogActions>
																		<Button
																			autoFocus
																			onClick={() =>
																				setShowBasketClearDialog(
																					!showBasketClearDialog,
																				)
																			}
																		>
																			Cancel
																		</Button>
																		<Button
																			onClick={() => {
																				clearBasket();
																				setShowBasketClearDialog(
																					!showBasketClearDialog,
																				);
																			}}
																			autoFocus
																		>
																			Clear basket
																		</Button>
																	</DialogActions>
																</Dialog>
																// <DialogToCleanBasket dismiss={setShowBasketClearDialog} />
															)}
														</Box>
													</>
												);
											}}
										</Formik>
									</Grid>
								)}
							</Card>
						</Grid>
					</TabPanel>
					<TabPanel value="2">
						<Link
							to={{ pathname: `/snaqer/${producerId}` }}
							style={{ textDecoration: "none" }}
						>
							<ProfileCardHeader data={productData} />
						</Link>
						<Box
							py={3}
							justifyContent="center"
							alignContent="center"
							alignItems="center"
						>
							<ProducerMap addressInformation={productData.producer.address} />
						</Box>
					</TabPanel>
				</TabContext>
			</Box>
			<Snackbar
				open={!snackBarVisible}
				autoHideDuration={9000}
				onClose={() => setSnackBarVisible(!snackBarVisible)}
				message="You've just added a new Snaq to your cart."
				action={snackBarAction}
			/>
			<Dialog open={loginDialogVisibility}>
				<DialogTitle id="responsive-dialog-title">
					{"Please sign in"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						You need to sign in to continue with the order flow.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setLoginDialogVisibility(!loginDialogVisibility)}
					>
						Cancel
					</Button>
					<Button href="/auth" autoFocus>
						Sign In
					</Button>
				</DialogActions>
			</Dialog>
		</Layout>
	);
};

export default ProductDetailPage;
