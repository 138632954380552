/**
 * Add Product Information
 *
 * @format
 */

import { Field, Formik } from "formik";

import { Box } from "@mui/system";
import Button from "components/Button";
import FormDropdown from "components/formik/FormDropdown";
import FormInput from "components/formik/FormInput";
import { Grid } from "@mui/material";
import React from "react";
import Title from "components/typography/Title";
import { addProductModifier } from "scripts/firestoreQueries";
import { amountOptions } from "../product/FoodVariables";
import { foodProductModifierSchema } from "scripts/formValidation";

type Props = {
	func: Function;
};

const AddProductModifier: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const onSubmit = async (data: any) => {
		const productModifier = await addProductModifier(data as any);
		props.func(1, productModifier);
	};

	const initialValues = {};

	return (
		<>
			<Box py={2}>
				<Title>Modifier Name</Title>
			</Box>
			<Formik
				validationSchema={foodProductModifierSchema}
				onSubmit={onSubmit}
				initialValues={initialValues}
			>
				{({ handleSubmit, isValid, dirty }) => (
					<>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<Field
									component={FormInput}
									name="modifierName"
									// placeholder="Product name"
									autoComplete="modifier name"
									label="Modifier name"
								/>
							</Grid>
						</Grid>
						<Box py={2}>
							<Title>Conditions</Title>
						</Box>
						<Grid container spacing={3}>
							<Grid item xs={4}>
								<Field
									component={FormDropdown}
									name="atLeast"
									placeholder="at least"
									autoComplete="at least"
									label="Select at Least"
									list={amountOptions}
								/>
							</Grid>
							<Grid item xs={4}>
								<Field
									component={FormDropdown}
									name="atMost"
									placeholder="at most"
									autoComplete="at most"
									label="Select at Most"
									list={amountOptions}
								/>
							</Grid>
						</Grid>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								variant="contained"
								sx={{ mt: 3, ml: 1 }}
								href="/producer/product-modifier"
							>
								Back
							</Button>
							<Button
								variant="contained"
								sx={{ mt: 3, ml: 1 }}
								onClick={() => handleSubmit()}
								disabled={!(isValid && dirty)}
							>
								Next
							</Button>
						</Box>
					</>
				)}
			</Formik>
		</>
	);
};

export default AddProductModifier;
