/** @format */

import "./Marker.css";

import {
	Avatar,
	Badge,
	Box,
	Button,
	Card,
	CardHeader,
	Grid,
	Link,
} from "@mui/material";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as LinkRouter } from "react-router-dom";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import zIndex from "@mui/material/styles/zIndex";

const Marker = (props: any) => {
	const { color, data } = props;
	const [visible, setVisible] = useState(false);
	return (
		<>
			<Box>
				<Link onClick={() => setVisible(true)}>
					<Box
						className="pin bounce"
						style={{ backgroundColor: color, cursor: "pointer" }}
					/>
					<Box className="pulse" />
				</Link>
			</Box>
			{visible && (
				<Box sx={{ position: "absolute", zIndex: 99 }}>
					<Card sx={{ width: 300 }}>
						<LinkRouter
							to={{
								pathname: `/snaqer/${data.producerId}`,
								state: { data: data },
							}}
							style={{ textDecoration: "none", color: "#3B6E6E" }}
						>
							<CardHeader
								avatar={
									<Badge
										color="secondary"
										overlap="circular"
										badgeContent={<FontAwesomeIcon icon={faAward} scale={3} />}
										anchorOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
									>
										<Avatar
											key={"profileImage"}
											src={
												typeof data.profileImage !== "undefined"
													? data.profileImage
													: ""
											}
											sx={{
												width: 76,
												height: 76,
											}}
										/>
									</Badge>
								}
								title={data.displayName}
								subheader={data.address.street}
							/>
						</LinkRouter>
						<Grid
							container
							direction="row"
							justifyContent="left"
							alignItems="center"
						>
							<Grid item>
								<Button onClick={() => setVisible(false)}>Close</Button>
							</Grid>
							<Grid item>
								<LinkRouter
									to={{
										pathname: `/snaqer/${data.producerId}`,
										state: { data: data },
									}}
									style={{ textDecoration: "none" }}
								>
									<Button>Snaqer Profile</Button>
								</LinkRouter>
							</Grid>
						</Grid>
					</Card>
				</Box>
			)}
		</>
	);
};

export default Marker;
