/**
 *
 * Input component for formik forms
 * This component automatically hooks some props to make reading the formik form easier
 *
 * @format */

import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const FormDropdown = (props: any): JSX.Element => {
	const {
		field: { name, onBlur, onChange, value },
		// @ts-ignore
		form: { errors, touched, setFieldTouched },
		...inputProps
	} = props;

	return (
		<>
			<InputLabel shrink id={props.label}>
				{props.label}
			</InputLabel>
			<Select
				style={{ width: "100%" }}
				variant="standard"
				labelId={props.label}
				value={value}
				onChange={(text) => onChange(name)(text)}
				onBlur={() => {
					setFieldTouched(name);
					onBlur(name);
				}}
				{...inputProps}
			>
				{props.list.map((item: any) => (
					<MenuItem value={item.value}>{item.label}</MenuItem>
				))}
			</Select>
			<FormHelperText>{errors[name]}</FormHelperText>
		</>
	);
};

export default FormDropdown;
