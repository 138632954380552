/**
 *
 * Basket Page which shows the users basket with recommended products
 *
 * @format */

import { Box, Card, CardHeader, LinearProgress } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useBasketData, useTotalBasketPrice } from "scripts/order/orderQueries";
import { useEffect, useState } from "react";

import BasketItem from "components/basket/BasketItem";
import Layout from "components/layout/Layout";
import LoadingButton from "@mui/lab/LoadingButton";
import ProductSliderHorizontal from "components/product/ProductSliderHorizontal";
import SubNavigationBar from "components/navigation/SubNavigationBar";
import Title from "components/typography/Title";
import Typography from "@mui/material/Typography";
import { trackEvent } from "scripts/intercom";
import { useProducerProducts } from "scripts/firestoreQueries";

const BasketPage: React.FunctionComponent = (): JSX.Element => {
	const [loading, isEmpty, getBasketData, basketData] = useBasketData();
	const [triggerLoading, setTriggerLoading] = useState<boolean>(false);
	let history = useHistory();
	const [products, getProducerProducts, loadingProducts, emptyProducts] =
		useProducerProducts();

	const [totalBasketLoading, getTotalBasketPrice, totalBasketPrice] =
		useTotalBasketPrice(basketData as any);

	useEffect(() => {
		return getBasketData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerLoading]);

	useEffect(() => {
		if (!isEmpty) {
			if (!loading) {
				//@ts-ignore
				return getProducerProducts(true, (basketData as any)[0].producerId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useEffect(() => {
		return getTotalBasketPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [basketData]);

	useEffect(() => {
		return trackEvent("BasketPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let handoverData = [];

	const handleCheckout = () => {
		const location = {
			pathname: "/checkout",
			state: {
				producerData: (basketData as any)[0],
				basketData: basketData,
				totalBasketPrice: totalBasketPrice,
			},
		};
		history.push(location);
	};

	return loading ? (
		<Layout title="Your basket full of homemade snaqs">
			<SubNavigationBar />
			<LinearProgress />
		</Layout>
	) : isEmpty ? (
		<Layout title="Your basket full of homemade snaqs">
			<SubNavigationBar />
			<Typography>You have no items in your basket.</Typography>
		</Layout>
	) : (
		<>
			{handoverData.push({ producer: { ...(basketData as any)[0] } })}
			<Layout title="Your basket full of homemade snaqs">
				<SubNavigationBar />
				{loadingProducts ? (
					<LinearProgress />
				) : emptyProducts ? null : (
					<>
						<Box mt={3} mb={3}>
							<Typography variant="h3">
								More products from {(basketData as any)[0].displayName}
							</Typography>
							<ProductSliderHorizontal
								productData={products}
								producerData={(basketData as any)[0]}
							/>
						</Box>
					</>
				)}
				<Card
					variant="outlined"
					style={{
						backgroundColor: "#e1e9e1",
					}}
				>
					<Typography variant="h3" m={3}>
						Your items
					</Typography>
					{(basketData as any)[1]?.map((item: any) => {
						return (
							<BasketItem
								data={item}
								docId={item.docId}
								func={setTriggerLoading}
								trigger={triggerLoading}
							/>
						);
					})}
					<Box justifyContent="flex-end" display="flex" m={3}>
						<Typography>
							Original price{" "}
							{`$${((totalBasketPrice as any) * 1.15).toFixed(2)}`}
						</Typography>
					</Box>
					<Box justifyContent="flex-end" display="flex" m={3}>
						<Typography>
							<strong>
								Discount (-15%)
								{` -$${((totalBasketPrice as any) * 0.15).toFixed(2)}`}
							</strong>
						</Typography>
					</Box>
					<Box justifyContent="flex-end" display="flex" m={3}>
						<LoadingButton
							size="large"
							loading={totalBasketLoading}
							loadingPosition="start"
							onClick={() => handleCheckout()}
							variant="contained"
						>
							Checkout {`$${(totalBasketPrice as any).toFixed(2)}`}
						</LoadingButton>
					</Box>
				</Card>
			</Layout>
		</>
	);
};

export default BasketPage;
