/** @format */

import * as Yup from "yup";

import Box from "@mui/material/Box";
import Button from "./Button";
import { FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "./TextField";
import Typography from "@mui/material/Typography";
import { USERS_COLLECTION } from "scripts/globalVariables";
import firebase from "firebase/app";
import { updateLoginTimes } from "scripts/firestoreQueries";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const validationSchema = Yup.object({
	email: Yup.string().email("Invalid email address").required("Required"),
	password: Yup.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
});

const SignInForm = () => {
	const [loginError, setLoginError] = useState("");
	const [errorMessage, setErrorMessage] = useState(false);
	const history = useHistory();
	const signInWithEmailAndPassword = async (
		email: string,
		password: string,
	) => {
		await firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(() => {
				history.push("/");
			})
			.catch((error) => {
				var errorMessage = error.message;
				if (error.code === "auth/invalid-email") {
					setLoginError("Invalid email!");
				}
				if (error.code === "auth/wrong-password") {
					setLoginError(
						"Wrong password. Please try again or consider using Google, Facebook or Apple to sign in.",
					);
				}
				if (error.code === "auth/user-not-found") {
					setLoginError("User not found, please sign up first.");
				}
				console.error("Error: ", error.code + " " + errorMessage);
			});
	};

	const signInWithFacebook = () => {
		var provider = new firebase.auth.FacebookAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((user) => {
				firebase
					.firestore()
					.collection(USERS_COLLECTION)
					.doc(firebase.auth().currentUser!.uid)
					.get()
					.then((doc) => {
						if (doc.exists) {
							updateLoginTimes();
							return user;
						} else {
							const nameArray = user.user?.displayName?.split(" ");
							const firstName = nameArray !== undefined ? nameArray[0] : "";
							const lastName = nameArray !== undefined ? nameArray[1] : "";
							const profileImage = user.user?.photoURL;
							doc.ref.set(
								{
									firstName: firstName,
									lastName: lastName,
									profileImage: profileImage,
								},
								{ merge: true },
							);
						}
					})
					.catch((error) => {
						setErrorMessage(true);
						console.error(
							"Error while writing to Firestore when signing in with Facebook: ",
							error,
						);
					});
			})
			.then(() => {
				history.push("/");
			})
			.catch((error) => {
				console.error("Error while signing in with Facebook: ", error);
				setErrorMessage(true);
			});
	};

	const signInWithGoogle = () => {
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((user) => {
				firebase
					.firestore()
					.collection(USERS_COLLECTION)
					.doc(firebase.auth().currentUser!.uid)
					.get()
					.then((doc) => {
						if (doc.exists) {
							updateLoginTimes();
							return user;
						} else {
							const nameArray = user.user?.displayName?.split(" ");
							const firstName = nameArray !== undefined ? nameArray[0] : "";
							const lastName = nameArray !== undefined ? nameArray[1] : "";
							const profileImage = user.user?.photoURL;
							doc.ref.set(
								{
									firstName: firstName,
									lastName: lastName,
									profileImage: profileImage,
								},
								{ merge: true },
							);
						}
					})
					.catch((error) => {
						console.error(
							"Error while writing to Firebse while signing in with Google: ",
							error,
						);
						setErrorMessage(true);
					});
			})
			.then(() => {
				history.push("/");
			})
			.catch((error) => {
				console.error("Error while signing in with Google: ", error);
				setErrorMessage(true);
			});
	};
	const signInWithApple = () => {
		var provider = new firebase.auth.OAuthProvider("apple.com");
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((user) => {
				firebase
					.firestore()
					.collection(USERS_COLLECTION)
					.doc(firebase.auth().currentUser!.uid)
					.get()
					.then((doc) => {
						if (doc.exists) {
							updateLoginTimes();
							return user;
						} else {
							const nameArray = user.user?.displayName?.split(" ");
							const firstName = nameArray !== undefined ? nameArray[0] : "";
							const lastName = nameArray !== undefined ? nameArray[1] : "";
							const profileImage = user.user?.photoURL;
							doc.ref.set(
								{
									firstName: firstName,
									lastName: lastName,
									profileImage: profileImage,
								},
								{ merge: true },
							);
						}
					})
					.then(() => {
						history.push("/");
					})
					.catch((error) => {
						console.error(
							"Error while writing to firestore when signing in with Apple: ",
							error,
						);
						setErrorMessage(true);
					});
			})
			.then(() => {
				history.push("/");
			})
			.catch((error) => {
				console.error("Error while signing in with Facebook: ", error);
				setErrorMessage(true);
			});
	};

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			passwordConfirmation: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			signInWithEmailAndPassword(values.email, values.password);
		},
	});

	return (
		<Box
			sx={{
				my: 4,
				mx: 4,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<Typography component="h1" variant="h5">
				Sign in
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<Grid container justifyContent="space-between" alignItems="stretch">
					<Grid item xs={12}>
						<TextField
							my={1}
							fullWidth
							id="email"
							name="email"
							label="Email"
							autoComplete="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && loginError}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							my={1}
							fullWidth
							id="password"
							name="password"
							label="Password"
							type="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							error={formik.touched.password && Boolean(formik.errors.password)}
							helperText={formik.touched.password && formik.errors.password}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							my={1}
							color="primary"
							variant="contained"
							fullWidth
							type="submit"
						>
							Sign in
						</Button>
					</Grid>
				</Grid>
			</form>
			{errorMessage && (
				<FormHelperText error>
					There was an error with the login. Please try another provider or
					contact support@snaqing.com
				</FormHelperText>
			)}
			<Grid container>
				<Grid item>
					<Link href="#" variant="body2">
						{"Forgot your password?"}
					</Link>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				OR
			</Grid>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				lg={12}
			>
				<Grid item sx={{ width: "100%" }}>
					<Button
						my={1}
						color="primary"
						variant="contained"
						fullWidth
						onClick={() => signInWithGoogle()}
					>
						Sign In with Google
					</Button>
				</Grid>
				<Grid item sx={{ width: "100%" }}>
					<Button
						my={1}
						color="primary"
						variant="contained"
						fullWidth
						onClick={() => signInWithFacebook()}
					>
						Sign In with Facebook
					</Button>
				</Grid>
				<Grid item sx={{ width: "100%" }}>
					<Button
						my={1}
						color="primary"
						variant="contained"
						fullWidth
						onClick={() => signInWithApple()}
					>
						Sign In with Apple
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SignInForm;
