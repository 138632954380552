/**
 * Root react component for the browser application
 *
 * @format
 */

import "./utilities/firebaseInit";

import { PrivateRoutes, PublicRoutes } from "./routes/Routes";
import React, { useEffect, useState } from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { Elements } from "@stripe/react-stripe-js";
import Geocoder from "react-geocode";
import { LinearProgress } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TagManager from "react-gtm-module";
import firebase from "firebase/app";
import { getUserId } from "scripts/globalFunctions";
import history from "./routes/history";
import { intercomBoot } from "scripts/intercom";
import { loadStripe } from "@stripe/stripe-js";
import materialUITheme from "./themes/materialUi";
import { stripePublishableKey } from "./helper/constant";
import useFavicon from "react-use/lib/useFavicon";
import useTitle from "hooks/useTitle";

Geocoder.setApiKey("AIzaSyBj-03-a6GjXhNFkIJAy7GUcEnOBfsaEwc");
const stripePromise = loadStripe(stripePublishableKey);

const App: React.FunctionComponent = (): JSX.Element => {
	useFavicon(
		"https://firebasestorage.googleapis.com/v0/b/snaqing-prod.appspot.com/o/intern%2Flogo%2Ffavicon.ico?alt=media&token=f85a775e-c4c0-4526-9648-92079a150179",
	);
	useTitle("Snaqing - Authentic Homemade Food");
	const [initializing, setInitializing] = useState(true);
	const [user, setUser] = useState<object | null>();
	const [loading, setLoading] = useState(true);

	const handleAuthStateChange = () => {
		// eslint-disable-next-line no-shadow
		firebase.auth().onAuthStateChanged(async (user) => {
			setUser(user);
			setLoading(false);
			intercomBoot();
		});
		if (initializing) {
			setInitializing(false);
		}
	};

	const tagManagerArgs = {
		gtmId: "GTM-TZ8QMZQ",
	};

	TagManager.initialize(tagManagerArgs);

	useEffect(() => {
		const subscriber = handleAuthStateChange();
		return subscriber;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (process.env.REACT_APP_ENV === "DEV") {
		console.log("User logged in: ", getUserId());
	}

	if (loading) {
		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={materialUITheme}>
					<CssBaseline />
					<LinearProgress color="primary" />
				</ThemeProvider>
			</StyledEngineProvider>
		);
	} else {
		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={materialUITheme}>
					<CssBaseline />
					<LocalizationProvider dateAdapter={DateAdapter}>
						{!user ? (
							<PublicRoutes history={history}></PublicRoutes>
						) : (
							<Elements stripe={stripePromise}>
								<PrivateRoutes history={history}></PrivateRoutes>
							</Elements>
						)}
					</LocalizationProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		);
	}
};

export default App;
