/**
 *
 * User Payment Method page
 *
 * @format
 */

import PaymentMethod from "../pages/PaymentMethodPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const PaymentMethodPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Payment Method - Snaqing | Homemade food");

	return <PaymentMethod />;
};

export default PaymentMethodPage;
