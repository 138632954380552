/**
 *
 * Functions for handling the interval checks for opening hours.
 *
 * @format */

// convert string into number
export const convertTimeToNumber = (time: string) => {
	const hours = Number(time.split(":")[0]);
	const minutes = Number(time.split(":")[1]) / 60;
	return hours + minutes;
};

// convert Date into date format MM-DD-YYYY
export const convertDateFormatToDate = (date: Date) => {
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const year = date.getFullYear();
	return month + "-" + day + "-" + year;
};

// sort intervals
export const sortIntervals = (intervals: Array<string>) => {
	return intervals.sort((intA, intB) => {
		const startA = convertTimeToNumber(intA[0]);
		const endA = convertTimeToNumber(intA[1]);

		const startB = convertTimeToNumber(intB[0]);
		const endB = convertTimeToNumber(intB[1]);

		if (startA > endB) {
			return 1;
		}

		if (startB > endA) {
			return -1;
		}

		return 0;
	});
};

export const isOverlapping = (
	intervals: Array<Array<string>>,
	newInterval: Array<string>,
) => {
	const a = convertTimeToNumber(newInterval[0]);
	const b = convertTimeToNumber(newInterval[1]);

	for (const interval of intervals) {
		const c = convertTimeToNumber(interval[0]);
		const d = convertTimeToNumber(interval[1]);

		if (a < d && b > c) {
			return true;
		}
	}

	return false;
};

export const isGoodInterval = (interval: Array<string>) => {
	let good = false;
	if (interval.length === 2) {
		const start = convertTimeToNumber(interval[0]);
		const end = convertTimeToNumber(interval[1]);

		if (start < end) {
			good = true;
		}
	}

	return good;
};

export const addInterval = (
	currentInterval: any,
	newInterval: Array<string>,
) => {
	if (!isGoodInterval(newInterval)) {
		console.error("This is not an interval");
		return;
	}

	if (!isOverlapping(currentInterval, newInterval)) {
		currentInterval.push(newInterval);
		sortIntervals(currentInterval);
	}
};

// takes date and time as input (string) and returns DateTime
// Date input: MM-DD-YYYY; Time input: hh:mm
export const convertStringFormatToDateTime = (
	stringDate: string,
	stringHourAndMinute: string,
) => {
	const date = convertStringFormatToDate(stringDate);
	const hourAndMinute = stringHourAndMinute.split(":");
	const hour = parseInt(hourAndMinute[0], 10);
	const minute = parseInt(hourAndMinute[1], 10);
	date.setHours(hour, minute, 0, 0);
	return date;
};

// convert Date into date format MM-DD-YYYY
export const convertDateFormatToString = (date: Date) => {
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const year = date.getFullYear();
	return month + "-" + day + "-" + year;
};

// convert string MM-DD-YYYY into Date format and set minutes and hours to 0
export const convertStringFormatToDate = (input: string) => {
	const date = input.split("-");
	const formattedDate = new Date(
		parseInt(date[2], 10), // YYYY
		//@ts-ignore
		parseInt(date[0] - 1, 10), // MM
		parseInt(date[1], 10), // DD
	);
	formattedDate.setMinutes(0, 0, 1000);
	return formattedDate;
};

// Make sure minutes has two digits
export const twoDigitMinutes = (date: Date) => {
	return (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
};

// return future date e.g.: in n days
export const getFutureDate = (days: number) => {
	let date = new Date();
	date.setDate(new Date().getDate() + days);
	return isoDateFormat(date);
};

// Return ISO formated Date
export const isoDateFormat = (date: Date) => {
	return (
		date.getFullYear() +
		"-" +
		String(date.getMonth() + 1).padStart(2, "0") +
		"-" +
		String(date.getDate()).padStart(2, "0")
	);
};

export const generateOpeningHourTag = (openingHours: Object | undefined) => {
	// let status: string = "ON_REQUEST";
	const status = [];
	if (
		typeof openingHours !== "undefined" &&
		Object.entries(openingHours).length > 0
	) {
		Object.entries(openingHours).map((dateTime) => {
			const hoursArray: Array<any> = [];
			const openingDate = convertDateStringToLocalDate(dateTime[0]);
			// check if opening hour is today
			if (isToday(openingDate)) {
				// Get latest Hours
				Object.entries(dateTime[1].hours).forEach((hours: Array<any>) => {
					const time: number = hours[1][1];
					hoursArray.push(time);
				});
				hoursArray.sort((a, b) => {
					return convertTimeToNumber(a) - convertTimeToNumber(b);
				});
				let latestHour = hoursArray.slice(-1)[0];
				let closingHourSliced = String(latestHour);
				// let closingMinuteSliced = String(latestHour);
				status.push(`ORDER_UNTIL ${closingHourSliced}`);
			} else {
				status.push("PRE_ORDER");
			}
		});
	} else {
		status.push("ON_REQUEST");
	}
	const preOrders = status.filter((element) => element.includes("ORDER_UNTIL"));
	if (preOrders.length > 0) {
		return preOrders[0];
	} else if (status.includes("PRE_ORDER")) {
		return "PRE_ORDER";
	} else {
		return "ON_REQUEST";
	}
};

// check if date is today (local timeZone)
export const isToday = (someDate: Date) => {
	const today = getLocalTime();
	today.setUTCHours(0, 0, 0, 0);
	someDate.setUTCHours(0, 0, 0, 0);

	if (
		someDate.getDate() === today.getDate() &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear()
	) {
		return true;
	} else {
		return false;
	}
};

// convert DateString into local Date format
export const convertDateStringToLocalDate = (date: string) => {
	const dateArray = date.split("-");
	let day = dateArray[1];
	day = ("0" + day).slice(-2);
	let month = dateArray[0];
	month = ("0" + month).slice(-2);
	const year = dateArray[2];
	const newDateString = String(year + "-" + month + "-" + day);
	let localDate = new Date(newDateString);

	return localDate;
};

// returns todays date and time in DATE format
export const getLocalTime = () => {
	const today = new Date();
	today.setUTCHours(today.getUTCHours() + -today.getTimezoneOffset() / 60);
	return today;
};
