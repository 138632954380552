/**
 *
 * PaymentMethod page to set up payment for the user
 *
 * @format */

import {
	Avatar,
	Button,
	Card,
	CardHeader,
	FormHelperText,
	Grid,
	IconButton,
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
	addPaymentMethod,
	deletePaymentMethod,
	usePaymentMethod,
} from "scripts/payment/stripeQueries";
import { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Formik } from "formik";
import Layout from "components/layout/Layout";
import { LinearProgress } from "@mui/material";
import SubNavigationBar from "components/navigation/SubNavigationBar";
import Typography from "@mui/material/Typography";
import { trackEvent } from "scripts/intercom";

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: "#3B6E6E",
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: "antialiased",
			fontSize: "16px",
			"::placeholder": {
				color: "#aab7c4",
			},
		},
		invalid: {
			color: "#3B6E6E",
			iconColor: "#3B6E6E",
		},
	},
};

const PaymentMethodPage: React.FunctionComponent = (): JSX.Element => {
	const stripe = useStripe();
	const elements = useElements();
	const [errorMessage, setErrorMessage] = useState("");
	const [
		loadingPaymentMethods,
		noPaymentMethod,
		paymentMethods,
		getPaymentMethod,
	] = usePaymentMethod();

	useEffect(() => {
		return getPaymentMethod();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = async () => {
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement)!;

		// Use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
		});

		if (error) {
			if (error.type === "validation_error") {
				setErrorMessage(
					"There was an error, please make sure you enter the correct data.",
				);
			}
		} else {
			addPaymentMethod(paymentMethod!);
		}
	};

	useEffect(() => {
		return trackEvent("PaymentMethodPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Payment">
			<SubNavigationBar />
			<Grid container>
				<Grid item xs={12} lg={6}>
					<Card sx={{ padding: 3, margin: 3 }}>
						<Typography variant="h3" mb={1}>
							Payment Methods
						</Typography>
						{loadingPaymentMethods ? (
							<LinearProgress />
						) : noPaymentMethod ? (
							<Typography>No Payment added</Typography>
						) : (
							<>
								{paymentMethods.map((paymentMethod, index) => {
									return (
										<CardHeader
											key={index}
											action={
												<IconButton
													onClick={() => {
														deletePaymentMethod(
															(paymentMethod as any)?.card?.fingerprint,
														);
													}}
													size="large"
												>
													<DeleteIcon />
												</IconButton>
											}
											title={`${(paymentMethod as any)?.card?.brand}`}
											subheader={`XXX XXXX ${
												(paymentMethod as any)?.card?.last4
											} | ${(paymentMethod as any)?.card?.exp_month}/${
												(paymentMethod as any)?.card?.exp_year
											}`}
										/>
									);
								})}
							</>
						)}
					</Card>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Card sx={{ padding: 3, margin: 3 }}>
						<Formik initialValues={{}} onSubmit={onSubmit}>
							{({ handleSubmit }) => (
								<>
									<Typography variant="h3" mb={1}>
										Add Payment Method
									</Typography>
									<CardElement options={CARD_ELEMENT_OPTIONS} />
									{errorMessage && (
										<FormHelperText error>{errorMessage}</FormHelperText>
									)}
									<Button
										variant="contained"
										sx={{ mt: 3, ml: 1 }}
										onClick={() => {
											handleSubmit();
										}}
										disabled={!stripe}
									>
										Add Card
									</Button>
								</>
							)}
						</Formik>
					</Card>
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center"></Grid>
			</Grid>
		</Layout>
	);
};

export default PaymentMethodPage;
