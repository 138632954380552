/**
 * React Hook for document title updates
 *
 * NOTE: Use this only in the page entrypoints, not in normal react components.
 *
 * @format
 */

import { useEffect } from "react";

function useTitle(title: string): void {
	useEffect(() => {
		const originalTitle = document.title;
		document.title = title;
		return (): void => {
			document.title = originalTitle;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export default useTitle;
