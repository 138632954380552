/**
 *
 * Queries for the order and checkout flow.
 *
 * @format */

import {
	BASKET_COLLECTION,
	BASKET_PRODUCTS,
	FOOD_PRODUCTS,
	PAYMENT_METHODS_COLLECTION,
	PRODUCER_COLLECTION,
	USERS_COLLECTION,
} from "../globalVariables";

import { FOOD_PRODUCTS_MODIFIERS } from "../globalVariables";
import firebase from "firebase";
import { getUserId } from "../globalFunctions";
import { useState } from "react";

export const addToBasket = async (data: any) => {
	const producerId = data.details.producerId;
	const userId = getUserId();

	const basketDocRef = firebase
		.firestore()
		.collection(USERS_COLLECTION)
		.doc(userId)
		.collection(BASKET_COLLECTION);

	const addProductToBasket = () =>
		basketDocRef
			.doc(producerId)
			.collection(BASKET_PRODUCTS)
			.doc()
			.set({
				data,
			})
			.catch((error) => {
				console.error(
					"Error while writing product to producerDoc in basket: ",
					error,
				);
			});

	return basketDocRef.get().then((basketDocs) => {
		if (basketDocs.empty) {
			basketDocRef
				.doc(producerId)
				.set({
					producer: data.details.producer,
				})
				.catch((error) => {
					console.error("Error while setting producerDoc in basket: ", error);
				});
			addProductToBasket();
			return true;
		} else {
			let producerExists: boolean;
			basketDocs.forEach((doc) => {
				const existingProducer = doc.id;
				if (existingProducer === producerId) {
					addProductToBasket();
					producerExists = true;
				} else {
					// return false statement to AddToBasketForm.tsx show dialog to clear basket
					producerExists = false;
				}
			});
			// @ts-ignore
			return producerExists;
		}
	});
};

export const clearBasket = () => {
	const userId = getUserId();

	const basketRef = firebase
		.firestore()
		.collection(USERS_COLLECTION)
		.doc(userId)
		.collection(BASKET_COLLECTION);

	basketRef
		.get()
		.then((basketDocs) => {
			basketDocs.forEach((basketDoc) => {
				basketDoc.ref
					.collection(BASKET_PRODUCTS)
					.get()
					.then((basketProduct) => {
						basketProduct.forEach((product) => {
							product.ref.delete();
						});
					});
				basketDoc.ref.delete();
			});
		})
		.catch((error: any) => {
			console.error("Error while clearing basket: ", error);
		});
};

export const useBasketData = (): [
	boolean,
	boolean,
	() => void,
	Array<any> | undefined,
] => {
	const userId = getUserId();
	const [loading, setLoading] = useState(true);
	const [isEmpty, setIsEmpty] = useState(true);
	const [basketData, setBasketData] = useState([]);
	let producerData: Object;
	const products: Array<any> = [];

	const getBasketData = async () => {
		const basketRef = firebase
			.firestore()
			.collection(USERS_COLLECTION)
			.doc(userId)
			.collection(BASKET_COLLECTION);

		try {
			await basketRef.onSnapshot((basketDocs) => {
				if (basketDocs.empty) {
					setIsEmpty(true);
					setLoading(false);
				} else {
					basketDocs.forEach(async (basketDoc) => {
						setIsEmpty(false);
						if (basketDoc.exists) {
							producerData = basketDoc.data().producer;
							await basketDoc.ref
								.collection(BASKET_PRODUCTS)
								.get()
								.then((basketProducts) => {
									if (basketProducts.empty) {
										setIsEmpty(true);
										setLoading(false);
									} else {
										setIsEmpty(false);
										basketProducts.forEach((basketProduct) => {
											const docId = basketProduct.id;
											const data = { docId: docId, ...basketProduct.data() };
											products.push(data);
										});
									}
								});
							//@ts-ignore
							setBasketData([producerData, products]);
							setIsEmpty(false);
							setLoading(false);
						} else {
							setIsEmpty(true);
							setLoading(false);
						}
					});
				}
			});
		} catch (error: any) {
			console.error(
				"Error while fetching Basket data in UseBasketData: ",
				error,
			);
		}
	};

	return [loading, isEmpty, getBasketData, basketData];
};

export const deleteProduct = (producerId: string, productDocId: string) => {
	const userId = getUserId();
	const productRef = firebase
		.firestore()
		.collection(USERS_COLLECTION)
		.doc(userId)
		.collection(BASKET_COLLECTION)
		.doc(producerId)
		.collection(BASKET_PRODUCTS)
		.doc(productDocId);
	productRef.delete();
	productRef.parent
		.get()
		.then((basketData) => {
			basketData.empty && productRef.parent.parent?.delete();
		})
		.catch((error) => {
			console.error("Error while deleting a single product in basket: ", error);
		});
};

// get total price of a product including their modifiers
export const useTotalProductPrice = (
	producerId: string,
	productId: string,
	modifierObjects: Array<any>,
	amount: number,
): [boolean, () => void, number | undefined] => {
	const [loading, setLoading] = useState(true);
	const [totalPrice, setTotalPrice] = useState<number>();

	const getPrice = async () => {
		const price = await getTotalProductPrice(
			producerId,
			productId,
			modifierObjects,
			amount,
		);
		setTotalPrice(price);
		setLoading(false);
	};
	return [loading, getPrice, totalPrice];
};

export const useTotalBasketPrice = (
	basketData: any,
): [boolean, () => void, Object | undefined] => {
	const [loading, setLoading] = useState(true);
	const [totalBasketPrice, setTotalBasketPrice] = useState(0);

	const getTotalBasketPrice = async () => {
		const basketInformation: Array<any> = getProductInformation(basketData);
		const productSums: Array<any> = [];
		await Promise.all(
			basketInformation.map(async (product) => {
				const { productId, producerId, modifierObjects, amount } = product;
				const productData: any = await getProductData(producerId, productId);
				const productPrice = parseFloat(productData.price);
				const data = await getTotalOfModifiers(producerId, modifierObjects);
				const productSum = (data + productPrice) * amount;
				productSums.push(productSum);
				productSums.length > 0 &&
					setTotalBasketPrice(productSums.reduce((a, b) => a + b));
			}),
		);
		setTimeout(function () {
			setLoading(false);
		}, 1000);
	};

	return [loading, getTotalBasketPrice, totalBasketPrice];
};

export const usePaymentMethod = (): [
	boolean,
	boolean,
	() => void,
	Object | undefined,
] => {
	const [loading, setLoading] = useState(true);
	const [noPaymentMethod, setNoPaymentMethod] = useState(true);
	const [payment, setPayment] = useState();

	const getPayment = async () => {
		firebase
			.firestore()
			.collection(USERS_COLLECTION)
			.doc(getUserId())
			.collection(PAYMENT_METHODS_COLLECTION)
			.onSnapshot(
				(sources) => {
					if (sources.empty) {
						setNoPaymentMethod(true);
						setLoading(false);
					} else {
						const subscribe = () => {
							const paymentSourceArray: Array<object> = [];
							sources.forEach((source) => {
								paymentSourceArray.push({
									label:
										source.data().card.brand +
										" **** **** **** " +
										source.data().card.last4 +
										" - " +
										source.data().card.exp_month +
										"/" +
										source.data().card.exp_year,
									value: source.data().id,
								});
							});
							setPayment(paymentSourceArray as any);
							setNoPaymentMethod(false);
							setLoading(false);
						};
						return subscribe();
					}
				},
				(error) => {
					console.error(
						"Error while getting payment method on BasketItemScreen",
						error,
					);
				},
			);
		setLoading(false);
	};
	return [loading, noPaymentMethod, getPayment, payment];
};

const getTotalProductPrice = async (
	producerId: string,
	productId: string,
	modifierObjects: Array<any>,
	amount: number,
) => {
	const producerDocRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId);
	const productDocRef = producerDocRef.collection(FOOD_PRODUCTS).doc(productId);
	let totalPrice: number;

	const modifierPrice: number = await getTotalOfModifiers(
		producerId,
		modifierObjects,
	);
	await productDocRef
		.get()
		.then((productDoc) => {
			const productPrice = parseFloat(productDoc.data()?.price);
			// const modifierPrice = modifierPrices.reduce((a, b) => a + b, 0);
			totalPrice = (productPrice + modifierPrice) * amount;
		})
		.catch((error) => {
			// setHasError(true);
			console.error("Error while fetching totalPrice: ", error);
		});
	//@ts-ignore
	return totalPrice;
};

const getTotalOfModifiers = async (
	producerId: string,
	modifierObjects: Array<any>,
) => {
	const producerDocRef = firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId);
	const productModifiersRef = producerDocRef.collection(
		FOOD_PRODUCTS_MODIFIERS,
	);
	const modifierPrices: Array<any> = [];

	await Promise.all(
		Object.entries(modifierObjects).map(async (modifierIdObject) => {
			const modifierId = modifierIdObject[1]?.modifierId;
			const modifierOptionName = modifierIdObject[1]?.modifierOptionName;
			await productModifiersRef
				.doc(modifierId)
				.get()
				.then((modifierObject) => {
					const modifierOptionPrice =
						modifierObject.data()?.modifierOptions[modifierOptionName];
					modifierPrices.push(parseFloat(modifierOptionPrice));
				})
				.catch((error) => {
					console.error(
						"Error while fetching modifierOptions and setting modifierPrice: ",
						error,
					);
				});
		}),
	);
	const data =
		(await Math.round(
			(modifierPrices.reduce((a, b) => a + b, 0) + Number.EPSILON) * 100,
		)) / 100;
	return data;
};

const getProductInformation = (basketData: any) => {
	const productsArray: Array<any> = [];

	basketData[1] &&
		Object.entries(basketData[1]).map((singleProductData) => {
			let producerId: string;
			let productId: string;
			let amount: number;
			const modifierObjects: Array<any> = [];
			const singleProduct = (singleProductData as any)[1].data;
			producerId = singleProduct.details.producerId;
			productId = singleProduct.details.productId;
			amount = singleProduct.portions;
			singleProduct &&
				Object.values(singleProduct.additions).map((additions) => {
					const modifierObject = {
						modifierId: (additions as any).modifierId,
						modifierOptionName: (additions as any).name,
					};
					modifierObjects.push(modifierObject);
				});
			const product = {
				producerId: producerId,
				productId: productId,
				modifierObjects: modifierObjects,
				amount: amount,
			};
			productsArray.push(product);
		});
	return productsArray;
};

const getProductData = async (producerId: string, productId: string) => {
	const price = await firebase
		.firestore()
		.collection(PRODUCER_COLLECTION)
		.doc(producerId)
		.collection(FOOD_PRODUCTS)
		.doc(productId)
		.get();

	return price.data();
};
