/**
 *
 * Food Variables to be used for the producer.
 * These should live in firestore at some point.
 *
 * @format */

export const foodCategory = [
	{ label: "Cooked", value: "cooked" },
	{ label: "Baked", value: "baked" },
	{ label: "Grown", value: "grown" },
];

export const portionType = [
	{ label: "Oz", value: "oz" },
	{ label: "Pieces", value: "pieces" },
	{ label: "Slices", value: "slices" },
	{ label: "Dozen", value: "dozen" },
	{ label: "Bundt", value: "bundt" },
	{ label: "Loaf", value: "loaf" },
	{ label: "Inch", value: "inch" },
	{ label: "Entire", value: "entire" },
	{ label: "Other", value: "other" },
];

export const amountOptions = [
	{ label: 0, value: 0 },
	{ label: 1, value: 1 },
	{ label: 2, value: 2 },
	{ label: 3, value: 3 },
	{ label: 4, value: 4 },
	{ label: 5, value: 5 },
	{ label: 6, value: 6 },
	{ label: 7, value: 7 },
	{ label: 8, value: 8 },
	{ label: 9, value: 9 },
	{ label: 10, value: 10 },
];

export const deliveryMethod = [
	{ label: "Delivery", value: "delivery" },
	{ label: "Pick Up", value: "pickUp" },
	{ label: "Delivery & Pick Up", value: "deliveryAndPickup" },
];

export const courseType = [
	{ label: "Drink", value: "drink" },
	{ label: "Breakfast", value: "breakfast" },
	{ label: "Starter", value: "starter" },
	{ label: "Main", value: "main" },
	{ label: "Dessert", value: "dessert" },
];

export const foodCuisines = [
	{ label: "Others", value: "others" },
	{ label: "Afghan", value: "afghan" },
	{ label: "American", value: "american" },
	{ label: "Albanian", value: "albanian" },
	{ label: "Algerian", value: "algerian" },
	{ label: "Algerian", value: "algerian" },
	{ label: "Angolan", value: "angolan" },
	{ label: "Argentine", value: "argentine" },
	{ label: "Armenian", value: "armenian" },
	{ label: "Aruban", value: "aruban" },
	{ label: "Australian", value: "australian" },
	{ label: "Austrian", value: "austrian" },
	{ label: "Azerbaijani", value: "azerbaijani" },
	{ label: "Bahamian", value: "bahamian" },
	{ label: "Balkan", value: "balkan" },
	{ label: "Baltic", value: "baltic" },
	{ label: "Barbadian", value: "barbadian" },
	{ label: "Basque", value: "basque" },
	{ label: "Belgian", value: "belgian" },
	{ label: "Belizean", value: "belizean" },
	{ label: "Belorussia­n", value: "belorussia­n" },
	{ label: "Bolivian", value: "bolivian" },
	{ label: "Bosnian", value: "bosnian" },
	{ label: "Brazilian", value: "brazilian" },
	{ label: "British", value: "british" },
	{ label: "British Isles", value: "british isles" },
	{ label: "Bulgarian", value: "bulgarian" },
	{ label: "Burmese", value: "burmese" },
	{ label: "Cambodian", value: "cambodian" },
	{ label: "Cameroon", value: "cameroon" },
	{ label: "Cape Verdean", value: "cape verdean" },
	{ label: "Caucasian", value: "caucasian" },
	{ label: "Caucasian", value: "caucasian" },
	{ label: "Chanukkah", value: "chanukkah" },
	{ label: "Chilean", value: "chilean" },
	{ label: "Chinese", value: "chinese" },
	{ label: "Colombian", value: "colombian" },
	{ label: "Congolese", value: "congolese" },
	{ label: "Cornish", value: "cornish" },
	{ label: "Costa Rican", value: "costa rican" },
	{ label: "Croatian", value: "croatian" },
	{ label: "Cuban", value: "cuban" },
	{ label: "Cuban", value: "cuban" },
	{ label: "Czech", value: "czech" },
	{ label: "Côte d'Ivoire", value: "côte d'ivoire" },
	{ label: "Danish", value: "danish" },
	{ label: "Dominican", value: "dominican" },
	{ label: "Dominican", value: "dominican" },
	{ label: "Dutch", value: "dutch" },
	{ label: "Eastern European", value: "eastern european" },
	{ label: "Ecuadorian", value: "ecuadorian" },
	{ label: "Egyptian", value: "egyptian" },
	{ label: "Egyptian", value: "egyptian" },
	{ label: "English", value: "english" },
	{ label: "Ethiopian", value: "ethiopian" },
	{ label: "Filipino", value: "filipino" },
	{ label: "Finnish", value: "finnish" },
	{ label: "French", value: "french" },
	{ label: "Gambian", value: "gambian" },
	{ label: "Georgian", value: "georgian" },
	{ label: "German", value: "german" },
	{ label: "Ghanaian", value: "ghanaian" },
	{ label: "Greek", value: "greek" },
	{ label: "Guam", value: "guam" },
	{ label: "Guatemalan", value: "guatemalan" },
	{ label: "Guyanese", value: "guyanese" },
	{ label: "Haitian", value: "haitian" },
	{ label: "Hawaiian", value: "hawaiian" },
	{ label: "Honduran", value: "honduran" },
	{ label: "Hungarian", value: "hungarian" },
	{ label: "Icelandic", value: "icelandic" },
	{ label: "Indian", value: "indian" },
	{ label: "Indonesian", value: "indonesian" },
	{ label: "Iraqi", value: "iraqi" },
	{ label: "Irish", value: "irish" },
	{ label: "Israeli", value: "israeli" },
	{ label: "Italian", value: "italian" },
	{ label: "Jamaican", value: "jamaican" },
	{ label: "Japanese", value: "japanese" },
	{ label: "Jordanian", value: "jordanian" },
	{ label: "Kashrut", value: "kashrut" },
	{ label: "Kazakh", value: "kazakh" },
	{ label: "Kenyan", value: "kenyan" },
	{ label: "Korean", value: "korean" },
	{ label: "Laotian", value: "laotian" },
	{ label: "Lebanese", value: "lebanese" },
	{ label: "Liberian", value: "liberian" },
	{ label: "Libyan", value: "libyan" },
	{ label: "Libyan", value: "libyan" },
	{ label: "Luxembourg­ian", value: "luxembourg­ian" },
	{ label: "Macedonian", value: "macedonian" },
	{ label: "Malagasy", value: "malagasy" },
	{ label: "Malaysian", value: "malaysian" },
	{ label: "Malian", value: "malian" },
	{ label: "Maltese", value: "maltese" },
	{ label: "Mediterran­ean", value: "mediterran­ean" },
	{ label: "Mexican", value: "mexican" },
	{ label: "Mongolian", value: "mongolian" },
	{ label: "Moroccan", value: "moroccan" },
	{ label: "Moroccan", value: "moroccan" },
	{ label: "Nepali", value: "nepali" },
	{ label: "New Zealander", value: "new zealander" },
	{ label: "Nicaraguan", value: "nicaraguan" },
	{ label: "Nigerian", value: "nigerian" },
	{ label: "Norwegian", value: "norwegian" },
	{ label: "Pakistani", value: "pakistani" },
	{ label: "Palestinia­n", value: "palestinia­n" },
	{ label: "Panamanian", value: "panamanian" },
	{ label: "Paraguayan", value: "paraguayan" },
	{ label: "Passover", value: "passover" },
	{ label: "Persian", value: "persian" },
	{ label: "Peruvian", value: "peruvian" },
	{ label: "Polish", value: "polish" },
	{ label: "Portuguese", value: "portuguese" },
	{ label: "Puerto Rican", value: "puerto rican" },
	{ label: "Puerto Rican", value: "puerto rican" },
	{ label: "Romanian", value: "romanian" },
	{ label: "Rosh HaShana", value: "rosh hashana" },
	{ label: "Russian", value: "russian" },
	{ label: "Salvadoran", value: "salvadoran" },
	{ label: "Saudi", value: "saudi" },
	{ label: "Scandinavi­an", value: "scandinavi­an" },
	{ label: "Scottish", value: "scottish" },
	{ label: "Senegalese", value: "senegalese" },
	{ label: "Sierra Leonean", value: "sierra leonean" },
	{ label: "Singaporea­n", value: "singaporea­n" },
	{ label: "Slovakian", value: "slovakian" },
	{ label: "Slovenian", value: "slovenian" },
	{ label: "South African", value: "south african" },
	{ label: "Spanish", value: "spanish" },
	{ label: "Sri Lankan", value: "sri lankan" },
	{ label: "Sudanese", value: "sudanese" },
	{ label: "Surinamese", value: "surinamese" },
	{ label: "Swedish", value: "swedish" },
	{ label: "Swiss", value: "swiss" },
	{ label: "Syrian", value: "syrian" },
	{ label: "Tanzanian", value: "tanzanian" },
	{ label: "Tatar", value: "tatar" },
	{ label: "Thai", value: "thai" },
	{ label: "Tibetan", value: "tibetan" },
	{ label: "Tunisian", value: "tunisian" },
	{ label: "Tunisian", value: "tunisian" },
	{ label: "Turkish", value: "turkish" },
	{ label: "Turkish", value: "turkish" },
	{ label: "Turkmen", value: "turkmen" },
	{ label: "Ukrainian", value: "ukrainian" },
	{ label: "Uruguayan", value: "uruguayan" },
	{ label: "Uzbek", value: "uzbek" },
	{ label: "Venezuelan", value: "venezuelan" },
	{ label: "Vietnamese", value: "vietnamese" },
	{ label: "Welsh", value: "welsh" },
	{ label: "Yom Kippur", value: "yom kippur" },
	{ label: "Zimbabwean", value: "zimbabwean" },
];
