/**
 *
 * Certificate page to upload a certificate as a Snaqer
 *
 * @format */

import {
	CERTIFICATE_IMAGE_PREFIX,
	PRODUCER_COLLECTION,
} from "../scripts/globalVariables";
import { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Layout from "../components/layout/Layout";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import PublishIcon from "@mui/icons-material/Publish";
import Title from "../components/typography/Title";
import Typography from "@mui/material/Typography";
import firebase from "firebase";
import { trackEvent } from "scripts/intercom";

let noImageRef = "intern/images/certification-missing.png";

const CertificatePage: React.FunctionComponent = (): JSX.Element => {
	const ref = useRef(null);
	const userId = firebase.auth().currentUser?.uid;
	const [noItemImage, setNoItemImage] = useState();
	const [state, setState] = useState<any>({
		image: null,
		progress: null,
		downloadURL: null,
	});
	const [preview, setPreview] = useState("");

	const imageName = CERTIFICATE_IMAGE_PREFIX + "-" + "producer" + userId;
	const imageRef =
		PRODUCER_COLLECTION + "/producer" + userId + "/certificate/" + imageName;

	useEffect(() => {
		(async () => {
			await firebase
				.storage()
				.ref()
				.child(imageRef)
				.getDownloadURL()
				.then((url) => {
					setState({
						downloadURL: url,
					});
				})
				.catch((error) => {
					if (error.code === "storage/object-not-found") {
						(async () => {
							setNoItemImage(
								(await firebase
									.storage()
									.ref(noImageRef)
									.getDownloadURL()) as any,
							);
						})();
					}
					console.warn("Warning", error);
				});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e: any) => {
		if (e.target.files[0]) {
			setState({
				image: e.target.files[0],
			});
			setPreview(URL.createObjectURL(e.target.files[0]));
		}
	};

	const handleClick = () => {
		if (ref) {
			(ref as any).current.click();
		}
	};

	const handleUpload = () => {
		let file = (state as any).image;
		var storage = firebase.storage();
		var storageRef = storage.ref();
		var uploadTask = storageRef.child(imageRef).put(file);

		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				var progress =
					Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setState({ progress });
			},
			(error) => {
				throw error;
			},
			() => {
				uploadTask.snapshot.ref.getDownloadURL().then((url) => {
					setState({
						downloadURL: url,
					});
				});
			},
		);
	};

	useEffect(() => {
		return trackEvent("CertificatePage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Certificate">
			{state.downloadURL && (
				<>
					<Title>Thank you for your certificate</Title>
					<Typography>
						In case you want to change the certificate, please just upload a new
						file.
					</Typography>
				</>
			)}
			{!state.downloadURL && (
				<>
					<Title>No Certificate uploaded</Title>
					<Typography>
						Please provide us proof that your are allowed to handle food in your
						area.
					</Typography>
				</>
			)}
			<Grid container>
				<Grid item xs={12} md={6} lg={6}>
					<Box py={2}>
						1.{" "}
						<Button
							onClick={() => handleClick()}
							variant="contained"
							startIcon={<PhotoSizeSelectActualIcon />}
						>
							Choose File
						</Button>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<Box py={2}>
						2.{" "}
						<Button
							onClick={handleUpload}
							variant="contained"
							startIcon={<PublishIcon />}
						>
							Upload File
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Box sx={{ width: "100%" }}>
				<Box position="relative" display="inline-flex">
					<CircularProgress variant="determinate" value={state.progress} />
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Typography variant="caption" component="div" color="textSecondary">
							{state.progress}
						</Typography>
					</Box>
				</Box>
			</Box>
			<input
				type="file"
				accept=".png, .jpg, .jpeg, pdf"
				hidden
				ref={ref}
				onChange={handleChange}
			/>
			<Box py={2}>
				<CardMedia
					component="img"
					src={preview || state.downloadURL || noItemImage}
					style={{
						maxHeight: "600px",
						maxWidth: "100%",
						objectFit: "contain",
					}}
				/>
			</Box>
		</Layout>
	);
};

export default CertificatePage;
