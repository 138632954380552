/** @format */

import "./index.css";

import * as Sentry from "@sentry/react";

import App from "./App";
import GA4React from "ga-4-react";
import { Integrations } from "@sentry/tracing";
import LoadableApp from "./LoadableApp";
import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import { getUserId } from "./scripts/globalFunctions";
import reportWebVitals from "./reportWebVitals";

// if (process.env.APP_ENV === "production") {
Sentry.init({
	dsn: "https://7a0b375dc0d94a9790157998e8b48e25@o998074.ingest.sentry.io/5956513",
	integrations: [new Integrations.BrowserTracing()],
	release: "snaqing-web@" + process.env.VERSION,
	// debug: process.env.APP_ENV === "development" ? true : false,
	environment: process.env.APP_ENV,
	attachStacktrace: true,
	autoSessionTracking: true,
	initialScope: {
		user: { id: getUserId() },
	},
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});
Sentry.setUser({ email: firebase.auth()?.currentUser?.email as string });
// }

const ga4react = new GA4React("G-ESY5MWNGBR");

(async (_) => {
	await ga4react
		.initialize()
		.then((res) => {
			if (process.env.NODE_ENV === "production") {
				console.log("Analytics Success", res);
			}
		})
		.catch((err) => {
			if (process.env.NODE_ENV === "production") {
				console.log("Analytics Failure", err);
			}
		})
		.finally(() => {
			ReactDOM.render(
				<React.StrictMode>
					<LoadableApp />
				</React.StrictMode>,
				document.getElementById("root"),
			);
		});
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
