/**
 *
 * Map Page to see all producers
 *
 * @format */

import { LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Layout from "components/layout/Layout";
import Map from "components/map/Map";
import SubNavigationBar from "components/navigation/SubNavigationBar";
import firebase from "firebase";
import { trackEvent } from "scripts/intercom";
import { useMapData } from "scripts/map/MapQueries";

const MapPage: React.FunctionComponent = (): JSX.Element => {
	const [nearByProducerLoading, getNearByProducers, nearByProducerInformation] =
		useMapData();

	useEffect(() => {
		return getNearByProducers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	useEffect(() => {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});
	});

	useEffect(() => {
		return trackEvent("MapPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Map" showToolbar={isLoggedIn}>
			<SubNavigationBar />
			{nearByProducerLoading ? (
				<LinearProgress />
			) : (
				<Map data={nearByProducerInformation} />
			)}
		</Layout>
	);
};

export default MapPage;
