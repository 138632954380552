/** @format */

import { Drawer, LinearProgress, Typography } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import {
	becomeASnaqerItems,
	secondaryListItems,
	snaqerListItems,
	userListItems,
} from "./ListItems";
import { useEffect, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppBar from "./AppBar";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Conditional from "components/Conditional";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import RoomIcon from "@mui/icons-material/Room";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Toolbar from "@mui/material/Toolbar";
import { drawerWidth } from "scripts/globalVariables";
import firebase from "firebase";
import { useRole } from "../../scripts/firestoreQueries";

type Props = {
	title: string;
	children?: React.ReactNode;
	showToolbar?: boolean;
};

const Layout: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
	const [open, setOpen] = useState(false);
	const [roleLoading, role, getRole] = useRole();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const history = useHistory();
	const toggleDrawer = () => {
		setOpen(!open);
	};

	useEffect(() => {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});
	}, []);

	useEffect(() => {
		return getRole();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return roleLoading ? (
		<LinearProgress />
	) : (
		<>
			<Box sx={{ display: "flex" }}>
				<AppBar position="absolute" open={open}>
					<Toolbar
						sx={{
							minHeight: "80px",
							pr: "24px",
						}}
					>
						<Conditional if={props.showToolbar !== false}>
							<IconButton
								edge="start"
								color="inherit"
								aria-label="open drawer"
								onClick={toggleDrawer}
								sx={{
									marginRight: "10px",
									...(open && { display: "none" }),
								}}
								size="large"
							>
								<MenuIcon />
							</IconButton>
						</Conditional>
						{isLoggedIn && <Box sx={{ flexGrow: 1 }} />}
						<Link to="/">
							<img
								src="https://firebasestorage.googleapis.com/v0/b/snaqing-prod.appspot.com/o/intern%2Flogo%2FIcon%20White.png?alt=media&token=c13334b6-5b4c-4cf4-8a18-48bc6039a668"
								style={{ width: "45px", height: "45px", objectFit: "contain" }}
							/>
						</Link>
						<Box sx={{ flexGrow: 1 }} />
						<Box sx={{ display: { md: "flex" } }}>
							<IconButton onClick={() => history.push("/search")}>
								<SearchIcon color="secondary" />
							</IconButton>
							{isLoggedIn && (
								<IconButton onClick={() => history.push("/basket")}>
									<ShoppingBasketIcon color="secondary" />
								</IconButton>
							)}
							<IconButton onClick={() => history.push("/map")}>
								<RoomIcon color="secondary" />
							</IconButton>
							{!isLoggedIn && (
								<>
									<IconButton onClick={() => history.push("/auth")}>
										<AccountCircleIcon color="secondary" />
									</IconButton>
								</>
							)}
						</Box>
					</Toolbar>
				</AppBar>
				<Conditional
					if={
						(props.showToolbar !== false && role === "user") ||
						role === "producer"
					}
				>
					<Drawer
						variant="persistent"
						sx={{
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
						}}
						open={open}
					>
						<Toolbar
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								px: [1],
							}}
						>
							<IconButton onClick={toggleDrawer} size="large">
								<ChevronLeftIcon />
							</IconButton>
						</Toolbar>
						<Divider />
						<List>{userListItems}</List>
						{role === "producer" ? (
							<>
								<List>{snaqerListItems}</List>
								<Divider />
							</>
						) : (
							<>
								<List>{becomeASnaqerItems}</List>
								<Divider />
							</>
						)}
						<List>{secondaryListItems}</List>
					</Drawer>
				</Conditional>
				<Box
					component="main"
					mt={5}
					ml={0}
					sx={{
						backgroundColor: (theme) =>
							theme.palette.mode === "light"
								? theme.palette.grey[50]
								: theme.palette.grey[900],
						flexGrow: 1,
						height: "100vh",
						overflow: "auto",
					}}
				>
					<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
						{props.children}
					</Container>
				</Box>
			</Box>
		</>
	);
};

export default Layout;
