/**
 *
 * CardHeader which shows the producer information in ProductDetailPage
 *
 * @format
 */

import {
	Avatar,
	Badge,
	Box,
	Button,
	Card,
	CardHeader,
	Grid,
	Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import theme from "themes/materialUi";

type Props = {
	data: any;
};

const ProfileCardHeader: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const productData = props.data;

	return (
		<Grid container display="flex" justifyContent="center" textAlign="center">
			<Card
				sx={{
					width: "80%",
					[theme.breakpoints.only("xs")]: {
						minWidth: "100%",
					},
				}}
			>
				<Grid
					item
					xs={12}
					lg={12}
					md={12}
					display="flex"
					justifyContent="center"
				>
					<CardHeader
						avatar={
							<Badge
								color="secondary"
								overlap="circular"
								badgeContent={<FontAwesomeIcon icon={faAward} />}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
							>
								<Avatar
									key={"profileImage"}
									src={
										typeof productData.producer.profileImage !== "undefined"
											? productData.producer.profileImage
											: ""
									}
									sx={{
										width: 76,
										height: 76,
									}}
								/>
							</Badge>
						}
					/>
				</Grid>
				<Grid item xs={12} lg={12} display="flex" justifyContent="center">
					<Box>
						<Typography variant="h2">
							{productData.producer.displayName}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} lg={12} m={3} display="flex" justifyContent="center">
					<Box>
						<Typography variant="h3">Bio</Typography>
						<Typography variant="body1">{productData.producer.bio}</Typography>
					</Box>
				</Grid>
				<Grid item m={3}>
					<Typography variant="h3">Where you can find me</Typography>
					<Typography variant="body1">
						{productData.producer.address.street +
							", " +
							productData.producer.address.postalCode +
							" " +
							productData.producer.address.city +
							" - " +
							productData.producer.address.state}
					</Typography>
				</Grid>
				<Grid item m={3}>
					<Button variant="contained">My profile</Button>
				</Grid>
			</Card>
		</Grid>
	);
};

export default ProfileCardHeader;
