/**
 *
 * Validation schemas for formik
 *
 * @format */

import * as Yup from "yup";

const REGEX_EMAIL =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_PRICE = /^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?\.\d{2}$/;
const FIRST_NAME = Yup.string()
	.min(2, "Name is too short.")
	.max(50, "Name is too long.")
	.required("Please provide your first name.");
const LAST_NAME = Yup.string()
	.min(2, "Name is too short.")
	.max(50, "Name is too long.")
	.required("Please provide your last name.");
const EMAIL = Yup.string()
	.required("Please fill in email.")
	.email("Email format invalid.")
	.matches(REGEX_EMAIL, "Email format invalid.");
const MIN_PASSWORD_LENGTH = 6;
const PASSWORD = Yup.string()
	.required("Password is required.")
	.min(
		MIN_PASSWORD_LENGTH,
		({ min }) => `Password must be at least ${min} characters.`,
	);
// Keeping these matches in here in case we wan to restrict the password strength
// .matches(/\w*[a-z]\w*/, "Password must have a small letter")
// .matches(/\w*[A-Z]\w*/, "Password must have a capital letter")
// .matches(/\d/, "Password must have a number")
// .matches(
// 	/[!@#$%^&*()\-_"=+{}; :,<.>]/,
// 	"Password must have a special character",
// )
const CONFIRM_PASSWORD = Yup.string()
	.required("Confirmation password is required.")
	.oneOf([Yup.ref("password")], "Confirmation password must match password.");
const TERMS = Yup.boolean().oneOf(
	[true],
	"You must accept the terms and conditions.",
);
const STREET = Yup.string().required("Please provide your street name.");
const CITY = Yup.string().required("Please provide your city.");
const STATE = Yup.string()
	.min(3, "State/Province is too short.")
	.required("Please select a state.");
const COUNTRY = Yup.string()
	.min(3, "State/Province is too short.")
	.required("Please select a state.");
const HOUSE_NUMBER = Yup.string().required("Please provide your house number.");
const POSTAL_CODE = Yup.string()
	.required("Please provide your postal code.")
	.matches(/^\d+$/, "The field postal code should have digits only.");
const DISPLAY_NAME = Yup.string()
	.min(2, "Name is too short.")
	.max(50, "Name is too long.")
	.required("Please provide a name you want to show as your display name.");
const BIO = Yup.string()
	.typeError("Please provide a bio.")
	.min(20, "Bio is too short. Please provide more information about you.")
	.required("Please provide a bio.");
const PHONE = Yup.string()
	.required("Please provide your phone number.")
	.matches(/^\d+$/, "The field phone number should have digits only.");
const COURSE = Yup.string().required("Please select a course.");
const PRODUCT_NAME = Yup.string()
	.min(2, "Name is too short.")
	.max(50, "Name is too long.")
	.required("Please provide a product name.");
const PRODUCT_DESCRIPTION = Yup.string()
	.min(15, "Description is too short. 15 characters are required.")
	.max(250, "Description is too long. Maximal 250 characters are allowed.")
	.required("Please provide a description.");
const PRODUCT_INGREDIENTS = Yup.string()
	.min(5, "Ingredients are too short.")
	.required("Please provide ingredients you use for this product.");
const PRODUCT_CUISINE = Yup.string()
	.ensure()
	.required("Please select a cuisine.");
const FOOD_CATEGORY = Yup.string()
	.ensure()
	.required("Please select a cuisine.");
const PORTION_SIZE = Yup.number().positive();
const PREPARATION_TIME = Yup.number()
	.typeError("Preparation time must be a number.")
	.integer("Preparation time must be a number without decimal number.")
	.positive("Preparation time must be a positive number.")
	.label("Preparation Time")
	.required("You need to provide a preparation time in minutes.");
const PORTION_TYPE = Yup.string().ensure().required("Please select.");
const PRODUCT_PRICE = Yup.string()
	.min(2, "Price not valid. Required format: 12.00")
	.matches(REGEX_PRICE, "Price not valid. Required format: 12.00")
	.required("Please provide a price per product.");
const PAYMENT_METHOD = Yup.string()
	.ensure()
	.required("Please select or add a payment method.");
const AVAILABILITY_SLOT = Yup.string().required(
	"Please choose an available slot.",
);
const OPENING_HOURS_DATE = Yup.string().required("Please choose a date.");
const OPENING_HOURS_TIME = Yup.string().required("Please choose a time.");
const AT_LEAST = Yup.number()
	.required("Please select a number.")
	.min(0, "Please select a number.")
	.max(10, "Please select a number");
const AT_MOST = Yup.number()
	.min(Yup.ref("atLeast"), 'Number must be greater or equal to "at Least".')
	.max(10, "Please select a number.")
	.required("Please select a number ddd.");
const ADDITIONAL_PRICE = Yup.string()
	.min(0, "Please select a number.")
	.matches(REGEX_PRICE, "Price not valid. Required format: 12.00")
	.required("Please select a number.");
const PICKUP_TIME = Yup.string().required("Please choose a slot.");

// const DELIVERY_ENABLED = Yup.boolean();
// const PICKUP_ENABLED = Yup.boolean().when("isDeliveryEnabled", {
// 	is: false,
// 	then: Yup.boolean()
// 		.required("This is wrong.")
// 		.oneOf([true], "You must select at least one delivery option."),
// });

// Sign up validation
export const signUpValidationSchema = Yup.object().shape({
	firstName: FIRST_NAME,
	lastName: LAST_NAME,
	email: EMAIL,
	password: PASSWORD,
	confirmationPassword: CONFIRM_PASSWORD,
	terms: TERMS,
});

// Validation in producer account
export const producerProfileInformationScheme = Yup.object().shape({
	firstName: FIRST_NAME,
	lastName: LAST_NAME,
	email: EMAIL,
	displayName: DISPLAY_NAME,
	phone: PHONE,
	bio: BIO,
	city: CITY,
	street: STREET,
	houseNumber: HOUSE_NUMBER,
	postalCode: POSTAL_CODE,
	state: STATE,
});

export const userProfileInformationScheme = Yup.object().shape({
	firstName: FIRST_NAME,
	lastName: LAST_NAME,
	email: EMAIL,
});

// Sign in validation
export const signInValidationSchema = Yup.object().shape({
	email: EMAIL,
	password: PASSWORD,
});

// Validation when a user wants to reset his password
export const resetPasswordValidationSchema = Yup.object().shape({
	email: EMAIL,
});

// Become a Snaqer Flow step1 validation
export const becomeAProducerSchemaStep1 = Yup.object().shape({
	firstName: FIRST_NAME,
	lastName: LAST_NAME,
	displayName: DISPLAY_NAME,
	email: EMAIL,
	phone: PHONE,
	bio: BIO,
});

// Become a Snaqer Flow step2 validation
export const becomeAProducerSchemaStep2 = Yup.object().shape({
	street: STREET,
	houseNumber: HOUSE_NUMBER,
	city: CITY,
	state: STATE,
	postalCode: POSTAL_CODE,
	country: COUNTRY,
	terms: TERMS,
});

// Account Information Producer Form
export const producerAccountInformationSchema = Yup.object().shape({
	firstName: FIRST_NAME,
	lastName: LAST_NAME,
	displayName: DISPLAY_NAME,
	email: EMAIL,
	phone: PHONE,
	bio: BIO,
	street: STREET,
	houseNumber: HOUSE_NUMBER,
	city: CITY,
	state: STATE,
	postalCode: POSTAL_CODE,
	country: COUNTRY,
	// isDeliveryEnabled: DELIVERY_ENABLED,
	// isPickupEnabled: PICKUP_ENABLED,
});

// Account Information Producer Form
export const accountInformationSchema = Yup.object().shape({
	firstName: FIRST_NAME,
	email: EMAIL,
});

export const paymentProcessSchema = Yup.object().shape({
	paymentMethod: PAYMENT_METHOD,
	requirements: TERMS,
});

// FoodProduct validation
export const foodProductSchema = Yup.object().shape({
	productName: PRODUCT_NAME,
	description: PRODUCT_DESCRIPTION,
	ingredients: PRODUCT_INGREDIENTS,
	cuisine: PRODUCT_CUISINE,
	foodCategory: FOOD_CATEGORY,
	portionSize: PORTION_SIZE,
	portionType: PORTION_TYPE,
	price: PRODUCT_PRICE,
	preparationTime: PREPARATION_TIME,
	course: COURSE,
});

// FoodProductModifier validation
export const foodProductModifierSchema = Yup.object().shape({
	modifierName: PRODUCT_NAME,
	atLeast: AT_LEAST,
	atMost: AT_MOST,
});

// Office Hours validation
export const officeHoursSchema = Yup.object().shape({
	openingHoursDate: OPENING_HOURS_DATE,
	openingHoursTime: OPENING_HOURS_TIME,
});

// FoodProductModifier validation
export const foodProductModifierOptionSchema = Yup.object().shape({
	optionName: PRODUCT_NAME,
	price: ADDITIONAL_PRICE,
});

// Basket checkout validation
export const checkoutSchema = Yup.object().shape({
	portions: PORTION_SIZE,
	availability: AVAILABILITY_SLOT,
});

// Basket addProduct validation
export const addToBasketSchema = Yup.object().shape({
	portions: PORTION_SIZE,
});

// Basket checkout validation
export const basketCheckoutSchema = Yup.object().shape({
	pickUpTime: PICKUP_TIME,
	paymentMethod: PAYMENT_METHOD,
	terms: TERMS,
});
