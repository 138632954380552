/**
 *
 * Order functions
 *
 * @format */

import firebase from "firebase";

export const orderCancelation = async (
	paymentIntent: string,
	orderDoc: string,
) => {
	try {
		let orderCancel = firebase.functions().httpsCallable("issuingFullRefund");
		const data = {
			paymentIntent: paymentIntent,
			orderDoc: orderDoc,
		};
		await orderCancel(data).catch((error: any) => {
			console.error(
				"ERROR while triggering issuingFullRefund function :",
				error,
			);
		});
	} catch (error) {
		console.error(error);
	}
};
