/**
 *
 * Checkbox component for formik forms
 * This component automatically hooks some props to make reading the formik form easier
 *
 * @format */

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Link } from "@mui/material";

const CheckboxInput = (props: any): JSX.Element => {
	const {
		field: { name, onBlur, onChange, value, label },
		//@ts-ignore
		form: { errors, touched, setFieldTouched },
		...inputProps
	} = props;
	return (
		<>
			<FormControlLabel
				control={
					<Checkbox
						checked={value}
						onChange={(text) => {
							onChange(name)(text);
						}}
						inputProps={{ "aria-label": "controlled" }}
						onBlur={() => {
							setFieldTouched(name);
							onBlur(name);
						}}
						{...inputProps}
					/>
				}
				label={props.label}
			/>
			{/* <FormControlLabel
				control={
					<Checkbox
						id="terms"
						name="terms"
						color="primary"
						required
						defaultChecked
					/>
				}
				id="terms"
				name="terms"
				label={

				}
			/> */}
			<FormHelperText error>{errors[name]}</FormHelperText>
		</>
	);
};

export default CheckboxInput;
