/**
 *
 * Application Page to apply as a Snaqer
 *
 * @format
 */

import Apply from "../pages/application/ApplyPage";
import React from "react";
import useTitle from "../hooks/useTitle";

const ApplyPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Apply as a Snaqer - Snaqing | Homemade food");

	return <Apply />;
};
export default ApplyPage;
