/**
 *
 * All the queries for payment topics such as adding paymentMethods, deleting etc.
 *
 * @format */

import {
	CHARGES_COLLECTION,
	PAYMENT_METHODS_COLLECTION,
	STRIPE_TOKENS_COLLECTION,
	USERS_COLLECTION,
} from "scripts/globalVariables";

import { clearBasket } from "scripts/order/orderQueries";
import firebase from "firebase";
import { getUserId } from "../globalFunctions";
import { useState } from "react";

export const addPaymentMethod = (paymentMethod: Object) => {
	firebase
		.firestore()
		.collection(USERS_COLLECTION)
		.doc(getUserId())
		.collection(STRIPE_TOKENS_COLLECTION)
		.add(paymentMethod)
		.catch((error: any) => {
			console.error("Error: ", error);
		});
};

export const usePaymentMethod = (): [
	boolean,
	boolean,
	Array<any>,
	() => void,
] => {
	const [loading, setLoading] = useState(true);
	const [noPaymentMethod, setNoPaymentMethod] = useState(true);
	const [paymentMethods, setPaymentMethods] = useState([]);

	const getPaymentMethod = () => {
		const paymentMethodRef = firebase
			.firestore()
			.collection(USERS_COLLECTION)
			.doc(getUserId())
			.collection(PAYMENT_METHODS_COLLECTION);

		paymentMethodRef.onSnapshot((paymentMethodDocs) => {
			if (paymentMethodDocs.empty) {
				setNoPaymentMethod(true);
				setLoading(false);
			} else {
				let paymentMethodArray: any = [];
				paymentMethodDocs.forEach((paymentMethod) => {
					const newMethod = paymentMethod.data();
					paymentMethodArray.push(newMethod);
				});
				setPaymentMethods(paymentMethodArray);
				setNoPaymentMethod(false);
				setLoading(false);
			}
		});
	};

	return [loading, noPaymentMethod, paymentMethods, getPaymentMethod];
};

export const deletePaymentMethod = async (sourceId: string) => {
	let paymentMethodId: string;
	const userRef = firebase
		.firestore()
		.collection(USERS_COLLECTION)
		.doc(getUserId());

	userRef
		.collection(PAYMENT_METHODS_COLLECTION)
		.doc(sourceId)
		.get()
		.then((paymentMethod) => {
			paymentMethodId = paymentMethod.data()?.id;
			paymentMethod.ref.delete();
			paymentMethod.ref.parent.parent
				?.collection(STRIPE_TOKENS_COLLECTION)
				.where("id", "==", paymentMethodId)
				.get()
				.then((doc) => {
					doc.forEach((snapshot) => {
						snapshot.ref.delete();
					});
				});
		})
		.catch((error) => {
			console.error(
				"Error while detaching paymentMethod from stripe customer account",
				error,
			);
		});
};

export const stripeChargeIntend = async (
	amount: number,
	currency = "USD",
	paymentMethodId: string,
	termsAccepted: string,
	basketInformation: any,
	pickUpTime?: string,
) => {
	const userId = getUserId();
	const userRef = firebase.firestore().collection(USERS_COLLECTION).doc(userId);

	userRef
		.get()
		.then(async (customer: any) => {
			const customerData = customer?.data();
			const unixTime = Date.now();
			const today = Date();
			const stripe_customer_id = customerData.stripe_customer_id;
			const userProfileImage = customerData.profileImage;
			const userFirstName = customerData.firstName;
			const userLastName = customerData.lastName;
			const producerId = basketInformation[0].producerId;
			const producerCity = basketInformation[0].address.city;
			const producerStreet = basketInformation[0].address.street;
			const producerHouseNumber = basketInformation[0].address.houseNumber;
			const displayName = basketInformation[0].displayName;
			const totalPriceNumber = amount * 100;
			const totalPriceString = totalPriceNumber.toString();

			userRef
				.collection(CHARGES_COLLECTION)
				.doc()
				.set({
					amount: totalPriceString,
					currency: currency,
					stripe_customer_id: stripe_customer_id,
					userEmail: firebase.auth().currentUser?.email,
					userId: getUserId(),
					userFirstName: userFirstName,
					userLastName: userLastName,
					userProfileImage: userProfileImage,
					producerId: producerId,
					producerCity: producerCity,
					producerStreet: producerStreet,
					producerHouseNumber: producerHouseNumber,
					displayName: displayName,
					payment_method_types: ["card"],
					payment_method: paymentMethodId,
					orderDetails: basketInformation[1],
					stripeAccountConnectId: (basketInformation as any)[0]
						.stripeAccountConnectId,
					unixTime: unixTime,
					dateTime: today,
					termsAccepted: termsAccepted,
					pickUpTime: pickUpTime ? pickUpTime : null,
				})
				.then(() => {
					clearBasket();
				})
				.then(async () => {
					// TODO ANALYTICS implementation
				})
				.catch((error) => {
					console.error(
						"Error while setting charges in charges collection",
						error,
					);
				});
		})
		.catch((error) => {
			console.error("Error while getting stripe customerId", error);
		});
};
