/**
 *
 * Page which shows all the product modifiers for a producer
 *
 * @format */

import Layout from "../../components/layout/Layout";
import ProductModifierTable from "components/productModifier/ProductModifierTable";
import { trackEvent } from "scripts/intercom";
import { useEffect } from "react";

const ProductModifierPage: React.FunctionComponent = (): JSX.Element => {
	useEffect(() => {
		return trackEvent("ProductModifierPage", {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout title="Modifiers - Snaqing | Homemade food">
			<ProductModifierTable />
		</Layout>
	);
};

export default ProductModifierPage;
