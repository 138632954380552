/**
 * Login page
 *
 * @format
 */

import AuthController from "../pages/AuthController";
import React from "react";
import useTitle from "../hooks/useTitle";

const AuthControllerPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("SignIn - Snaqing | Homemade food");

	return <AuthController />;
};

export default AuthControllerPage;
