/**
 *
 * Opening Hours horizontal scrolling for the producerProfilePage
 *
 * @format
 */

import {
	Box,
	Card,
	Collapse,
	Grid,
	IconButton,
	LinearProgress,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { UnfoldMore } from "@mui/icons-material";
import { formatAMPM } from "scripts/globalFunctions";
import theme from "themes/materialUi";
import { useOpeningHours } from "scripts/firestoreOpeningHours";

type Props = {
	data: any;
};

const OpeningHoursHorizontalScroll: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const productData = props.data;
	const [loading, noHours, openingHours, getOpeningHours] = useOpeningHours();
	const [expanded, setExpanded] = useState(true);

	useEffect(() => {
		//@ts-ignore
		getOpeningHours(props.data.producerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return loading ? (
		<LinearProgress />
	) : noHours ? (
		<Box>
			<Typography>This Snaqer does not set any opening hours.</Typography>
		</Box>
	) : (
		<Box
			overflow="auto"
			display="flex"
			flexDirection="row"
			my={2}
			sx={{
				scrollbarColor: "transparent",
				"&::-webkit-scrollbar, & *::-webkit-scrollbar": {
					backgroundColor: "transparent",
				},
			}}
		>
			{Object.entries(openingHours).map((openingHour: any) => {
				return Object.entries((openingHour as any)[1][1])
					.slice(0, 2)
					.sort()
					.map((hours: any) => {
						const hoursArray = hours[1][0].split("-");
						const startTime = formatAMPM(hoursArray[0]);
						const endTime = formatAMPM(hoursArray[1]);
						return (
                            <Box
								m={1}
								sx={{
									[theme.breakpoints.only("xs")]: {
										minWidth: "70%",
									},
									[theme.breakpoints.between("xs", 'lg')]: {
										minWidth: "40%",
									},
									[theme.breakpoints.up("md")]: {
										minWidth: "20%",
									},
								}}
							>
								<Card key={openingHour[1][0] + startTime + endTime}>
									<Box px={2} m={1}>
										<Typography fontWeight="bold">
											{openingHour[1][0]}
										</Typography>
										<Typography key={startTime}>
											{startTime + "-" + endTime}
										</Typography>
									</Box>
								</Card>
							</Box>
                        );
					});
			})}
		</Box>
	);
};

export default OpeningHoursHorizontalScroll;
