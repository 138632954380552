/**
 *
 * Header image for the index page.
 *
 * @format
 */

import { Box, Grid, Paper, Typography } from "@mui/material";

import React from "react";
import theme from "themes/materialUi";

const HeaderImage: React.FunctionComponent = (): JSX.Element => {
	return (
		<>
			<Paper
				elevation={0}
				sx={{
					position: "relative",
					backgroundColor: "grey.800",
					color: "#333",
					mb: 0,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					borderRadius: "15px",
					[theme.breakpoints.only("xs")]: {
						height: "15vh",
					},
					[theme.breakpoints.up("xs")]: {
						height: "30vh",
					},
					backgroundImage:
						"url(https://firebasestorage.googleapis.com/v0/b/snaqing-prod.appspot.com/o/intern%2Fimages%2Fheader.jpg?alt=media&token=252ccf91-a0da-423e-920d-d730bb102006)",
				}}
			>
				{/* Increase the priority of the hero background image */}
				{
					<img
						style={{ display: "none", borderRadius: "35px" }}
						src="https://firebasestorage.googleapis.com/v0/b/snaqing-prod.appspot.com/o/intern%2Fimages%2Fheader.jpg?alt=media&token=252ccf91-a0da-423e-920d-d730bb102006"
					/>
				}

				<Grid container>
					<Grid item md={6}>
						<Box
							sx={{
								position: "relative",
								p: { xs: 2, md: 6 },
								pr: { md: 0 },
							}}
						>
							{/* <Typography
								fontSize={{ lg: 30, md: 28, sm: 24, xs: 18 }}
								color="inherit"
								paragraph
							>
								Authentic homemade food
							</Typography> */}
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};

export default HeaderImage;
