/**
 * Snaqer information form to apply as a Snaqer - Step 1
 *
 * @format
 */

import { Field, Formik } from "formik";
import React, { useEffect } from "react";
import { createProducer, useProducer } from "../../scripts/firestoreQueries";

import Box from "@mui/material/Box";
import Button from "../../components/Button";
import FormInput from "../../components/formik/FormInput";
import Grid from "@mui/material/Grid";
import { LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { becomeAProducerSchemaStep1 } from "../../scripts/formValidation";

type Props = {
	func: Function;
};

const SnaqerInformationForm: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	const [producerInformation, producerAddress, producerLoading, getProducer] =
		useProducer();
	let initialValues = { ...producerInformation, ...producerAddress };

	useEffect(() => {
		return getProducer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = (data: any) => {
		createProducer(data as any);
		props.func(1);
	};
	return (
		<>
			<Typography variant="h6" gutterBottom>
				Snaqer Information
			</Typography>
			{producerLoading && <LinearProgress />}
			{!producerLoading && (
				<Formik
					initialValues={initialValues}
					validationSchema={becomeAProducerSchemaStep1}
					onSubmit={onSubmit}
				>
					{({ handleSubmit, isValid, dirty }) => (
						<>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="firstName"
										placeholder="First name"
										autoComplete="first name"
										label="First name"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="lastName"
										placeholder="Last name"
										autoComplete="last name"
										label="Last name"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="displayName"
										placeholder="Display name"
										autoComplete="display name"
										label="Display name"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="email"
										placeholder="Email"
										autoComplete="email"
										label="Email"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="phone"
										placeholder="Phone number"
										autoComplete="phone number"
										label="phone number"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Field
										component={FormInput}
										name="bio"
										placeholder="Biography"
										autoComplete="bio"
										label="Biography"
									/>
								</Grid>
							</Grid>
							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<Button
									variant="contained"
									sx={{ mt: 3, ml: 1 }}
									onClick={() => handleSubmit()}
									disabled={!(isValid && dirty)}
								>
									Next step
								</Button>
							</Box>
						</>
					)}
				</Formik>
			)}
		</>
	);
};
export default SnaqerInformationForm;
