/**
 * Status box for the dashboard to showcase the status of some required documents
 *
 * @format
 */

import BoxWithTitleSubtitleAndLink from "./BoxWithTitleSubtitleAndLink";
import React from "react";

type Props = {
	status: boolean;
	titleTrue?: string;
	subTitleTrue?: string;
	descriptionTrue?: string;
	linkTitleTrue?: string;
	linkTrue?: string;
	iconTrue?: JSX.Element;
	titleFalse?: string;
	subTitleFalse?: string;
	descriptionFalse?: string;
	linkTitleFalse?: string;
	linkFalse?: string;
	iconFalse?: JSX.Element;
};

const StatusBox: React.FunctionComponent<Props> = (
	props: Props,
): JSX.Element => {
	return (
		<>
			{props.status ? (
				<BoxWithTitleSubtitleAndLink
					title={props.titleTrue as any}
					subTitle={props.subTitleTrue as any}
					description={props.descriptionTrue as any}
					linkTitle={props.linkTitleTrue as any}
					link={props.linkTrue as any}
					icon={props.iconTrue as any}
				/>
			) : (
				<BoxWithTitleSubtitleAndLink
					title={props.titleFalse as any}
					subTitle={props.subTitleFalse as any}
					description={props.descriptionFalse as any}
					linkTitle={props.linkTitleFalse as any}
					link={props.linkFalse as any}
					icon={props.iconFalse as any}
				/>
			)}
		</>
	);
};

export default StatusBox;
