/**
 *
 * Firestore queries for messages feature
 *
 * @format */

import {
	MESSAGES_SUBCOLLECTION,
	PARTICIPANT_IDS_ARRAY,
	PRODUCER_ID_PREFIX,
	ROOMS_COLLECTION,
} from "scripts/globalVariables";

import firebase from "firebase";
import { getReceiverData } from "./messageFunctions";
import { getUserId } from "../globalFunctions";
import { useState } from "react";

export const addMessage = (
	receiverId: string,
	senderId: string,
	message: string,
) => {
	const participants = [receiverId, senderId];
	participants.sort();
	const roomId = participants[0] + "-" + participants[1];

	const roomData = {
		participants: [receiverId, senderId],
		lastMessage: message,
		lastMessageTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
		readData: {
			[senderId]: firebase.firestore.FieldValue.serverTimestamp(),
		},
	};
	const messageData = {
		timestamp: firebase.firestore.FieldValue.serverTimestamp(),
		senderId: senderId,
		message: message,
	};

	const batch = firebase.firestore().batch();
	const roomRef = firebase.firestore().collection(ROOMS_COLLECTION).doc(roomId);
	const messageRef = firebase
		.firestore()
		.collection(ROOMS_COLLECTION)
		.doc(roomId)
		.collection(MESSAGES_SUBCOLLECTION)
		.doc();

	batch.set(roomRef, roomData, { merge: true });
	batch.set(messageRef, messageData);
	batch.commit();
};

export const useMessage = (
	roomId: string,
): [object | undefined, () => void, boolean] => {
	const [message, setMessage] = useState();
	const [loading, setLoading] = useState(true);

	const getMessage = () => {
		const messageRef = firebase
			.firestore()
			.collection(ROOMS_COLLECTION)
			.doc(roomId)
			.collection(MESSAGES_SUBCOLLECTION)
			.orderBy("timestamp", "asc");

		return messageRef.onSnapshot(
			(doc) => {
				const messageArray: any = [];
				doc.forEach((docMessage) => {
					const individualMessageId = { messageId: docMessage.id };
					const messageData = docMessage.data();
					const newData = { ...individualMessageId, ...messageData };
					messageArray.push(newData);
				});
				setMessage(messageArray);
				setLoading(false);
			},
			(error) => {
				console.error("Error while getting messages in useMessage hook", error);
			},
		);
	};
	return [message, getMessage, loading];
};
