/**
 *
 * Row to show individual orders for producers.
 *
 * @format */

import {
	Box,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Chip from "@mui/material/Chip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useState } from "react";

export const ProducerOrdersRow = (data: any) => {
	const orderData = data.data;
	const [open, setOpen] = useState(false);
	let userName = orderData.userFirstName + " " + orderData.userLastName;
	if (userName.length <= 1 || typeof userName === "undefined") {
		userName = "No name, please refer to orderId.";
	}

	return (
		<>
			<TableRow
				sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: "white" }}
			>
				<TableCell component="th" scope="row" align="left">
					<Typography>
						{orderData.status === "succeeded" ? (
							<CheckCircleIcon color="success" />
						) : orderData.status === "refunded" ? (
							<Tooltip title="This order was refunded">
								<KeyboardReturnIcon color="secondary" />
							</Tooltip>
						) : (
							<CancelIcon color="warning" />
						)}
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography>
						<strong>{orderData.pickUpTime.split(" ••• ")[0]}</strong>
					</Typography>
					<Chip size="small" label={orderData.pickUpTime.split(" ••• ")[1]} />
				</TableCell>
				<TableCell align="left">{userName}</TableCell>
				<TableCell align="left">
					<Chip size="medium" label={orderData.orderId} />
				</TableCell>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow key={orderData.orderId}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Table size="small" aria-label="details">
								<TableHead>
									<TableRow sx={{ backgroundColor: "#e3e3e3" }}>
										<TableCell>
											<Typography color="primary">Product</Typography>
										</TableCell>
										<TableCell>
											<Typography color="primary">Portion size</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{getProductDetails(orderData.orderDetails)}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const getProductDetails = (orderDetails: any) => {
	return orderDetails.map((product: any) => {
		let productName = product.data.details.productName;
		let additions: Array<string> = [];

		Object.keys(product.data.additions).map((additionName) => {
			additions.push(additionName);
		});
		return (
			<TableRow sx={{ backgroundColor: "#f1f1f1" }}>
				<TableCell>
					<Typography>
						<Typography sx={{ paddingBottom: 2 }}>
							<strong>
								{product.data.portions + "x "}
								{productName}
							</strong>
							{additions.length > 0 && (
								<>
									<Typography gutterBottom>
										Additions:{" "}
										{additions.map((name: string) => (
											<Chip
												label={name}
												size="small"
												variant="outlined"
												sx={{ marginRight: 1 }}
											/>
										))}
									</Typography>
									<Typography gutterBottom>
										Special Request: {product.data.specialRequest}
									</Typography>
								</>
							)}
						</Typography>
					</Typography>
				</TableCell>
				<TableCell>
					<Typography>
						{product.data.details.portionSize +
							" " +
							product.data.details.portionType}
					</Typography>
				</TableCell>
			</TableRow>
		);
	});
};
