/**
 * Root of the application
 *
 * @format
 */

import React from "react";
import UpdateProductModifier from "../pages/productModifier/UpdateProductModifierPage";
import useTitle from "../hooks/useTitle";

const UpdateProductModifierPage: React.FunctionComponent = (): JSX.Element => {
	useTitle("Update Modifier - Snaqing | Homemade food");

	return <UpdateProductModifier />;
};

export default UpdateProductModifierPage;
